import {IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonModal, IonTitle, IonToolbar} from "@ionic/react";
import {
    businessOutline,
    businessSharp,
    checkmarkCircleOutline,
    checkmarkCircleSharp,
    chevronDownOutline,
    chevronDownSharp,
    chevronForwardSharp,
    chevronForwardOutline,
} from "ionicons/icons";
import {useActiveCompany, useActiveCompanyId, useAvailableCompanies, useSetActiveCompanyId} from "./company.store";
import {useState} from "react";

export default function CompanySwitchButton() {
    const detail = useActiveCompany();
    const addr = [detail.zip, detail.town].filter(i => !!i).join(' - ');

    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <IonItem onClick={() => setIsOpen(true)} detail={false} lines={"none"} button>
                <IonIcon slot="start" md={businessSharp} ios={businessOutline} />
                <IonLabel>
                    <h2>{detail.label}</h2>
                    <p>{addr}</p>
                </IonLabel>
                <IonIcon slot="end" md={chevronDownSharp} ios={chevronDownOutline} />
            </IonItem>

            <IonModal style={{ width: 'inherit', height: 'inherit' }} isOpen={isOpen} onWillDismiss={() => setIsOpen(false)}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Changer de société</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonContent className="ion-padding">
                    <CompaniesList />
                </IonContent>
            </IonModal>
        </>
    )
}

function CompaniesList() {
    const activeCompanyId = useActiveCompanyId();
    const companies = useAvailableCompanies();
    const setActiveCompanyId = useSetActiveCompanyId();

    const handleSelectCompany = (id: string) => {
        setActiveCompanyId(id);

        // on ne se prend pas la tête on reload le tout à chaque fois q'une organisation est rendu active
        window.location.reload();
    }

    return (
        <IonList>
            {
                companies
                    .map(
                        (c, index) => {
                            const isActive = activeCompanyId === c.id;
                            const addr = [c.zip, c.town].filter(i => !!i).join(' - ');

                            return (
                                <IonItem
                                    style={{ '--background': 'white' }}
                                    key={`company-${index}`}
                                    lines={"full"}
                                    className={`ion-text-wrap ${isActive ? 'active' : ''}`}
                                    onClick={() => handleSelectCompany(c.id)}
                                    button
                                    detail={false}
                                >
                                    <IonIcon slot="start" md={businessSharp} ios={businessOutline} />
                                    <IonLabel>
                                        <h3>{c.label}</h3>
                                        <p>{addr}</p>
                                    </IonLabel>
                                    <IonIcon
                                        slot="end"
                                        color={isActive ? "success" : ""}
                                        md={isActive ? checkmarkCircleSharp : chevronForwardSharp}
                                        ios={isActive ? checkmarkCircleOutline : chevronForwardOutline}
                                    />
                                </IonItem>
                            )
                        }
                    )
            }
        </IonList>
    )
}