import {http} from "../../http";
import {Endpoint} from "../endpoint.enum";
import { omitBy, isNil } from 'lodash';
import { PaginatedResponse } from "../../models/common.model";
import {ProposalSearchResult} from "../../models/proposal.model";

type SearchProposalsPayload = {
    id?: number | string;

    thirdPartyName?: string | number;
    thirdPartyIds?: number | string; // example '1' or '1,2,3'
    address?: string | number;
    zip?: string | number;
    town?: string | number;
    code?: string | number;
    email?: string | number;
    phone?: string | number;
    origine?: string; // Origine de l'affaire 1=Foire 2=Terrain 3=Autre
    foire?: number | string; // example '1' or '1,2,3'
    startDate?: string; // Date in a string YYYY-MM-DD HH:MM:SS
    endDate?: string; // Date in a string YYYY-MM-DD HH:MM:SS
    amountWithoutTax?: string;
    amountIncludingTax?: string;

    status?: number | string;
}

type Pagination = {
    sortorder: 'ASC' | 'DESC',
    limit: number,
}

export async function searchProposals(payload: SearchProposalsPayload, pagination: Pagination = { sortorder: 'DESC', limit: 500 }) {
    const dto = omitBy(payload, isNil);

    const { data } = await http.post<PaginatedResponse<ProposalSearchResult>>(Endpoint.proposalsSearch, dto, {
        params: {
            ...pagination,
            sortfield: 't.rowid',
        }
    });

    return data;
}