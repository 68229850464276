import {useEffect} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {useHistory} from "react-router";
import {
    useCreateNewContractMultipleProposals
} from "../components/Contracts/hooks/useCreateNewContractMultipleProposals";
import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import './NewContract.css';
import CustomerForm from "../components/Contracts/CustomerForm";
import {DateTime} from "luxon";
import ModePaiementItem from "../components/Contracts/ModePaiement/ModePaiementItem";
import {normalizeFormValues} from "../components/Contracts/utils";
import ObservationsItem from "../components/Contracts/Observations";
import EmargementItem from "../components/Contracts/Emargement";
import PacAirAirItem from "../components/Contracts/PacAirAir/PacAirAirItem";
import {ContractFormModel} from "../components/Contracts/models";
import PanneauPhotovoltaiqueItem from "../components/Contracts/PanneauPhotovoltaique/PanneauPhotovoltaiqueItem";
import PacAirEauItem from "../components/Contracts/PacAirEau/PacAirEauItem";
import AutreMaterielItem from "../components/Contracts/AutreMateriel/AutreMaterielItem";
import InstallationItem from "../components/Contracts/Installation/InstallationItem";
import ErrorList from "../components/Contracts/Errors";
import BallonThermodynamiqueItem from "../components/Contracts/BallonThermodynamique/BallonThermodynamiqueItem";
import {DevTool} from "@hookform/devtools";
import {mapValues} from "lodash";
import {useUser} from "../components/Auth/auth.store";

const NewContract: React.FC = () => {
    const methods = useForm();

    let history = useHistory();

    const user = useUser();

    const { mutateAsync: mutateAsyncContract, isLoading, isSuccess, isError, data } = useCreateNewContractMultipleProposals();

    const onSubmit = async (data: any, e: any) => {
        const { existingCustomer, customer, salesForce, observations, installation, technicianSignature, customerSignature, modePaiement } = data as ContractFormModel;

        const responsableIds = (salesForce.responsables || []).map(r => r.id).join(',');
        const vendeurIds = (salesForce.vendeurs || []).map(v => v.id).join(',');
        const rabatteurIds = (salesForce.rabatteurs || []).map(r => r.id).join(',');

        const proposalDateSeconds = DateTime.fromISO(customer.dateProposition).toSeconds();

        const results = normalizeFormValues(data);

        const proposalLines = mapValues(results, lines => {
            return lines!.map(
                ({ product, poseId, pose, ...value }) => {
                    const fk_product = product ? product.id : (poseId ? `${poseId}` :"");

                    return {
                        desc: product ? product.description : pose,
                        qty: value.qty || 1,
                        fk_product,
                        tva_tx: value.tvaTx,
                        subprice: value.totalHT,
                        product_type: product ? 0 : 1,
                        array_options: {
                            ...(value.extraFields || {}), // spread des extra fields pour les produits qui en ont
                            related_product: fk_product
                        }
                    }
                }
            )
        });

        // const proposals = Object.entries(proposalLines).map(
        //     ([key, lines]) => {
        //         return {
        //             header: {
        //                 model_pdf: customer.docTemplate.id,
        //                 date: proposalDateSeconds,
        //                 duree_validite: customer.dureeValidite,
        //                 array_options: {
        //                     origine_affaire: customer.origine,
        //                     foire_origine: customer.foireOrigine && customer.foireOrigine.id,
        //                     responsable_ids: responsableIds,
        //                     vendeur_ids: vendeurIds,
        //                     rabatteur_ids: rabatteurIds,
        //                     installation_day_delai_max: installation.delaiMaximalInstallation || 0,
        //                     technician_signature: (technicianSignature || '').split(',')[1],
        //                     customer_signature: (customerSignature || '').split(',')[1],
        //                     autre_moyen_paiement: modePaiement?.autre_moyen_paiement,
        //                     montant_acompte: modePaiement?.montant_acompte || 0,
        //                 },
        //                 note_public: observations
        //             },
        //             lines,
        //         }
        //     }
        // );

        const proposals = [
            {
                header: {
                    model_pdf: customer.docTemplate.id,
                    date: proposalDateSeconds,
                    duree_validite: customer.dureeValidite,
                    array_options: {
                        origine_affaire: customer.origine,
                        foire_origine: customer.foireOrigine && customer.foireOrigine.id,
                        responsable_ids: responsableIds,
                        vendeur_ids: vendeurIds,
                        rabatteur_ids: rabatteurIds,
                        installation_day_delai_max: installation.delaiMaximalInstallation || 0,
                        technician_signature: (technicianSignature || '').split(',')[1],
                        customer_signature: (customerSignature || '').split(',')[1],
                        autre_moyen_paiement: modePaiement?.autre_moyen_paiement,
                        montant_acompte: modePaiement?.montant_acompte || 0,
                    },
                    note_public: observations
                },
                lines: Object.values(proposalLines).flat(),
            }
        ];

        await mutateAsyncContract({
            existingThirdParty: existingCustomer,
            thirdParty: existingCustomer ? undefined : ({
                name: `${customer.firstName} ${customer.name}`,
                name_bis: customer.name,
                firstname: customer.firstName,
                email: customer.email,
                phone: customer.phone,
                address: `${customer.address.street || ''} ${customer.address.route || ''}`.trim(),
                zip: customer.address.postalCode,
                town: customer.address.city,
                commercial_id: Number(user.id), // l'id de l'user
                typent_id: 8, // 8 = particulier = on demande à créer le contact également
                civility_id: customer.civility, // civilité pour le contact par défaut
                array_options: {
                    civilite: customer.civility,
                    personal_mobile_phone: customer.personalMobilePhone,
                    business_mobile_phone: customer.businessMobilePhone,
                    lat: customer.address.lat || 0,
                    lng: customer.address.lng || 0,
                }
            }),
            proposals,
            billingAddress: !customer.billingAddressIsSameAsDefaultAddress ? ({
                lastname: customer.name,
                firstname: customer.firstName,
                civility_code: customer.civility,
                address: `${customer.billingAddress?.street || ''} ${customer.billingAddress?.route || ''}`.trim(),
                zip: customer.billingAddress?.postalCode || '',
                town: customer.billingAddress?.city || '',
                phone: customer.phone,
                phone_mobile: customer.personalMobilePhone,
                phone_perso: customer.businessMobilePhone,
                array_options: {
                    lat: customer.billingAddress?.lat || 0,
                    lng: customer.billingAddress?.lng || 0,
                }
            }) : undefined,
            docTemplateName: customer.docTemplate.filename, // pour les besoins de l'upload
        });

        // Redirection après succès mutation
        history.replace('/page/Contracts');
    };

    const onError = (errors: any, e: any) => console.log(errors, e);

    useEffect(() => {
        return () => {
            methods.reset(); // On reset le tout en quittant cette page
        }
    }, []);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Nouveau contrat</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Nouveau contrat</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <FormProvider {...methods}>
                    <CustomerForm />

                    <IonGrid>
                        <PacAirEauItem />

                        <PacAirAirItem />

                        <BallonThermodynamiqueItem />

                        <PanneauPhotovoltaiqueItem />

                        <AutreMaterielItem />

                        <InstallationItem />

                        <ModePaiementItem />

                        <ObservationsItem />

                        <EmargementItem />
                    </IonGrid>

                    <ErrorList />

                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <IonButton color="light" expand="block" size="large" onClick={() => history.push('/')}>
                                    Annuler
                                </IonButton>
                            </IonCol>

                            <IonCol>
                                <IonButton expand="block"
                                           size="large"
                                           onClick={methods.handleSubmit(onSubmit)}
                                           disabled={isLoading || isSuccess}
                                >
                                    Enregistrer
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </FormProvider>
            </IonContent>

            <DevTool control={methods.control} />
        </IonPage>
    );
}

export default NewContract;