import {Endpoint} from "../endpoint.enum";
import {http} from "../../http";
import {ProposalStatus} from "../../models/proposal.model";

interface LastModifiedProposalItem {
    proposalId: string;
    entity: string;
    ref: string;
    fk_statut: ProposalStatus;
    createdAt: string;
    customerName: string;
    customerId: string;
    productTypologies: string | null; // Liste des typologies de produits coma separed
}

export async function getLastModifiedProposals() {
    const { data } = await http.get<LastModifiedProposalItem[]>(Endpoint.statsLastModifiedProposals);

    return data;
}