import React from 'react';
import {FormProvider, useForm} from "react-hook-form";
import ProposalLineForm, {ProposalLineFormModel} from "./ProposalLineForm";
import {
    IonButton,
    IonCol,
    IonContent,
    IonFooter,
    IonHeader,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import {useMutation} from "@tanstack/react-query";
import {addProposalLine, ProposalLineDto} from "../../calls/Proposals/addProposalLine";
import IonLoadingButton from "../Common/Lab/IonLoadingButton";
import {draftProposal} from "../../calls/Proposals/draftProposal";
import {validateProposal} from "../../calls/Proposals/validateProposal";

type LineModalProps = {
    proposalId: string,
    onDismiss?(data: ProposalLineFormModel | null, role: 'confirm' | 'cancel'): void,
}

export default function AddNewProposalLineModal ({ proposalId, onDismiss }: LineModalProps) {
    const methods = useForm<ProposalLineFormModel>({
        defaultValues: {
            // label: 'Produit 2',
            // desc: 'Produit 2 en test',
            qty: 1,
            // subprice: 15,
            remise_percent: 0,
            tva_tx: 20,
            product_type: '0',
        }
    });

    const { mutateAsync: addNewLine, isLoading } = useMutation(async (dto: ProposalLineDto) => {
        // Passage à l'état de brouillon avant
        await draftProposal(proposalId);

        // On ajout la ligne
        await addProposalLine(proposalId, dto);

        // On revalide
        await validateProposal(proposalId);
    });

    const onSubmit = async (data: ProposalLineFormModel) => {
        try {
            await addNewLine({
                label: data.label,
                desc: data.desc,
                qty: data.qty,
                subprice: data.subprice,
                remise_percent: data.remise_percent,
                tva_tx: data.tva_tx,
                product_type: 0 as any,
            });

            onDismiss && onDismiss(data, "confirm");
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Ajouter une nouvelle ligne</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <FormProvider {...methods} >
                    <ProposalLineForm />
                </FormProvider>
            </IonContent>

            <IonFooter>
                <IonRow>
                    <IonCol offset="6">
                        <IonButton
                            color="light"
                            expand="block"
                            onClick={() => onDismiss && onDismiss(null, "cancel")}
                        >
                            Annuler
                        </IonButton>
                    </IonCol>

                    <IonCol>
                        <IonLoadingButton
                            loading={isLoading}
                            expand="block"
                            color="primary"
                            onClick={methods.handleSubmit(onSubmit)}
                        >
                            Enregistrer
                        </IonLoadingButton>
                    </IonCol>
                </IonRow>
            </IonFooter>
        </IonPage>
    );
};