import {useMutation} from "@tanstack/react-query";
import {InterventionType} from "../../models/intervention.model";
import {CreateVisiteTechinqueDto, createVisiteTechnique} from "../../calls/Interventions/VT/createVisiteTechnique";

export function useCreateNewIntervention(type: InterventionType) {
    const handler = getHandlerByType(type);

    return useMutation((dto: CreateVisiteTechinqueDto) => {
        return handler(dto);
    });
}

function getHandlerByType(type: InterventionType) {
    switch (type) {
        case InterventionType.VISITE_TECHNIQUE:
            return createVisiteTechnique;

        default:
            throw Error(`Oups no handler found for intervention type ${type}`)
    }
}