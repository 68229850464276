import {Proposal} from "../../models/proposal.model";
import {removeExtension} from "../../utils";
import {deburr} from "lodash";
import {ThirdParty} from "../../models/third-party.model";

export function useGetProposalEcmFilename() {
    return (proposal: Proposal, customer: ThirdParty) => {
        const { id, name } = customer as any;

        const modelPdf = (!!proposal.model_pdf ? (proposal.model_pdf || '').split('/').pop() : '') as string;
        const modelName = removeExtension(modelPdf);
        const filename = `${proposal.ref}_${modelName}.pdf`;

        const folder = deburr(`${name} (${id})`);

        return `${folder}/${filename}`;
    }
}