import {IonInput, IonItem, IonLabel, IonList, IonSelect, IonSelectOption, IonTextarea} from "@ionic/react";
import { Controller } from "react-hook-form";
import React from "react";

export interface ProposalLineFormModel {
    label: string;
    desc: string;
    qty: number;
    subprice: number;
    remise_percent: number;
    tva_tx: number;
    product_type: '0' | '1',
    fk_product?: string;
}

export default function ProposalLineForm() {
    return(
        <IonList>
            <ControllerIonInput title={"Libellé du produit"} name={"label"} type={'text'} rules={{ required: true }} />
            <ControllerIonTextArea title={"Description du produit"} name={"desc"} rows={15} />
            <ControllerIonInput title={"Quantité"} name={'qty'} type={'number'} rules={{ min: 1 }} />
            <ControllerIonInput title={"Prix unitaire"} name={'subprice'} type={"number"} rules={{ required: true }} />
            <ControllerIonInput title={"Remise en %"} name={'remise_percent'} type={'number'} />
            {/*TVA */}
            <IonItem>
                <Controller
                    name={"tva_tx"}
                    render={
                        ({field}) => {
                            return (
                                <>
                                    <IonLabel >TVA</IonLabel>
                                    <IonSelect interface="popover" placeholder="TVA" value={field.value} onIonChange={field.onChange}>
                                        <IonSelectOption value={0}>0%</IonSelectOption>
                                        <IonSelectOption value={2.1}>2.1%</IonSelectOption>
                                        <IonSelectOption value={5.5}>5.5%</IonSelectOption>
                                        <IonSelectOption value={10}>10%</IonSelectOption>
                                        <IonSelectOption value={20}>20%</IonSelectOption>
                                    </IonSelect>
                                </>
                            )
                        }
                    }
                />
            </IonItem>
        </IonList>
    )
}

interface ControllerIonInputProps {
    title: string;
    name: string;
    type: 'text' | 'number';
    rules?: any,
}

function ControllerIonInput({title, name, type, rules = {}}: ControllerIonInputProps) {
    return (
        <>
            <Controller
                name={name}
                rules={rules}
                render={
                    ({field}) => {
                        return (
                            <IonItem>
                                <IonLabel position={"stacked"}>{title}</IonLabel>
                                <IonInput value={field.value} onIonChange={field.onChange} type={type} placeholder={title} />
                            </IonItem>
                        )
                    }
                }
            />
        </>
    )
}

interface ControllerIonTextAreaProps {
    title: string;
    name: string;
    autoGrow?: boolean;
    rows?: number;
}

function ControllerIonTextArea({title, name, autoGrow, rows = 3}: ControllerIonTextAreaProps) {
    return (
        <>
            <Controller
                name={name}
                render={
                    ({field}) => {
                        return (
                            <IonItem>
                                <IonLabel position={"stacked"}>{title}</IonLabel>
                                <IonTextarea
                                    value={field.value}
                                    onIonChange={field.onChange}
                                    placeholder={title}
                                    rows={rows}
                                    autoGrow={autoGrow}
                                />
                            </IonItem>
                        )
                    }
                }
            />
        </>
    )
}