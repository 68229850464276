import React, {useEffect} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {useHistory, useParams} from "react-router";
import {
    useCreateNewContractMultipleProposals
} from "../components/Contracts/hooks/useCreateNewContractMultipleProposals";
import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonItem,
    IonLabel,
    IonMenuButton,
    IonPage,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import './NewContract.css';
import CustomerForm from "../components/Contracts/CustomerForm";
import {DateTime} from "luxon";
import ModePaiementItem from "../components/Contracts/ModePaiement/ModePaiementItem";
import {ManagedProductKey, normalizeFormValues} from "../components/Contracts/utils";
import ObservationsItem from "../components/Contracts/Observations";
import EmargementItem from "../components/Contracts/Emargement";
import PacAirAirItem from "../components/Contracts/PacAirAir/PacAirAirItem";
import {ContractFormModel, PacAirEauFormModel} from "../components/Contracts/models";
import PanneauPhotovoltaiqueItem from "../components/Contracts/PanneauPhotovoltaique/PanneauPhotovoltaiqueItem";
import PacAirEauItem from "../components/Contracts/PacAirEau/PacAirEauItem";
import AutreMaterielItem from "../components/Contracts/AutreMateriel/AutreMaterielItem";
import InstallationItem from "../components/Contracts/Installation/InstallationItem";
import ErrorList from "../components/Contracts/Errors";
import BallonThermodynamiqueItem from "../components/Contracts/BallonThermodynamique/BallonThermodynamiqueItem";
import {DevTool} from "@hookform/devtools";
import {compact, keyBy, mapValues} from "lodash";
import {useUser} from "../components/Auth/auth.store";
import {useProposalDetail} from "../components/ContractDetail/useProposalDetail";
import LoadingDots from "../components/LoadingDots";
import {useQueries, useQuery} from "@tanstack/react-query";
import {ThirdParty} from "../models/third-party.model";
import {getThirdParty} from "../calls/getThirdParty";
import {Proposal, ProposalLine} from "../models/proposal.model";
import {User} from "../models/user.model";
import {searchUsers} from "../calls/User/searchUsers";
import {getProduct} from "../calls/Products/getProduct";
import {Product} from "../models/product.model";
import {productTypologyMap} from "../components/Contracts/utils/product-typology";
import {getEvent} from "../calls/Agenda/getEvent";
import {ActionComm} from "../models/action-comm.model";

const EditContract: React.FC = () => {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let { id: proposalId } = useParams<{ id: string }>();

    const { isError, isFetched, isLoading } = useContract(proposalId);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>

                    <IonTitle>Edition contrat</IonTitle>

                    <IonButtons slot="end">
                        <IonButton routerLink={`/page/Contracts/${proposalId}`} routerDirection={"forward"}>
                            Retour detail
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            {
                isLoading && (
                    <IonContent>
                        <IonItem lines={"none"}>
                            <IonText color={'medium'} style={{ marginRight: 5 }}>
                                <span>Veuillez patienter</span>
                            </IonText>
                            <LoadingDots />
                        </IonItem>
                    </IonContent>
                )
            }

            {
                isError && (
                    <IonContent>
                        <IonItem lines={"none"}>
                            <IonLabel>
                                <h5>Une erreur est survenue</h5>
                                <p>Une erreur est survenue lors du chargement d l'affaire</p>
                            </IonLabel>
                        </IonItem>
                    </IonContent>
                )
            }

            {
                (!isLoading && !isError) && (
                    <MainForm proposalId={proposalId} />
                )
            }
        </IonPage>
    );
}

function MainForm({ proposalId }: { proposalId: string }) {
    const { data: { proposal, customer, foire, usersById, productsById, enhancedLines } } = useContract(proposalId);

    const defaultValues = formatDefaultValues({
        proposal: proposal!,
        customer: customer!,
        foire,
        usersById: usersById!,
        enhancedLines,
    });

    // console.log(defaultValues);

    const methods = useForm<ContractFormModel>({
        defaultValues,
    });

    let history = useHistory();

    const user = useUser();

    const { mutateAsync: mutateAsyncContract, isLoading, isSuccess, isError, data } = useCreateNewContractMultipleProposals();

    const onSubmit = async (data: ContractFormModel, e: any) => {
        const { existingCustomer, customer, salesForce, observations, installation, technicianSignature, customerSignature, modePaiement } = data;

        const responsableIds = (salesForce.responsables || []).map(r => r.id).join(',');
        const vendeurIds = (salesForce.vendeurs || []).map(v => v.id).join(',');
        const rabatteurIds = (salesForce.rabatteurs || []).map(r => r.id).join(',');

        const proposalDateSeconds = DateTime.fromISO(customer.dateProposition).toSeconds();

        const results = normalizeFormValues(data);

        const proposalLines = mapValues(results, lines => {
            return lines!.map(
                ({ product, poseId, pose, ...value }) => {
                    const fk_product = product ? product.id : (poseId ? `${poseId}` :"");

                    return {
                        desc: product ? product.description : pose,
                        qty: value.qty || 1,
                        fk_product,
                        tva_tx: value.tvaTx,
                        subprice: value.totalHT,
                        product_type: product ? 0 : 1,
                        array_options: {
                            ...(value.extraFields || {}), // spread des extra fields pour les produits qui en ont
                            related_product: fk_product
                        }
                    }
                }
            )
        });

        // const proposals = Object.entries(proposalLines).map(
        //     ([key, lines]) => {
        //         return {
        //             header: {
        //                 model_pdf: customer.docTemplate.id,
        //                 date: proposalDateSeconds,
        //                 duree_validite: customer.dureeValidite,
        //                 array_options: {
        //                     origine_affaire: customer.origine,
        //                     foire_origine: customer.foireOrigine && customer.foireOrigine.id,
        //                     responsable_ids: responsableIds,
        //                     vendeur_ids: vendeurIds,
        //                     rabatteur_ids: rabatteurIds,
        //                     installation_day_delai_max: installation.delaiMaximalInstallation || 0,
        //                     technician_signature: (technicianSignature || '').split(',')[1],
        //                     customer_signature: (customerSignature || '').split(',')[1],
        //                     autre_moyen_paiement: modePaiement?.autre_moyen_paiement,
        //                     montant_acompte: modePaiement?.montant_acompte || 0,
        //                 },
        //                 note_public: observations
        //             },
        //             lines,
        //         }
        //     }
        // );

        const proposals = [
            {
                header: {
                    model_pdf: customer.docTemplate.id,
                    date: proposalDateSeconds,
                    duree_validite: customer.dureeValidite,
                    array_options: {
                        origine_affaire: customer.origine,
                        foire_origine: customer.foireOrigine && customer.foireOrigine.id,
                        responsable_ids: responsableIds,
                        vendeur_ids: vendeurIds,
                        rabatteur_ids: rabatteurIds,
                        installation_day_delai_max: installation.delaiMaximalInstallation || 0,
                        technician_signature: (technicianSignature || '').split(',')[1],
                        customer_signature: (customerSignature || '').split(',')[1],
                        autre_moyen_paiement: modePaiement?.autre_moyen_paiement,
                        montant_acompte: modePaiement?.montant_acompte || 0,
                    },
                    note_public: observations
                },
                lines: Object.values(proposalLines).flat(),
            }
        ];

        await mutateAsyncContract({
            existingThirdParty: existingCustomer,
            thirdParty: existingCustomer ? undefined : ({
                name: `${customer.firstName} ${customer.name}`,
                name_bis: customer.name,
                firstname: customer.firstName,
                email: customer.email,
                phone: customer.phone,
                address: `${customer.address.street || ''} ${customer.address.route || ''}`.trim(),
                zip: customer.address.postalCode,
                town: customer.address.city,
                commercial_id: Number(user.id), // l'id de l'user
                typent_id: 8, // 8 = particulier = on demande à créer le contact également
                civility_id: customer.civility, // civilité pour le contact par défaut
                array_options: {
                    civilite: customer.civility,
                    personal_mobile_phone: customer.personalMobilePhone,
                    business_mobile_phone: customer.businessMobilePhone,
                    lat: customer.address.lat || 0,
                    lng: customer.address.lng || 0,
                }
            }),
            proposals,
            billingAddress: !customer.billingAddressIsSameAsDefaultAddress ? ({
                lastname: customer.name,
                firstname: customer.firstName,
                civility_code: customer.civility,
                address: `${customer.billingAddress?.street || ''} ${customer.billingAddress?.route || ''}`.trim(),
                zip: customer.billingAddress?.postalCode || '',
                town: customer.billingAddress?.city || '',
                phone: customer.phone,
                phone_mobile: customer.personalMobilePhone,
                phone_perso: customer.businessMobilePhone,
                array_options: {
                    lat: customer.billingAddress?.lat || 0,
                    lng: customer.billingAddress?.lng || 0,
                }
            }) : undefined,
            docTemplateName: customer.docTemplate.filename, // pour les besoins de l'upload
        });

        // Redirection après succès mutation
        history.push(`/page/Contracts`);
    };

    const onError = (errors: any, e: any) => console.log(errors, e);

    useEffect(() => {
        return () => {
            methods.reset(); // On reset le tout en quittant cette page
        }
    }, []);

    return (
        <>
            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Edition contrat</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <FormProvider {...methods}>
                    <CustomerForm defaultMode={'existing'} />

                    <IonGrid>
                        <PacAirEauItem />

                        <PacAirAirItem />

                        <BallonThermodynamiqueItem />

                        <PanneauPhotovoltaiqueItem />

                        <AutreMaterielItem />

                        <InstallationItem />

                        <ModePaiementItem />

                        <ObservationsItem />

                        <EmargementItem />
                    </IonGrid>

                    <ErrorList />

                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <IonButton color="light" expand="block" size="large" onClick={() => history.push('/')}>
                                    Annuler
                                </IonButton>
                            </IonCol>

                            <IonCol>
                                <IonButton expand="block"
                                           size="large"
                                           onClick={methods.handleSubmit(onSubmit)}
                                           disabled={isLoading || isSuccess}
                                >
                                    Enregistrer
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </FormProvider>
            </IonContent>

            <DevTool control={methods.control} />
        </>
    )
}

function useContract(proposalId: string) {
    const { isError: proposalIsError, isFetched: proposalIsFetched, isLoading: proposalIsLoading, data: proposal } = useProposalDetail(proposalId);
    const { isError: customerIsError, isFetched: customerIsFetched, isLoading: customerIsLoading, data: customer } = useCustomerId(proposal?.socid || '');
    const { isError: foireIsError, isFetched: foireIsFetched, isLoading: foireIsLoading, data: foire } = useFoireId(proposal?.array_options?.options_foire_origine || '');

    // la force de vente
    const userIds = [
        proposal?.array_options?.options_responsable_ids || '',
        proposal?.array_options?.options_vendeur_ids || '',
        proposal?.array_options?.options_rabatteur_ids || '',
    ]
        .filter(id => !!id)
        .map(id => id.split(','))
        .flat();
    ;

    const { isError: usersIsError, isFetched: usersIsFetched, isLoading: usersIsLoading, data: usersById } = useUsersQuery({ userIds }, { enabled: !!proposal?.id });

    // les produits
    const { isError: productsIsError, isFetched: productsIsFetched, isLoading: productsIsLoading, productsById, enhancedLines } = useProductQueries({
        productLines: (proposal?.lines || []),
    });

    return {
        isError: proposalIsError || customerIsError || usersIsError || productsIsError,
        isFetched: proposalIsFetched || customerIsFetched || usersIsFetched || productsIsFetched,
        isLoading: proposalIsLoading || customerIsLoading || usersIsLoading || productsIsLoading,
        data: {
            proposal,
            customer,
            foire,
            usersById,
            productsById,
            enhancedLines,
        }
    }
}

function useCustomerId(id: number | string) {
    return useQuery<ThirdParty, any, ThirdParty>(['thirdParty', id], ({ queryKey }) => {
        return getThirdParty(queryKey[1] as string)
    }, { enabled: !!id });
}

function useFoireId(id: number) {
    return useQuery(['foire', id], ({ queryKey }) => {
        return getEvent(queryKey[1] as number)
    }, { enabled: !!id });
}

function useUsersQuery({ userIds }: { userIds: string[] }, opts: { enabled: boolean }) {
    return useQuery({
        queryKey: ['salesForce', userIds],
        queryFn: () => searchUsers({  userIds }),
        enabled: opts.enabled,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        select(d) {
            return keyBy(d.items, 'id');
        }
    });
}

function useProductQueries({ productLines }: { productLines: Array<ProposalLine> }) {
    const queries = productLines
        .filter(line => !!line.fk_product!) // Si pas de fk product on ne prend pas le produit sera peut être recalculé
        .map(
            line => {
                const id = line.fk_product!;

                return {
                    queryKey: ['product', id],
                    queryFn: () => getProduct(id),
                    select(p: Product) {
                        return {
                            id,
                            product: p,
                            line,
                            typology: line.array_options?.options_product_typology || '',
                        }
                    }
                }
            }
        )

    const productQueries = useQueries({ queries });

    const isLoading = productQueries.some(q => q.isLoading);
    const isError = productQueries.some(q => q.isError);
    const isFetched = productQueries.every(q => q.isFetched);

    const data = productQueries.filter(q => !!q.data).map(q => q.data!).flat();
    const productsById = keyBy(data, 'id');

    return {
        isLoading,
        isError,
        isFetched,
        productsById,
        enhancedLines: data,
    }
}

export default EditContract;

interface FormatDefaultValuesProps {
    proposal: Proposal;
    customer: ThirdParty;
    foire?: ActionComm;
    usersById: Record<string, User>;
    enhancedLines: Array<{ id: string, product: Product, line: ProposalLine, typology: string }>;
}

function formatDefaultValues(
    { proposal, customer, foire, usersById, enhancedLines }: FormatDefaultValuesProps
): Partial<ContractFormModel> {
    const responsableIds = (proposal?.array_options?.options_responsable_ids || '').split(',');
    const vendeurIds = (proposal?.array_options?.options_vendeur_ids || '').split(',');
    const rabatteurIds = (proposal?.array_options?.options_rabatteur_ids || '').split(',');

    const contractProducts = extractProductsFromProposal(proposal, enhancedLines);

    return {
        customer: {
            origine: proposal.array_options?.options_origine_affaire || '',
            foireOrigine: foire,
        } as any,
        existingCustomer: {
            thirdPartyId: customer.id,
            thirdPartyName: customer.name,
            thirdPartyEmail: customer.email || '',
            address: customer.address || '',
            zip: customer.zip || '',
            city: customer.town || '',
            projectId: customer.array_options?.options_related_project || ''
        },
        salesForce: {
            responsables: compact(responsableIds.map(id => usersById[id])),
            vendeurs: compact(vendeurIds.map(id => usersById[id])),
            rabatteurs: compact(rabatteurIds.map(id => usersById[id])),
        },
        pacAirEau: contractProducts.pacAirEau,
        pacAirAir: contractProducts.pacAirAir,
        ballonThermodynamique: contractProducts.ballonThermodynamique,
        panneauxPhotovoltaique: contractProducts.panneauxPhotovoltaique,
        autreMateriel: contractProducts.autreMateriel,
    }
}

function extractProductsFromProposal(proposal: Proposal, enhancedLines: Array<{ id: string, product: Product, line: ProposalLine, typology: string }>) {
    const result: Partial<Record<ManagedProductKey, any>> = {};

    for (const enhancedLine of enhancedLines) {
        const { typology } = enhancedLine;

        const productType = productTypologyMap[typology];

        const {
            product,
            line: {
                qty,
                total_ttc: totalHT,
                total_ht: totalTTC,
                tva_tx: tvaTx,
                array_options,
            }
        } = enhancedLine;

        if (productType === 'pacAirEau') {
            const actions = array_options?.options_pac_air_eau_actions;
            const typeLogement = array_options?.options_pac_air_eau_type_logement;
            const surfaceLogement = array_options?.options_pac_air_eau_surface_logement;
            const marqueAncienneChaudiere = array_options?.options_pac_air_eau_marque_ancienne_chaudiere;
            const typeAncienneChaudiere = array_options?.options_pac_air_eau_type_ancienne_chaudiere;

            result[productType] = {
                product,

                totalHT: Number(totalHT),
                totalTTC: Number(totalTTC),
                tvaTx: Number(tvaTx),

                actions: actions || '',
                typeLogement: typeLogement || '',
                surfaceLogement: surfaceLogement ? Number(surfaceLogement) : '',
                marqueAncienneChaudiere: marqueAncienneChaudiere || '',
                typeAncienneChaudiere: typeAncienneChaudiere || '',
            } as PacAirEauFormModel;
        }

        if (productType === 'pacAirAir') {
            if (!result[productType]) {
                result[productType] = {
                    products: []
                };
            }

            result[productType].products.push({
                product,
                qty: Number(qty),
                totalHT: Number(totalHT),
                totalTTC: Number(totalTTC),
                tvaTx: Number(tvaTx),
            });
        }

        if (productType === 'ballonThermodynamique') {
            result[productType] = {
                product,

                totalHT: Number(totalHT),
                totalTTC: Number(totalTTC),
                tvaTx: Number(tvaTx),
            }
        }

        if (productType === 'panneauxPhotovoltaique') {
            const sourcePuissanceKit = array_options?.options_pv_source_puissance_kit || '';

            result[productType] = {
                product,

                qty: Number(qty),

                totalHT: Number(totalHT),
                totalTTC: Number(totalTTC),
                tvaTx: Number(tvaTx),

                sourcePuissanceKit,
            }
        }

        if (productType === 'autreMateriel') {
            result[productType] = {
                product,

                totalHT: Number(totalHT),
                totalTTC: Number(totalTTC),
                tvaTx: Number(tvaTx),
            }
        }
    }

    return result;
}