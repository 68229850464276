import {
    IonButton,
    IonButtons,
    IonChip,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenuButton,
    IonNote,
    IonPage,
    IonRow,
    IonSkeletonText,
    IonSpinner,
    IonText,
    IonTitle,
    IonToolbar,
    useIonModal
} from "@ionic/react";
import {addOutline, addSharp, arrowDownCircleOutline, arrowDownCircleSharp, eyeOutline, eyeSharp} from 'ionicons/icons';

import "./Contracts.css";
import {useMutation, useQuery} from "@tanstack/react-query";
import {Proposal} from "../models/proposal.model";
import LoadingDots from "../components/LoadingDots";
import React from "react";
import {FormattedNumber} from "react-intl";
import {ThirdParty} from "../models/third-party.model";
import {getThirdParty} from "../calls/getThirdParty";
import {downloadDocument} from "../calls/downloadDocument";
import {saveAs} from 'file-saver';
import {getProposal} from "../calls/getProposal";
import {DateTime} from "luxon";
import currency from "currency.js";
import CompanySwitchButton from "../components/Company/CompanySwitchButton";
import {useUser} from "../components/Auth/auth.store";
import {searchProposals} from "../calls/Proposals/searchProposals";
import {useActiveCompanyId} from "../components/Company/company.store";
import {deburr} from 'lodash';

const Contracts: React.FC = () => {
    const user = useUser();

    const { isError, isFetched, isLoading, data } = useQuery(['contracts'], ({ queryKey }) => {
        return searchProposals({})
    });

    const contracts = (data?.items || []);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Contrats</IonTitle>
                    <IonButtons slot="end">
                        <IonIcon slot="icon-only" md={addSharp} ios={addOutline} />
                    </IonButtons>
                </IonToolbar>

                <CompanySwitchButton />
            </IonHeader>

            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar style={{ '--background': '#f4f4f4' }}>
                        <IonTitle size="large">Contrats</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonList id={"contractList"}>
                    {
                        isError && (
                            <IonGrid>
                                <IonRow>
                                    <IonCol>
                                        <div className="ion-text-center">
                                            <h4>Pas de contrats</h4>
                                            <p>Vous n'avez aucun contract disponible</p>
                                        </div>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        )
                    }
                    {
                        (isLoading) && (
                            <IonItem lines={"none"}>
                                <IonText color={'medium'} style={{ marginRight: 5 }}>
                                    <span>Veuillez patienter</span>
                                </IonText>
                                <LoadingDots />
                            </IonItem>
                        )
                    }

                    {
                        (isFetched) && (
                            contracts.map(
                                proposal => {
                                    return (
                                        <IonItem key={proposal.id}>
                                            <IonLabel>
                                                <CustomerItem value={proposal.thirdparty_label} />
                                                <h3>
                                                    <IonChip style={{ margin: 0 }} color="primary">{proposal.statut_libelle}</IonChip>
                                                </h3>
                                            </IonLabel>

                                            <IonLabel>
                                                <h2>Code postal</h2>
                                                <PostalCodeItem value={proposal.thirdparty_zip} />
                                            </IonLabel>

                                            <IonLabel>
                                                <h2>Total HT</h2>
                                                <h3>
                                                    <b>
                                                        <FormattedNumber
                                                            value={Number(proposal.total_ht || 0)}
                                                            style="currency"
                                                            currency={"EUR"} />
                                                        HT
                                                    </b>
                                                </h3>
                                            </IonLabel>

                                            <IonLabel>
                                                <h2>Montant TVA</h2>
                                                <h3>
                                                    <b>
                                                        <FormattedNumber
                                                            value={Number(proposal.total_tva || 0)}
                                                            style="currency"
                                                            currency={"EUR"} />
                                                    </b>
                                                </h3>
                                            </IonLabel>

                                            <IonLabel>
                                                <h2>Total TTC</h2>
                                                <h3>
                                                    <b>
                                                        <FormattedNumber
                                                            value={Number(proposal.total_ttc || 0)}
                                                            style="currency"
                                                            currency={"EUR"} />
                                                    </b>
                                                </h3>
                                            </IonLabel>

                                            <DownloadButton proposal={proposal} />
                                            <ShowDetailButton proposalId={proposal.id as string} />
                                        </IonItem>
                                    )
                                }
                            )
                        )
                    }
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default Contracts;

function CustomerItem({ value }: { value: string }) {
    return (
        <h2><b>{value}</b></h2>
    )
}


function PostalCodeItem({ value }: { value: string }) {
    return (
        <h3><b>{value}</b></h3>
    )
}

function useCustomerId(id: number | string) {
    return useQuery<ThirdParty, any, ThirdParty>(['contracts', id], ({ queryKey }) => {
        return getThirdParty(queryKey[1] as string)
    });
}

function DownloadButton({ proposal }: { proposal: Proposal }) {
    const companyId = useActiveCompanyId();

    const { isLoading: customerIsLoading, mutateAsync } = useMutation(() => {
        return getThirdParty(proposal?.socid || '')
    });

    const { mutateAsync: dl, isLoading } = useMutation((file: string) => downloadDocument({
        modulepart: 'ecm',
        original_file: file,
        attachment: 1,
        entity: +companyId,
    }));

    const loading = customerIsLoading || isLoading;

    const handleDownload = async () => {
        const customer = await mutateAsync();
        const { id, name } = customer as any;

        const modelPdf = (!!proposal.model_pdf ? (proposal.model_pdf || '').split('/').pop() : '') as string;
        const modelName = removeExtension(modelPdf);
        const filename = `${proposal.ref}_${modelName}.pdf`;

        const folder = deburr(`${name} (${id})`);

        const file = `${folder}/${filename}`;

        const res = await dl(file);

        const base64 = `data:${res["content-type"]};base64,${res.content}`;
        saveAs(dataURItoBlob(base64), res.filename);
    }

    return (
        <IonButton
            fill="outline"
            slot="end"
            onClick={() => handleDownload()} disabled={loading}
        >
            <IonLabel>
                Doc.
            </IonLabel>

            {
                isLoading ? (
                    <IonSpinner name="dots" />
                ) : (
                    <IonIcon slot={"end"} md={arrowDownCircleSharp} ios={arrowDownCircleOutline} />
                )
            }
        </IonButton>
    )
}

function ShowDetailButton({ proposalId }: { proposalId: string | number }) {
    const [present, dismiss] = useIonModal(Modal, {
        proposalId,
        onDismiss: (data: string, role: string) => {
            dismiss(data, role);
        },
    });

    return (
        <IonButton
            fill="outline"
            slot="end"
            routerLink={`/page/Contracts/${proposalId}`}
            routerDirection={'forward'}
        >
            <IonLabel>
                Détail
            </IonLabel>

            <IonIcon slot={"end"} md={eyeSharp} ios={eyeOutline} />
        </IonButton>
    )
}

function removeExtension(filename: string) {
    return filename.substring(0, filename.lastIndexOf('.')) || filename;
}

function dataURItoBlob(dataURI: any) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    const ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    const blob = new Blob([ab], {type: mimeString});
    return blob;
}

const Modal = ({ proposalId, onDismiss }: { onDismiss: (data?: any | null | undefined | number, role?: string) => void, proposalId: string | number }) => {
    const { isError, isFetched, isLoading, data: proposal } = useQuery<Proposal, any, Proposal>(['contract', proposalId], ({ queryKey }) => {
        return getProposal(queryKey[1] as string);
    });

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton color="medium" onClick={() => onDismiss(null, 'cancel')}>
                            Fermer
                        </IonButton>
                    </IonButtons>
                    <IonTitle>
                        {
                            isLoading
                                ? (<IonSkeletonText animated={true} style={{ 'width': '80px' }} />)
                                : <>Référence: {proposal?.ref}</>
                        }
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                {
                    isLoading && (
                        <IonItem lines={"none"} color={"light"}>
                            <IonText color={'medium'} style={{ marginRight: 5 }}>
                                <span>Veuillez patienter</span>
                            </IonText>
                            <LoadingDots />
                        </IonItem>
                    )
                }

                {
                    !isLoading && (
                        <>
                            <IonList>
                                <IonListHeader>En tête</IonListHeader>

                                <CustomerItems id={proposal?.socid as string} />

                                <IonItem>
                                    <IonLabel>
                                        <h2>
                                            <DateItem value={proposal?.date || 0} />
                                        </h2>
                                        <p>Date du devis</p>
                                    </IonLabel>
                                </IonItem>

                                <IonItem>
                                    <IonLabel>
                                        <h2>
                                            <DateItem value={proposal?.fin_validite || 0} />
                                        </h2>
                                        <p>Fin de validité</p>
                                    </IonLabel>
                                </IonItem>
                            </IonList>

                            <IonList>
                                <IonListHeader>Lignes</IonListHeader>

                                {
                                    (proposal?.lines || []).map(
                                        line => {
                                            return (
                                                <IonItem key={line.ref}>
                                                    <IonLabel>
                                                        <h2>
                                                            {line.ref}
                                                        </h2>
                                                        <h3>{line.libelle}</h3>
                                                    </IonLabel>

                                                    <IonNote>
                                                        Tva: <FormattedNumber
                                                        style='percent'
                                                        value={Number(line.tva_tx || 0) / 100}
                                                        minimumFractionDigits={2} />
                                                    </IonNote>

                                                    <IonNote style={{ marginLeft: 5 }}>
                                                        Qté: {line.qty}
                                                    </IonNote>
                                                </IonItem>
                                            )
                                        }
                                    )
                                }
                            </IonList>

                            <IonList>
                                <IonListHeader>Totaux</IonListHeader>

                                <IonItem>
                                    <IonLabel>
                                        <h2>
                                            Total HT
                                        </h2>
                                    </IonLabel>
                                    <IonNote>
                                        <CurrencyItem value={proposal?.total_ht || 0} />
                                    </IonNote>
                                </IonItem>

                                <IonItem>
                                    <IonLabel>
                                        <h2>
                                            Montant TVA
                                        </h2>
                                    </IonLabel>
                                    <IonNote>
                                        <CurrencyItem value={proposal?.total_tva || 0} />
                                    </IonNote>
                                </IonItem>

                                <IonItem>
                                    <IonLabel>
                                        <h2>
                                            Total TTC
                                        </h2>
                                    </IonLabel>
                                    <IonNote>
                                        <CurrencyItem value={proposal?.total_ttc || 0} />
                                    </IonNote>
                                </IonItem>
                            </IonList>
                        </>
                    )
                }
            </IonContent>
        </IonPage>
    );
};

function CustomerItems({ id }: { id: string }) {
    const { isError, isFetched, isLoading, data: customer } = useCustomerId(id);

    if (isLoading) {
        return <LoadingDots />;
    }

    return (
        <>
            <IonItem>
                <IonLabel>
                    <h2>{customer?.firstname} {customer?.name}</h2>
                    <p>Nom client</p>
                </IonLabel>
            </IonItem>

            <IonItem>
                <IonLabel>
                    <h2>{customer?.phone}</h2>
                    <p>Téléphone</p>
                </IonLabel>
            </IonItem>

            <IonItem>
                <IonLabel>
                    <h2>{customer?.email}</h2>
                    <p>Email</p>
                </IonLabel>
            </IonItem>
        </>
    )
}

function DateItem({ value }: { value: number }) {
    const d = DateTime.fromSeconds(value, { zone: "Europe/Paris" }).setLocale('fr');
    return <>{d.toLocaleString(DateTime.DATE_SHORT)}</>
}

function CurrencyItem({ value }: { value: number | string }) {
    const c = currency(value);
    return <IonText color={'primary'}>
        <p><b>{c.value} €</b></p>
    </IonText>;
}