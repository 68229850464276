import {interventionHttp} from "../../http";
import {Endpoint} from "../endpoint.enum";
import {RawVisiteTechnique} from "../../models/intervention.model";

export type GetScheduledInterventionsInPeriodPayload = {
    technicianId: string;
    startDate: string;
    endDate: string;
}

export interface ScheduledInterventionsInPeriodResponse {
    date: string;
    dayISO: string;
    docs: Array<RawVisiteTechnique>
    count: number;
}

export async function getScheduledInterventionsInPeriod(payload: GetScheduledInterventionsInPeriodPayload) {
    const { data } = await interventionHttp
        .post<ScheduledInterventionsInPeriodResponse[]>(Endpoint.aggregationScheduledInterventionsInPeriod, payload);

    return data;
}