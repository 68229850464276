import {FormProvider, useForm, useWatch} from "react-hook-form";
import InterventionForm, {InterventionFormModel} from "./InterventionForm";
import React, {useEffect, useRef} from "react";
import {
    IonAvatar,
    IonButton,
    IonCol,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonModal,
    IonRow,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import IonLoadingButton from "../Common/Lab/IonLoadingButton";
import {DateTime} from "luxon";
import {useCreateNewIntervention} from "./useCreateNewIntervention";
import {InterventionType} from "../../models/intervention.model";
import { CreateInterventionDto } from "../../calls/Interventions/types";
import {useActiveCompanyId} from "../Company/company.store";
import {CreateVisiteTechinqueDto} from "../../calls/Interventions/VT/createVisiteTechnique";
import {OverlayEventDetail} from "@ionic/react/dist/types/components/react-component-lib/interfaces";

interface InterventionFormModalProps {
    trigger: string;
    defaultInterventionType: InterventionType
    proposal: {
        id: number;
        origin: string;
        originId?: string;
    },
    thirdParty: {
        id: number,
        name: string,
    },
    place: {
        address: string;
        zipCode: string;
        town: string;
        coords: number[];
    },
    projectId: number,
    date: Date,
    technician: {
        id: string,
        name: string
    },
    onWillDismiss?(ev: CustomEvent<OverlayEventDetail>): void
}

export default function InterventionFormModal({ trigger, defaultInterventionType, date: defaultDate, technician, ...restOfProps }: InterventionFormModalProps) {
    const activeCompanyId = useActiveCompanyId();

    const methods = useForm<InterventionFormModel>({
        defaultValues: {
            type: defaultInterventionType,
            date: defaultDate ? defaultDate.toISOString() : DateTime.now().toISO(),
            technician,
            duration: 1,
        }
    });

    const { proposal, thirdParty, place } = restOfProps;

    const type = methods.watch('type');

    const modal = useRef<HTMLIonModalElement>(null);

    const { mutateAsync, isLoading, isSuccess, isError, data } = useCreateNewIntervention(type);

    const dismiss = () => {
        methods.reset();
        modal.current?.dismiss();
    }

    const onSubmit = async ({ date, technician, ...data }: InterventionFormModel) => {
        // Get des coords
        const [lat, lng] = place.coords;

        const baseDto: CreateInterventionDto = {
            proposal: {
                id: proposal.id.toString(),
                origin: proposal.origin,
                originId: proposal.originId,
            },
            customer: {
                id: thirdParty.id.toString(),
                name: thirdParty.name,
            },
            place: {
                address: place.address,
                zipCode: place.zipCode,
                town: place.town,
                coords: [Number(lng), Number(lat)]
            },
            entity: activeCompanyId,
            technician,
            date,
        }

        let dto;

        if (type === InterventionType.VISITE_TECHNIQUE) {
            // Convert duree
            const duration = (data.duration || 0) * 3600; // conversion durée en seconde

             dto = {
                ...baseDto,
                duration,
                desiredInterventionDate: '',
            } as CreateVisiteTechinqueDto;
        }

        const id = await mutateAsync(dto as any);

        methods.reset();

        modal.current?.dismiss(id, 'confirm');
    }

    return (
        <IonModal ref={modal} backdropDismiss={false} keepContentsMounted={false} trigger={trigger} style={{ '--width': '30%', '--min-height': 400 }} onWillDismiss={restOfProps.onWillDismiss}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Programmer intervention</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonItem>
                    <IonAvatar slot="start">
                        <img alt="Silhouette of a techincian" src="/assets/img/ion-avatar.svg" />
                    </IonAvatar>
                    <IonLabel>
                        <h3>{technician.name}</h3>
                        <p>Technicien</p>
                    </IonLabel>
                </IonItem>

                <br />

                <FormProvider {...methods}>
                    <InterventionForm />
                </FormProvider>
            </IonContent>

            <IonRow className={"ion-margin-top"}>
                <IonCol>
                    <IonButton expand="block" color="light" onClick={dismiss}>
                        Annuler
                    </IonButton>
                </IonCol>

                <IonCol>
                    <IonLoadingButton
                        expand="block"
                        loading={isLoading}
                        onClick={methods.handleSubmit(onSubmit)}
                    >
                        Enregistrer
                    </IonLoadingButton>
                </IonCol>
            </IonRow>
        </IonModal>
    );
}
