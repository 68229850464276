import {
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCheckbox,
    IonCol,
    IonGrid,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonNote,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText
} from "@ionic/react";
import {Controller, useFormContext} from "react-hook-form";
import {FormattedNumber, FormattedPlural} from "react-intl";
import React, {useState} from "react";
import {TypeBranchementAirAir} from "../../../valueObjects/TypeBranchementAirAir";
import {SearchProductResult, searchProducts} from "../../../calls/Products/searchProducts";
import LoadingDots from "../../LoadingDots";
import {alertCircleOutline, alertCircleSharp} from "ionicons/icons";
import {Product} from "../../../models/product.model";

const brands = [
    { id: 17, label: 'Airwell' },
    { id: 18, label: 'LG' }
];

const powers = [
    { label: '1.5 KW', value: 1.5 },
    { label: '2.5 KW', value: 2.5 },
    { label: '2.8 KW', value: 2.8 },
    { label: '3.3 KW', value: 3.3 },
    { label: '3.4 KW', value: 3.4 },
    { label: '3.5 KW', value: 3.5 },
    { label: '4 KW', value: 4 },
    { label: '5.2 KW', value: 5.2 },
    { label: '5.3 KW', value: 5.3 },
    { label: '5.65 KW', value: 5.65 },
    { label: '5.8 KW', value: 5.8 },
    { label: '7 KW', value: 7 },
    { label: '8.6 KW', value: 8.6 },
    { label: '9.1 KW', value: 9.1 },
    { label: '9.5 KW', value: 9.5 },
];

export default function SearchPacAirAir() {
    const {watch, resetField} = useFormContext();

    const [loading, setLoading] = useState(false);

    const initialResult = {
        data: [],
        pagination: {
            page: 1,
            limit: 100,
            page_count: 1,
            total: 0
        }
    };

    const [result, setResult] = useState<SearchProductResult>(initialResult);

    const totalItems = result.pagination.total;
    const products = result.data;

    // Callback version of watch.  It's your responsibility to unsubscribe when done.
    React.useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            // console.log(value, name, type);
            // Si le champs qui change n'est pas filter on ne fait rien
            // on refresh la liste uniquement quand c'est filter qui change
            if (! (name || '').startsWith('filter')) {
                return;
            }

            const { filter: { marque: category, ...extraFields } } = value as { filter: { marque: number } };

            setLoading(true);
            setResult(initialResult);
            searchProducts({ category, includesubproducts: true, ...extraFields })
                .then(result => {
                    resetField('selectedProducts'); // a chaque fois que les données changent on reset selectedProducts
                    setLoading(false);
                    setResult(result);
                })
                .catch(() => {
                    setLoading(false);
                })
            ;
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    return (
        <IonGrid>
            <IonRow>
                <IonCol size={'4'} className={"ion-no-padding"}>
                    <IonCard>
                        <IonCardContent>
                            <IonList>
                                <Controller
                                    key={'marque'}
                                    name={"filter.marque"}
                                    render={
                                        ({field}) => {
                                            return (
                                                <IonRadioGroup onIonChange={field.onChange}>
                                                    <IonListHeader>
                                                        <IonLabel>Marque</IonLabel>
                                                    </IonListHeader>

                                                    {
                                                        brands.map(
                                                            brand => {
                                                                return (
                                                                    <IonItem key={brand.id} lines={'none'}>
                                                                        <IonLabel>{brand.label}</IonLabel>
                                                                        <IonRadio slot="start" value={brand.id} color={'pac-air-air'} />
                                                                    </IonItem>
                                                                )
                                                            }
                                                        )
                                                    }
                                                </IonRadioGroup>
                                            )
                                        }
                                    }
                                />

                                <Controller
                                    key={"puissance"}
                                    name={"filter.puissance"}
                                    render={({ field}) => {
                                        return (
                                            <IonList>
                                                <IonListHeader>
                                                    <IonLabel>Puissance</IonLabel>
                                                </IonListHeader>

                                                <IonItem lines={'none'}>
                                                    <IonSelect onIonChange={field.onChange} interface={"action-sheet"} placeholder="Sélectionner une puissance">
                                                        {
                                                            powers.map(
                                                                power => {
                                                                    return (
                                                                        <IonSelectOption key={power.value} value={power.value}>{power.label}</IonSelectOption>
                                                                    )
                                                                }
                                                            )
                                                        }
                                                    </IonSelect>
                                                </IonItem>
                                            </IonList>
                                        )
                                    }}
                                />

                                <Controller
                                    key={"typeUnite"}
                                    name={"filter.isIndoorUnit"}
                                    render={
                                        ({ field }) => {
                                            return (
                                                <IonRadioGroup onIonChange={field.onChange}>
                                                    <IonListHeader>
                                                        <IonLabel>Type unité</IonLabel>
                                                    </IonListHeader>

                                                    <IonItem key={'allUnit'} lines={'none'}>
                                                        <IonLabel>Tout</IonLabel>
                                                        <IonRadio slot="start" value={undefined} color={'pac-air-air'} />
                                                    </IonItem>

                                                    <IonItem key={'outdoorUnit'} lines={'none'}>
                                                        <IonLabel>Unité extérieure uniquement</IonLabel>
                                                        <IonRadio slot="start" value={0} color={'pac-air-air'} />
                                                    </IonItem>

                                                    <IonItem key={'split'} lines={'none'}>
                                                        <IonLabel>Unité intérieure uniquement</IonLabel>
                                                        <IonRadio slot="start" value={1} color={'pac-air-air'} />
                                                    </IonItem>
                                                </IonRadioGroup>
                                            )
                                        }
                                    }
                                />

                                <Controller
                                    key={"typeBranchement"}
                                    name={"filter.typeBranchement"}
                                    render={
                                        ({ field }) => {
                                            return (
                                                <IonRadioGroup onIonChange={field.onChange}>
                                                    <IonListHeader>
                                                        <IonLabel>Branchement</IonLabel>
                                                    </IonListHeader>

                                                    {
                                                        TypeBranchementAirAir.supportedBranchements().map(
                                                            branchement => {
                                                                return (
                                                                    <IonItem key={branchement.value || branchement.label} lines={'none'}>
                                                                        <IonLabel>{branchement.label}</IonLabel>
                                                                        <IonRadio slot="start" value={branchement.value} color={'pac-air-air'} />
                                                                    </IonItem>
                                                                )
                                                            }
                                                        )
                                                    }
                                                </IonRadioGroup>
                                            )
                                        }
                                    }
                                />
                            </IonList>
                        </IonCardContent>
                    </IonCard>
                </IonCol>

                <IonCol size={'8'} className={"ion-no-padding"}>
                    <IonCard>
                        <IonCardContent>
                            <IonCardSubtitle>
                                Résultat de la recherche
                            </IonCardSubtitle>
                            <IonCardTitle>
                                <FormattedPlural value={totalItems} one="Pompe" other="Pompes">
                                    { (value) => <>{`${totalItems} ${value} à chaleur Air/Air`}</> }
                                </FormattedPlural>
                            </IonCardTitle>

                            {
                                loading && (
                                    <IonItem lines={"none"}>
                                        <IonText color={'medium'} style={{ marginRight: 5 }}>
                                            <span>Recherche en cours</span>
                                        </IonText>
                                        <LoadingDots />
                                    </IonItem>
                                )
                            }

                            {
                                !loading && products.length === 0 && (
                                    <IonText style={{ display: 'flex' }} className={'ion-align-items-center ion-margin-top'}>
                                        <IonIcon md={alertCircleSharp} ios={alertCircleOutline} />
                                        <span style={{ marginLeft: 5 }}>Pas de produits trouvés. Lancer une recherche en changeant les filtres.</span>
                                    </IonText>
                                )
                            }

                            <IonList>
                                {
                                    products.map(
                                        (product, index) => {
                                            return (
                                                <ProductIonItem key={product.ref} product={product} index={index} />
                                            )
                                        }
                                    )
                                }
                            </IonList>
                        </IonCardContent>
                    </IonCard>
                </IonCol>
            </IonRow>
        </IonGrid>
    );
}

function ProductIonItem({ product, index }: { product: Product, index: number }) {
    return (
        <Controller
            name={`selectedProducts.${index}`}
            render={
                ({ field: { onChange, value } }) => {
                    return (
                        <IonItem key={product.ref}>
                            <IonCheckbox
                                checked={!!value}
                                onIonChange={({ detail: { checked } }) => onChange(
                                    checked
                                        ? product
                                        : undefined
                                )}
                                color={'pac-air-air'}
                                slot="start"
                            />
                            <IonLabel>
                                <h2>{product.label}</h2>
                                <h3>{product.description}</h3>
                                <p>
                                    Tarif public <b><FormattedNumber value={Number(product.price)} style="currency" currency={"EUR"} /> HT</b>
                                </p>

                                {
                                    product.kits.length > 0 && (
                                        product.kits.map(
                                            p => (
                                                <div key={'kit' + p.id} className={'ion-margin-top'}>
                                                    <h2>{p.label}</h2>
                                                    <h3>{p.description}</h3>
                                                    <p>
                                                        Tarif public <b><FormattedNumber value={Number(p.price)} style="currency" currency={"EUR"} /> HT</b>
                                                    </p>
                                                </div>
                                            )
                                        )
                                    )
                                }
                            </IonLabel>
                            <IonNote slot={'end'}>
                                {product.ref}
                            </IonNote>
                        </IonItem>
                    )
                }
            }
        />
    )
}
