import {createThirdParty, CreateThirdPartyDto} from "../../../calls/ThirdParties/createThirdParty";
import {createProposal, ProposalHeaderDto, ProposalLineDto} from "../../../calls/Proposals/createProposal";
import {useMutation} from "@tanstack/react-query";
import {useActiveCompanyId} from "../../Company/company.store";
import {validateProposal} from "../../../calls/Proposals/validateProposal";
import {buildDocument} from "../../../calls/Documents/buildDocument";
import {downloadDocument} from "../../../calls/Documents/downloadDocument";
import {uploadDocumentToEcm} from "../../../calls/Documents/uploadDocumentToEcm";
import {addContact} from "../../../calls/Proposals/addContact";
import {Proposal, ProposalContactType} from "../../../models/proposal.model";
import {createContact, CreateContactDto} from "../../../calls/Contacts/createContact";
import {PDFDocument} from 'pdf-lib';
import {getOrder} from "../../../calls/Proposals/getOrder";
import {addPayment, AddPaymentDto} from "../../../calls/Payments/addPayment";
import {DateTime} from "luxon";
import {PaymentMethod} from "../../../models/payment.model";
import currency from "currency.js";

type Payload = {
    existingThirdParty?: {
        thirdPartyId: string, // Id d'un tiers existant
        thirdPartyName: string,
        thirdPartyEmail: string,
        projectId: string, // Id project si utilisation d'un tiers existant
    },
    thirdParty?: Omit<CreateThirdPartyDto, 'entity'>,
    proposals: Array<{
        header: Omit<ProposalHeaderDto, 'socid' | 'fk_project' | 'entity'>,
        lines: ProposalLineDto[],
    }>,
    billingAddress?: Omit<CreateContactDto, 'socid'>,
    docTemplateName: string,
}

export function useCreateNewContractMultipleProposals() {
    const companyId = useActiveCompanyId();

    return useMutation(
        async ({ existingThirdParty, thirdParty, proposals, docTemplateName, billingAddress }: Payload) => {
            // Création du tiers d'abord
            const { thirdpartyId, thirdPartyName, projectId, defaultContactId } = await createThirdPartyOrUseExistingOne({
                existingThirdParty,
                thirdParty: {
                    ...thirdParty!,
                    entity: +companyId,
                }
            });

            for (const proposal of proposals) {
                // Création du proposal lié au tiers
                const proposalId  = await createProposal({
                    ...proposal.header,
                    socid: thirdpartyId,
                    fk_project: projectId,
                    entity: +companyId,
                }, proposal.lines);

                // On valide directe le devis après la création
                const createdProposal = await validateProposal(proposalId);
                const { ref: proposalRef } = createdProposal;

                // Ajout du paiement de l'acompte
                await addAcompte(createdProposal, thirdPartyName);

                // Default contact n'est pas là si le client est existant
                if (defaultContactId) {
                    // On va créer le contact pour la facturation
                    const billingContactId = !billingAddress ? defaultContactId : (
                        await createContact({
                            ...billingAddress,
                            socid: thirdpartyId,
                        })
                    );

                    // On va directement attacher les contacts
                    await addContact({
                        id: proposalId,
                        contactid: defaultContactId,
                        type: ProposalContactType.SHIPPING, // Contact par défaut créé = contact pour le chantier
                    });

                    await addContact({
                        id: proposalId,
                        contactid: billingContactId,
                        type: ProposalContactType.BILLING, // contact pour facturation
                    });
                }

                // On va build ensuite le document associé
                await buildDocument({
                    modulepart: 'propal',
                    langcode: 'fr_FR',
                    original_file: proposalRef,
                });

                // On success du build on lance un call pour download le fichier
                const docTemplate = removeExtension(docTemplateName); // rm ext pour éviter 404 à cause du rajouter .pdf

                const { content: filecontent, ...res } = await downloadDocument({
                    modulepart: 'propal',
                    original_file: `${proposalRef}/${proposalRef}_${docTemplate}.pdf`,
                    entity: +companyId,
                });

                // On signe le document si signature
                const signatureClient = proposal.header.array_options?.customer_signature;
                let signedContract = '';
                if (signatureClient) {
                    signedContract = await signDocument(filecontent, signatureClient);
                }

                // On success du download en base64 on upload le contenu et on est bon
                const filename = `${proposalRef}_${docTemplate}.pdf`;
                const subdir = `${thirdPartyName} (${thirdpartyId})`; // ex. Marcel Pierre (26)

                // upload signatureClient
                if (signatureClient) {
                    await uploadDocumentToEcm({
                        filename: 'signature_client.png',
                        subdir: `${subdir}/${proposalRef}`,
                        filecontent: signatureClient || '',
                        overwriteifexists: 1, // On overwrite ?
                    });
                }

                // upload signatureTechnicien
                const signatureTechnicien = proposal.header.array_options?.technician_signature;

                if (signatureTechnicien) {
                    await uploadDocumentToEcm({
                        filename: 'signature_technicien.png',
                        subdir: `${subdir}/${proposalRef}`,
                        filecontent: signatureTechnicien || '',
                        overwriteifexists: 1, // On overwrite ?
                    });
                }

                await uploadDocumentToEcm({
                    filename,
                    subdir,
                    filecontent: signedContract || filecontent,
                    overwriteifexists: 1, // On overwrite ?
                });
            }
        }
    )
}

async function createThirdPartyOrUseExistingOne({ existingThirdParty, thirdParty }: {
    existingThirdParty?: {
        thirdPartyId: string,
        thirdPartyName: string,
        projectId: string,
    },
    thirdParty?: CreateThirdPartyDto,
}): Promise<{
    thirdpartyId: number,
    thirdPartyName: string,
    projectId: number,
    defaultContactId?: number; // contact par défaut créé
}> {
    if (existingThirdParty) {
        return {
            thirdpartyId: +existingThirdParty.thirdPartyId,
            thirdPartyName: existingThirdParty.thirdPartyName,
            projectId: +existingThirdParty.projectId,
        }
    }

    const { thirdpartyId, projectId, defaultContactId } = await createThirdParty(thirdParty!);

    return { thirdpartyId, thirdPartyName: thirdParty!.name, projectId, defaultContactId };
}

function removeExtension(filename: string) {
    return filename.substring(0, filename.lastIndexOf('.')) || filename;
}

async function signDocument(base64Pdf: string, base64Signature: string) {
    const pdfDoc = await PDFDocument.load(base64Pdf);

    const embedSignature = await pdfDoc.embedPng(base64Signature)

    // On fait - 6 car on enlève les 6 pages CGV
    const signaturePageIndex = pdfDoc.getPages().length - 7;
    const signaturePage = pdfDoc.getPages()[signaturePageIndex];

    const pngDims = embedSignature.scale(.15);

    signaturePage.drawImage(embedSignature, {
        x: signaturePage.getWidth() / 2 - 100,
        y: signaturePage.getHeight() / 2 + 160,
        width: pngDims.width,
        height: pngDims.height,
    });

    return pdfDoc.saveAsBase64();
}

async function addAcompte(proposal: Proposal, thirdPartyName: string) {
    // Montant de l'acompte
    // const amount = proposal.array_options?.options_montant_acompte || '';
    const amount = proposal.array_options?.options_acompte || '';

    // Si pas d'acompte, on ne va pas plus loin
    if (!Number(amount)) {
        return;
    }

    // On part du principe pour le moment qu'un proposal n'est lié qu'à une seule commande
    const orderId = Object.values(proposal?.linkedObjectsIds?.commande || {})[0];

    // On part du principe pour le moment qu'une commande n'est lié qu'à une seule facture
    const order = await getOrder(orderId);
    const invoiceId = Object.values(order?.linkedObjectsIds?.facture || {})[0];

    // Paiement acompte
    const datepaye = DateTime.now().toSeconds();

    const moyenPaiement = proposal.array_options?.options_autre_moyen_paiement || 'ESPECES';
    const paymentId = moyenPaiement === 'ESPECES' ? PaymentMethod.ESPECE : PaymentMethod.CHEQUE;

    const dto: AddPaymentDto = {
        datepaye: Math.floor(datepaye),
        arrayofamounts: {
            [invoiceId]: {
                amount: currency(amount).toString(),
                multicurrency_amount: ""
            }
        },
        paymentid: paymentId,
        accountid: 1,
        closepaidinvoices: 'yes',
        ...(
            paymentId === PaymentMethod.CHEQUE && ({
                chqemetteur: thirdPartyName,
            })
        )
    }

    await addPayment(dto)
}