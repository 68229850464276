import {IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonRow, IonText, IonThumbnail} from "@ionic/react";
import {useController, useWatch} from "react-hook-form";
import {DateTime} from "luxon";
import styled from "@emotion/styled";
import {useUser} from "../../Auth/auth.store";
import {userToString} from "../../../models/user.model";
import {useSignaturePad} from "../../SignaturePad/useSignaturePad";

export default function EmargementItem() {
    const user = useUser();

    const city = useWatch({ name: 'customer.address.city', defaultValue: '' });
    const origineType = useWatch({ name: 'customer.origine', defaultValue: 1 });

    const isOrigineFoire = origineType === 1;

    const foireOrigine = useWatch({ name: 'customer.foireOrigine', defaultValue: undefined });
    const existingCity = useWatch({ name: 'existingCustomer.city', defaultValue: '' });
    const defaultValue = DateTime.now().toISO();
    const dateProposition = useWatch({ name: 'customer.dateProposition', defaultValue: defaultValue });
    const formatted = DateTime.fromISO(dateProposition).toLocaleString(DateTime.DATE_SHORT);

    const nomTechnicien = userToString(user);

    return (
        <IonGrid>
            <IonRow>
                <IonCol size="6">
                    <StyledItem style={{ border: '2px solid var(--ion-color-emargement)' }}>
                        <IonLabel className="ion-text-wrap">
                            <IonText color="emargement">
                                <h3>
                                   NOM DU TECHNICIEN
                                </h3>
                                <h2><b>{nomTechnicien}</b></h2>
                            </IonText>
                        </IonLabel>
                    </StyledItem>
                </IonCol>
                <IonCol size="6">
                    <StyledItem style={{ border: '2px solid var(--ion-color-emargement)' }}>
                        <IonLabel className="ion-text-wrap">
                            <IonText color="emargement">
                                <h3 className={"ion-text-uppercase"}>
                                    FAIT À <b>{(isOrigineFoire ? foireOrigine?.label : (city || existingCity)) || '.............'}</b> LE <b>{formatted || '.............'}</b>
                                </h3>
                            </IonText>
                        </IonLabel>
                    </StyledItem>
                </IonCol>
            </IonRow>

            <IonRow>
                <SignatureCol label={'SIGNATURE DU TECHNICIEN'} name={'technicianSignature'} />
                <SignatureCol label={'SIGNATURE DU CLIENT'} name={'customerSignature'} />
            </IonRow>
        </IonGrid>
    )
}

const StyledItem = styled(IonItem)`
    --min-height: 72px;
    text-align: center;
`;

const signatureIcon = '/assets/icon/signature.svg';

function SignatureCol({ name, label }: { name: string, label: string }) {
    const {
        field: { value: signature, onChange },
    } = useController({
        name,
        defaultValue: "",
    });

    const persentSignaturePad = useSignaturePad({
        onDismiss(data, role) {
            if (role !== 'confirm') {
                return;
            }

            onChange(data);
        }
    });

    return (
        <IonCol size="6">
            <StyledItem style={{ border: '2px solid var(--ion-color-emargement)' }} button onClick={() => persentSignaturePad()}>
                {
                    signature ? (
                        <IonThumbnail slot="start">
                            <img alt="Signature" src={signature} />
                        </IonThumbnail>
                    ) : (
                        <IonIcon icon={signatureIcon} slot="start"></IonIcon>
                    )
                }
                <IonLabel className="ion-text-wrap">
                    <IonText color="emargement">
                        <h3>{label}</h3>
                    </IonText>
                </IonLabel>
            </StyledItem>
        </IonCol>
    )
}