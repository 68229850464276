import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonFooter,
    IonHeader,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import {useEffect, useRef, useState} from "react";
import BaseSignaturePad from 'signature_pad';

type SignaturePadProps = {
    onDismiss?: (data?: string, role?: string) => void
}

export default function SignaturePad({ onDismiss }: SignaturePadProps) {
    const [signaturePad, setSignaturePad] = useState<BaseSignaturePad | null>();

    const canvasRef = useRef<HTMLCanvasElement>(null);

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        if (!canvasRef.current) {
            return;
        }

        setTimeout(() => {
            const ionModal = canvasRef.current!.closest('ion-content');

            setWidth(ionModal!.clientWidth);
            setHeight(ionModal!.clientHeight);
        }, 300)
    }, [canvasRef]);

    useEffect(() => {
        function handleResize() {
            const ionModal = canvasRef.current!.closest('ion-content');

            setWidth(ionModal!.clientWidth);
            setHeight(ionModal!.clientHeight);
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (!signaturePad && canvasRef.current) {
            const signaturePad = new BaseSignaturePad(canvasRef.current, {
                minWidth: 2,
                maxWidth: 4,
                penColor: "rgb(0,0,0)",
                backgroundColor: "rgb(255,255,255)",
            });

            setSignaturePad(signaturePad);
        }
    }, [signaturePad, canvasRef])

    const handleSubmitSignature = () => {
        const data = signaturePad?.toDataURL("image/png"); // save image as PNG

        // ne rien faire si pas de données
        if (!data) {
            return;
        }

        onDismiss && onDismiss(data, 'confirm');
    }

    const handleCancel = () => onDismiss && onDismiss(undefined, 'cancel');

    const clear = () => {
        signaturePad?.clear();
    }

    const isInitializing = (width === 0 || height === 0);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>
                        Signature
                    </IonTitle>

                    <IonButtons slot={'end'}>
                        <IonButton onClick={handleCancel}>
                            Annuler
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen style={{ background: 'white' }}>
                {
                    isInitializing && (
                        <div
                            style={{ padding: 15, width: '100%' }}
                            className={'ion-text-center'}
                        >
                            <p>Initialisation de la signature en cours ...</p>
                        </div>
                    )
                }
                <canvas style={{ background: 'white' }} ref={canvasRef} width={width} height={height}></canvas>
            </IonContent>

            <IonFooter>
                <IonRow>
                    <IonCol>
                        <IonButton expand={'block'} fill={'clear'} onClick={clear}>Effacer</IonButton>
                    </IonCol>

                    <IonCol>
                        <IonButton expand={'block'} onClick={handleSubmitSignature}>Signer</IonButton>
                    </IonCol>
                </IonRow>
            </IonFooter>
        </IonPage>
    )
}
