import {IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle,} from '@ionic/react';

import {useLocation} from 'react-router-dom';
import {
    addOutline,
    addSharp,
    briefcaseOutline,
    briefcaseSharp,
    calendarOutline,
    calendarSharp,
    logOutOutline,
    logOutSharp,
    speedometerOutline,
    speedometerSharp
} from 'ionicons/icons';
import './Menu.css';
import Wordmark from "./Wordmark";
import {useHistory} from "react-router";
import {useLogout} from "./Auth/auth.store";

interface AppPage {
    url: string;
    iosIcon: string;
    mdIcon: string;
    title: string;
}

const appPages: AppPage[] = [
    {
        title: 'Dashboard',
        url: '/page/Dashboard',
        iosIcon: speedometerOutline,
        mdIcon: speedometerSharp
    },
    {
        title: 'Contrats',
        url: '/page/Contracts',
        iosIcon: briefcaseOutline,
        mdIcon: briefcaseSharp
    },
    {
        title: 'Rendez-vous',
        url: '/page/Interventions',
        iosIcon: calendarOutline,
        mdIcon: calendarSharp
    },
];

const Menu: React.FC = () => {
    const location = useLocation();
    const logoutAndRedirect = useLogoutAndRedirect();

    return (
        <IonMenu contentId="main" type="overlay">
            <IonContent>
                <IonList id="inbox-list" style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }} lines={"none"}>
                    <IonListHeader className={"ion-align-items-center ion-margin-bottom"}>
                        <Wordmark />
                    </IonListHeader>

                    <IonMenuToggle key={"new-contract"} autoHide={false} style={{ marginBottom: 20 }}>
                        <IonItem color="primary" button={true} detail={false} routerLink={'/page/NewContract'} routerDirection={"root"} lines="none">
                            <IonIcon md={addSharp} ios={addOutline} slot="start" style={{ color: 'white' }} />
                            <IonLabel>
                                Nouveau contrat
                            </IonLabel>
                        </IonItem>
                    </IonMenuToggle>

                    {
                        appPages.map((appPage, index) => {
                            return (
                                <IonMenuToggle key={index} autoHide={false}>
                                    <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                                        <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                                        <IonLabel>{appPage.title}</IonLabel>
                                    </IonItem>
                                </IonMenuToggle>
                            );
                        })
                    }

                    <IonMenuToggle key={"logout"} autoHide={false} style={{ marginTop: 'auto' }}>
                        <IonItem detail={false} button lines="none" onClick={logoutAndRedirect}>
                            <IonIcon slot="start" ios={logOutOutline} md={logOutSharp} />
                            <IonLabel>{"Déconnexion"}</IonLabel>
                        </IonItem>
                    </IonMenuToggle>
                </IonList>
            </IonContent>
        </IonMenu>
    );
};

export default Menu;

function useLogoutAndRedirect() {
    let history = useHistory();
    const logout = useLogout();

    return () => {
        // reset du state
        logout();

        // redirection après logout
        history.push('/Login');
    }
}