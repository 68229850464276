import {interventionHttp} from "../../http";
import {Endpoint} from "../endpoint.enum";
import {RdvCom} from "../../models/intervention.model";

export async function closeRdvCom(id: string) {
    const url = Endpoint.closeRdvCom.replace(':id', id);

    const {data} = await interventionHttp.post<RdvCom>(url, {});

    return data;
}
