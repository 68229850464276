import {
    IonButton, IonButtons,
    IonCard,
    IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader,
    IonIcon, IonInput, IonItem, IonNote, IonPage, IonRow, IonSelect, IonSelectOption,
    IonText, IonTitle, IonToolbar, useIonModal
} from "@ionic/react";
import {
    addOutline,
    addSharp,
    checkmarkOutline,
    checkmarkSharp,
    logoEuro,
    trashOutline,
    trashSharp
} from "ionicons/icons";
import SearchAutreMateriel from "./SearchAutreMateriel";
import {FormProvider, Controller, useForm, useWatch, useController, useFormContext} from "react-hook-form";
import styled from "@emotion/styled";
import {Product} from "../../../models/product.model";
import currency from "currency.js";
import React, {useEffect} from "react";
import {TVA_10, VatRate} from "../../../valueObjects/VatRate";
import {useProductPricingRange} from "../hooks/useProductPricingRange";
import {useProductPricingAlert, useProductPricingAlertFunc} from "../hooks/useProductPricingAlert";

export default function AutreMaterielItem() {
    const title = 'Autre materiel';

    const product = useWatch({
        name: "autreMateriel.product",
    });

    return (
        <>
            {
                !product && (
                    <Controller
                        name={"autreMateriel.product"}
                        render={
                            ({ field, fieldState, formState, }) => {
                                return (
                                    <AddButton
                                        title={title}
                                        onProductSelected={field.onChange}
                                    />
                                )
                            }
                        }
                    />
                )
            }

            {
                product && (
                    <Card title={title} />
                )
            }
        </>
    )
}

type AddButtonProps = {
    title: string,
    onProductSelected?(product: any): void,
}

function AddButton({ title, onProductSelected }: AddButtonProps) {
    const [present, dismiss] = useIonModal(Modal, {
        onDismiss: (data: string, role: string) => {
            (onProductSelected && role === 'confirm') && onProductSelected(data);
            dismiss(data, role);
        },
    });

    return (
        <IonRow>
            <IonCol>
                <IonButton color={"autre-materiel"} fill={"solid"} expand={"block"} onClick={() => present()}>
                    <IonIcon md={addSharp} ios={addOutline} slot="start"/>
                    <IonText>
                        <span>{title}</span>
                    </IonText>
                </IonButton>
            </IonCol>
        </IonRow>
    )
}

type CardProps = {
    title: string,
}

function Card({ title }: CardProps) {
    const {
        field
    } = useController({ name: 'autreMateriel.product' });

    const {setValue} = useFormContext();

    const [present, dismiss] = useIonModal(Modal, {
        onDismiss: (data: string, role: string) => {
            (field && role === 'confirm') && field.onChange(data);
            dismiss(data, role);
        },
    });

    const product = field.value as Product;

    const tvaTx = useWatch({ name: 'autreMateriel.tvaTx', defaultValue: TVA_10 });
    const totalHT = useWatch({ name: 'autreMateriel.totalHT', defaultValue: product.price });
    const totalTTC = useWatch({ name: 'autreMateriel.totalTTC', defaultValue: Number(product.price) * (1 + (Number(tvaTx) / 100)) })

    const ht = currency(totalHT);
    const baseTva = Number(tvaTx);
    const coeffTva = baseTva / 100;
    const montantTva = ht.multiply(coeffTva);
    const ttc = ht.add(montantTva);

    useEffect(() => {
        const myHt = currency(totalHT);
        const myCoeffTva = tvaTx / 100;
        const myTtc = myHt.multiply(1 + myCoeffTva);
        setValue('autreMateriel.totalTTC', myTtc.value);
    }, [totalHT, tvaTx]);

    useEffect(() => {
        const myTtc = currency(totalTTC);
        const myHt = myTtc.divide(1 + coeffTva);
        setValue('autreMateriel.totalHT', myHt.value);
    }, [totalTTC]);

    const resetProduct = () => {
        field.onChange(undefined);
    }

    // Alert changement de tarif
    const alertProductPriceRange = useProductPricingAlertFunc({ keyToReset: 'autreMateriel.totalHT' });
    const onBlur = () => {
        alertProductPriceRange({ product, tvaTx, totalTTC });
    }

    return (
        <IonCard style={{ border: '2px solid var(--ion-color-autre-materiel)' }}>
            <IonCardHeader style={{ display: 'flex', background: 'var(--ion-color-autre-materiel)' }} className={"ion-align-items-center"}>
                <div>
                    <IonCardSubtitle style={{ color: 'white' }}>{title}</IonCardSubtitle>
                    <IonCardTitle style={{ color: 'white' }}>
                        {product.label}
                    </IonCardTitle>
                </div>

                <IonButton fill="outline" style={{ marginLeft: 'auto' }} color={'light'} onClick={() => present()}>
                    Modifier
                </IonButton>
            </IonCardHeader>

            <IonCardContent style={{ paddingTop: 12 }}>
                <IonGrid>
                    <TotalRow>
                        <IonCol>
                            <IonItem lines={"none"}>
                                <IonNote slot={'start'}>HT</IonNote>
                                <Controller
                                    name={"autreMateriel.totalHT"}
                                    render={
                                        ({ field }) => <IonInput placeholder="TOTAL HT" type={'number'} value={field.value} onIonChange={field.onChange} onIonBlur={onBlur} />
                                    }
                                    defaultValue={ht.toString()}
                                />
                                <IonIcon icon={logoEuro} />
                            </IonItem>
                        </IonCol>

                        <IonCol>
                            <IonItem lines={"none"}>
                                <IonNote slot={'start'}>
                                    TVA
                                </IonNote>
                                <Controller
                                    name={"autreMateriel.tvaTx"}
                                    render={
                                        ({ field }) => (
                                            <IonSelect placeholder="TVA" value={field.value} onIonChange={field.onChange} cancelText={'Fermer'} onIonBlur={onBlur}>
                                                {
                                                    VatRate.AutreMaterielRates.map(
                                                        r => (
                                                            <IonSelectOption key={r.label} value={r.value}>{r.label}</IonSelectOption>
                                                        )
                                                    )
                                                }
                                            </IonSelect>
                                        )
                                    }
                                    defaultValue={baseTva}
                                />
                                <IonNote slot={'end'}>
                                    % =
                                </IonNote>
                            </IonItem>
                        </IonCol>

                        <IonCol>
                            <IonItem lines={"none"}>
                                <IonInput placeholder="Montant TVA" readonly value={montantTva.toString()} />
                                <IonIcon icon={logoEuro} />
                            </IonItem>
                        </IonCol>

                        <IonCol>
                            <IonItem lines={"none"}>
                                <IonNote slot={'start'}>TTC</IonNote>
                                <Controller
                                    name={"autreMateriel.totalTTC"}
                                    render={
                                        ({ field }) => {
                                            return <IonInput type={"number"} placeholder="TOTAL TTC" value={field.value} onIonChange={field.onChange} onIonBlur={onBlur} />
                                        }
                                    }
                                    defaultValue={ttc.toString()}
                                />
                                <IonIcon icon={logoEuro} />
                            </IonItem>
                        </IonCol>
                    </TotalRow>

                    <IonRow className={'ion-margin-top'}>
                        <IonCol>
                            <IonButton type={"button"} expand="block" color={"danger"} onClick={() => resetProduct()}>
                                <IonIcon slot={"start"} md={trashSharp} ios={trashOutline} />

                                Supprimer ce produit
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonCardContent>
        </IonCard>
    )
}

const TotalRow = styled(IonRow)`
    padding: 0;

    ion-col {
        padding: 0;
    }

    ion-col ion-item {
        font-weight: bold;
    }

    ion-col:first-of-type ion-item {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }

    ion-col:not(last-child) ion-item {
        --background: #f9e5cd;
    }

    ion-col:last-child {
        --background: var(--ion-color-autre-materiel);
    }

    ion-col:last-child ion-note {
        color: white;
    }

    ion-col:last-child ion-item {
        --background: var(--ion-color-autre-materiel);
    }

    ion-col:last-child ion-item {
        border-radius: 8px;
    }

    ion-col ion-input, ion-col ion-select {
        background: white;
        border-radius: 8px;
        margin-top: 6px;
        margin-bottom: 6px;
        --padding-start: 8px!important;
        font-weight: bold;
        font-size: 1.2em;
    }

    ion-icon {
        color: white;
    }
`;

const Item = styled(IonItem)`
    &.item-interactive.ion-focused, &.item-interactive.item-has-focus {
        --highlight-background: var(--ion-color-autre-materiel);
    }

    &.item-has-focus .label-floating.sc-ion-label-md-h:not(.ion-color) {
        color: var(--ion-color-autre-materiel);
    }
`;

const Modal = ({ onDismiss, }: { onDismiss: (data?: any | null | undefined | number, role?: string) => void; }) => {
    const methods = useForm();

    const handleDismiss = () => {
        const { selectedProduct } = methods.getValues();
        onDismiss(selectedProduct, 'confirm');
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton color="medium" onClick={() => onDismiss(null, 'cancel')}>
                            Annuler
                        </IonButton>
                    </IonButtons>
                    <IonTitle>Recherche autre materiel</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <FormProvider {...methods}>
                    <SearchAutreMateriel />
                </FormProvider>
            </IonContent>

            <IonButton onClick={handleDismiss} color={'autre-materiel'} expand={"block"}>
                <IonIcon md={checkmarkOutline} ios={checkmarkSharp} slot="start" />
                Valider le choix
            </IonButton>
        </IonPage>
    );
};