import {
    IonButton, IonCard, IonCardContent, IonCardSubtitle, IonCardTitle,
    IonCol,
    IonGrid, IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader, IonNote,
    IonRadio,
    IonRadioGroup, IonRow,
    IonSelect,
    IonSelectOption, IonText
} from "@ionic/react";
import {Controller, useFormContext} from "react-hook-form";
import {TypeBloc} from "../../../valueObjects/TypeBloc";
import {TypeBranchement} from "../../../valueObjects/TypeBranchement";
import {FormattedNumber, FormattedPlural} from "react-intl";
import React, {useState} from "react";
import {SearchProductResult, searchProducts} from "../../../calls/Products/searchProducts";
import LoadingDots from "../../LoadingDots";
import {alertCircleOutline, alertCircleSharp} from "ionicons/icons";

export default function SearchPacAirAir() {
    const {watch, resetField} = useFormContext();

    const [loading, setLoading] = useState(false);

    const initialResult = {
        data: [],
        pagination: {
            page: 1,
            limit: 100,
            page_count: 1,
            total: 0
        }
    };

    const [result, setResult] = useState<SearchProductResult>(initialResult);

    const totalItems = result.pagination.total;
    const products = result.data;

    // Callback version of watch.  It's your responsibility to unsubscribe when done.
    React.useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            // console.log(value, name, type);

            // Si le champs qui change n'est pas filter on ne fait rien
            // on refresh la liste uniquement quand c'est filter qui change
            if (! (name || '').startsWith('filter')) {
                return;
            }

            const { filter: { marque: category, ...extraFields } } = value as { filter: { marque: number } };

            setLoading(true);
            setResult(initialResult);
            searchProducts({ category: 19, ...extraFields }) // on force la catégorie
                .then(result => {
                    resetField('selectedProduct'); // a chaque fois que les données changent on reset selectedProduct
                    setLoading(false);
                    setResult(result);
                })
                .catch(() => {
                    setLoading(false);
                })
            ;
        });

        return () => subscription.unsubscribe();
    }, [watch]);

    return (
        <IonGrid>
            <IonRow>
                <IonCol size={'4'} className={"ion-no-padding"}>
                    <IonCard>
                        <IonCardContent>
                            <IonList>
                                <Controller
                                    key={"typeBranchement"}
                                    name={"filter.typeBranchement"}
                                    render={
                                        ({ field }) => {
                                            return (
                                                <IonRadioGroup onIonChange={field.onChange}>
                                                    <IonListHeader>
                                                        <IonLabel>Branchement</IonLabel>
                                                    </IonListHeader>

                                                    {
                                                        TypeBranchement.supportedBranchements().map(
                                                            branchement => {
                                                                return (
                                                                    <IonItem key={branchement.value} lines={'none'}>
                                                                        <IonLabel>{branchement.label}</IonLabel>
                                                                        <IonRadio slot="start" value={branchement.value} color={'photovoltaique'} />
                                                                    </IonItem>
                                                                )
                                                            }
                                                        )
                                                    }
                                                </IonRadioGroup>
                                            )
                                        }
                                    }
                                />
                            </IonList>
                        </IonCardContent>
                    </IonCard>
                </IonCol>

                <IonCol size={'8'} className={"ion-no-padding"}>
                    <IonCard>
                        <IonCardContent>
                            <IonCardSubtitle>
                                Résultat de la recherche
                            </IonCardSubtitle>
                            <IonCardTitle>
                                <FormattedPlural value={totalItems} one="Panneau photovoltaïque" other="Panneaux photovoltaïques">
                                    { (value) => <>{`${totalItems} ${value}`}</> }
                                </FormattedPlural>

                            </IonCardTitle>

                            {
                                loading && (
                                    <IonItem lines={"none"}>
                                        <IonText color={'medium'} style={{ marginRight: 5 }}>
                                            <span>Recherche en cours</span>
                                        </IonText>
                                        <LoadingDots />
                                    </IonItem>
                                )
                            }

                            {
                                !loading && products.length === 0 && (
                                    <IonText style={{ display: 'flex' }} className={'ion-align-items-center ion-margin-top'}>
                                        <IonIcon md={alertCircleSharp} ios={alertCircleOutline} />
                                        <span style={{ marginLeft: 5 }}>Pas de produits trouvés. Lancer une recherche en changeant les filtres.</span>
                                    </IonText>
                                )
                            }

                            <Controller
                                name={"selectedProduct"}
                                render={
                                    ({ field }) => {
                                        return (
                                            <IonList>
                                                <IonRadioGroup onIonChange={field.onChange}>
                                                    {
                                                        products.map(
                                                            product => (
                                                                <IonItem key={product.ref}>
                                                                    <IonLabel>
                                                                        <h2>{product.label}</h2>
                                                                        <h3>
                                                                            Tarif public <b><FormattedNumber value={Number(product.price)} style="currency" currency={"EUR"} /> HT</b>
                                                                        </h3>
                                                                    </IonLabel>
                                                                    <IonNote>
                                                                        {product.ref}
                                                                    </IonNote>
                                                                    <IonRadio slot="start" value={product} color={'photovoltaique'} />
                                                                </IonItem>
                                                            )
                                                        )
                                                    }
                                                </IonRadioGroup>
                                            </IonList>
                                        )
                                    }
                                }
                            />
                        </IonCardContent>
                    </IonCard>
                </IonCol>
            </IonRow>
        </IonGrid>
    );
}