export enum ProposalStatus {
    DRAFT = 0, // Draft status.
    VALIDATED = 1, // Validated status.
    SIGNED = 2, // Signed quote.
    NOT_SIGNED = 3, // Not signed quote.
    BILLED = 4, // Billed or processed quote.
}

export class Proposal {
    public socid?: string;
    public contactid?: any;
    public ref_client?: string;
    public statut?: number;
    public status: number;
    public datec?: number;
    public date_creation?: number;
    public datev?: string;
    public date_validation?: string;
    public date_signature?: any;
    public user_signature?: any;
    public date: number;
    public datep?: number;
    public date_livraison?: string;
    public delivery_date?: string;
    public fin_validite: number;
    public user_author_id?: string;
    public user_valid_id?: any;
    public user_close_id?: any;
    public price?: any;
    public tva?: any;
    public total?: string;
    public cond_reglement_code?: any;
    public mode_reglement_code?: any;
    public remise_percent?: any;
    public remise?: string;
    public remise_absolue?: any;
    public fk_address?: any;
    public address_type?: any;
    public availability_id?: string;
    public availability_code?: any;
    public duree_validite?: number;
    public demand_reason_id?: string;
    public demand_reason_code?: any;
    public warehouse_id?: any;
    public lines?: ProposalLine[];
    public linkedObjectsIds?: Record<'commande', Record<string, number>>;
    public line?: any;
    public fk_multicurrency?: string;
    public multicurrency_code?: string;
    public multicurrency_tx?: string;
    public multicurrency_total_ht?: string;
    public multicurrency_total_tva?: string;
    public multicurrency_total_ttc?: string;
    public id: string;
    public entity?: string;
    public import_key?: any;
    public array_languages?: any;
    public array_options?: ProposalArrayOptions;
    public canvas?: any;
    public fk_project?: string;
    public contact_id?: any;
    public user?: any;
    public origin?: any;
    public origin_id?: any;
    public ref: string;
    public ref_ext?: any;
    public country_id?: any;
    public country_code?: any;
    public state_id?: any;
    public region_id?: any;
    public barcode_type?: any;
    public barcode_type_coder?: any;
    public mode_reglement_id?: any;
    public cond_reglement_id?: any;
    public transport_mode_id?: any;
    public shipping_method_id?: any;
    public model_pdf?: string;
    public last_main_doc?: any;
    public fk_bank?: any;
    public fk_account?: any;
    public note_public?: string;
    public note_private?: string;
    public total_ht: string;
    public total_tva: string;
    public total_localtax1?: string;
    public total_localtax2?: string;
    public total_ttc: string;
    public date_modification?: number;
    public specimen?: number;
    public fk_incoterms?: string;
    public label_incoterms?: any;
    public location_incoterms?: string;
    public statut_libelle?: string;
    public availability?: any;
    public demand_reason?: any;
    public cond_reglement_doc?: any;
    public brouillon?: number;
}

export interface ProposalArrayOptions {
    options_acompte: any;
    options_autre_moyen_paiement: any;
    options_cout_total: any;
    options_foire_origine: any;
    options_montant_acompte: any;
    options_montant_cee: any;
    options_montant_maprimerenov: any;
    options_montant_mensualite: any;
    options_montant_solde: any;
    options_nb_differe: any;
    options_nb_mensualite: any;
    options_origine_affaire: any;
    options_prix_comptant: any;
    options_rabatteur_id: any,
    options_rabatteur_ids: string; // ex. "5,8,11"
    options_responsable_id: string;
    options_responsable_ids: string;
    options_solde: any;
    options_teag: any;
    options_vendeur_id: any;
    options_vendeur_ids: string;
    options_vente_credit: any;
    options_versement_initial: any;
}

export class ProposalLine {
    public fk_propal?: string;
    public fk_parent_line?: any;
    public desc?: string;
    public fk_product?: string;
    public fk_product_type?: string;
    public product_type?: string;
    public qty?: string;
    public tva_tx?: string;
    public vat_src_code?: string;
    public subprice?: string;
    public remise_percent?: string;
    public fk_remise_except?: any;
    public rang?: string;
    public fk_fournprice?: any;
    public pa_ht?: string;
    public marge_tx?: string;
    public marque_tx?: number;
    public special_code?: string;
    public info_bits?: string;
    public total_ht?: string;
    public total_tva?: string;
    public total_ttc?: string;
    public remise?: any;
    public price?: any;
    public ref?: string;
    public product_ref?: string;
    public libelle?: string;
    public label?: any;
    public product_label?: string;
    public product_desc?: string;
    public product_tobatch?: string;
    public product_barcode?: any;
    public localtax1_tx?: string;
    public localtax2_tx?: string;
    public localtax1_type?: string;
    public localtax2_type?: string;
    public total_localtax1?: string;
    public total_localtax2?: string;
    public date_start?: string;
    public date_end?: string;
    public multicurrency_subprice?: string;
    public multicurrency_total_ht?: string;
    public multicurrency_total_tva?: string;
    public multicurrency_total_ttc?: string;
    public id: string;
    public rowid?: string;
    public fk_unit?: any;
    public date_debut_prevue?: any;
    public date_debut_reel?: any;
    public date_fin_prevue?: any;
    public date_fin_reel?: any;
    public entity?: any;
    public import_key?: any;
    public array_options?: {
        options_related_product?: string,
        options_product_typology?: string,

        // Extra Pac Air Eau
        options_pac_air_eau_actions?: string,
        options_pac_air_eau_type_logement?: string,
        options_pac_air_eau_surface_logement?: string,
        options_pac_air_eau_marque_ancienne_chaudiere?: string,
        options_pac_air_eau_type_ancienne_chaudiere?: string,

        // Panneaux photovoltaïque
        options_pv_source_puissance_kit?: string;
    }
    public array_languages?: any;
    public linkedObjectsIds?: any;
    public canvas?: any;
    public origin?: any;
    public origin_id?: any;
    public ref_ext?: any;
    public statut?: any;
    public status?: any;
    public state_id?: any;
    public region_id?: any;
    public barcode_type?: any;
    public barcode_type_coder?: any;
    public demand_reason_id?: any;
    public transport_mode_id?: any;
    public last_main_doc?: any;
    public fk_bank?: any;
    public fk_account?: any;
    public lines?: any;
    public date_creation?: any;
    public date_validation?: any;
    public date_modification?: any;
    public specimen?: number;
    public description?: string;
    public weight?: any;
    public weight_units?: string;
    public volume?: any;
    public volume_units?: string;
}

export class ProposalSearchResult extends Proposal {
    public responsable_label: string;
    public vendeur_label: string;
    public rabatteur_label: string;
    public foire_label: string;
    public origine_label: string;
    public thirdparty_initial: string;
    public thirdparty_label: string;
    public thirdparty_address: string;
    public thirdparty_town: string;
    public thirdparty_zip: string;
}

export enum ProposalContactType {
    BILLING = 'BILLING',
    SHIPPING = 'SHIPPING',
    CUSTOMER = 'CUSTOMER',
}

interface ProposalUI {
    statusTranslation: Record<ProposalStatus, string>;
    statusColorMapping: Record<ProposalStatus, string>;
    contactTypeTranslation: Record<ProposalContactType, string>
}

export const proposalUI: ProposalUI = {
    statusTranslation: {
        [ProposalStatus.DRAFT]: 'Brouillon',
        [ProposalStatus.VALIDATED]: 'Validé',
        [ProposalStatus.SIGNED]: 'Accepté et signé',
        [ProposalStatus.NOT_SIGNED]: 'Refusé',
        [ProposalStatus.BILLED]: 'Facturé',
    },
    statusColorMapping: {
        [ProposalStatus.DRAFT]: 'default',
        [ProposalStatus.VALIDATED]: 'success',
        [ProposalStatus.SIGNED]: 'success',
        [ProposalStatus.NOT_SIGNED]: 'danger',
        [ProposalStatus.BILLED]: 'tertiary',
    },
    contactTypeTranslation: {
        [ProposalContactType.BILLING]: 'Adresse facturation',
        [ProposalContactType.SHIPPING]: 'Adresse chantier',
        [ProposalContactType.CUSTOMER]: 'Adresse principale',
    }
}