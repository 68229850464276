import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCol, IonDatetime, IonDatetimeButton,
    IonGrid,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel, IonModal,
    IonNote,
    IonRow, IonSelect, IonSelectOption
} from "@ionic/react";
import {Controller, useFieldArray, useFormContext, useWatch} from "react-hook-form";
import styled from "@emotion/styled";
import React, {useEffect, useState} from "react";
import {logoEuro} from "ionicons/icons";
import currency from "currency.js";
import {
    AutreMaterielFormModel,
    BallonThermodynamiqueFormModel,
    InstallationProductType,
    PacAirAirFormModel,
    PacAirEauFormModel,
    PanneauxPhotovoltaiqueFormModel
} from "../models";
import {reduce} from "lodash";
import {IVatRate, tvaTauxReduitByProduct, VatRate} from "../../../valueObjects/VatRate";

export default function InstallationItem() {
    const title = 'Installation';

    // const products = useWatch({
    //     name: "installation.products",
    //     defaultValue: [],
    // });

    return (
        <Card title={title} />
    )
}

const productKeys = Object.keys(InstallationProductType);

const poseIdMapping: any = {
    [InstallationProductType.pacAirAir]: 573,
    [InstallationProductType.pacAirEau]: 572,
    [InstallationProductType.ballonThermodynamique]: 575,
    [InstallationProductType.panneauxPhotovoltaique]: 574,
}

export const productTypeSubTitleMapping: any = {
    [InstallationProductType.pacAirAir]: 'Pompe à chaleur Air/Air',
    [InstallationProductType.pacAirEau]: 'Pompe à chaleur Air/Eau',
    [InstallationProductType.ballonThermodynamique]: 'Ballon d\'eau chaude',
    [InstallationProductType.panneauxPhotovoltaique]: 'Panneaux Photovoltaique',
    [InstallationProductType.autreMateriel]: 'Autre matériel'
}

const vatRates: any = {
    [InstallationProductType.pacAirAir]: VatRate.PacAirAirRates,
    [InstallationProductType.pacAirEau]: VatRate.PacAirEauRates,
    [InstallationProductType.ballonThermodynamique]: VatRate.BallonThermodynamiqueRates,
    [InstallationProductType.panneauxPhotovoltaique]: VatRate.PhotovoltaiqueRates,
    [InstallationProductType.autreMateriel]: VatRate.AutreMaterielRates,
}

type CardProps = {
    title: string,
}

function Card({ title }: CardProps) {
    const {watch, setValue} = useFormContext();

    const { fields, append, remove } = useFieldArray({
        name: "installation.products",
    });

    // Callback version of watch.  It's your responsibility to unsubscribe when done.
    React.useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            // console.log(value, name, type);

            // Si le champs qui change n'est pas filter on ne fait rien
            // on refresh la liste uniquement quand c'est filter qui change
            const keyIndex = productKeys.findIndex(k => (name || '').startsWith(k));
            if (keyIndex < 0) {
                return;
            }

            const productType = productKeys[keyIndex];

            const hasProduct = installationHasProduct(productType as InstallationProductType, value[productType]);
            const tvaReduit = tvaTauxReduitByProduct[productType];

            const totalHT = !hasProduct ? currency(0) : extractPoseTotalHT(productType as InstallationProductType, value[productType]);
            const product = { type: productType, totalHT: totalHT?.toString(), tvaTx: tvaReduit || 20, poseId: poseIdMapping[productType] };

            // si productType déjà là on update le total
            const existingIndex = value.installation.products.findIndex((p: any) => p.type === productType);

            if (! hasProduct) {
                remove(existingIndex);
            } else {
                (existingIndex >= 0)
                    ? setValue(`installation.products[${existingIndex}].totalHT`, totalHT?.toString())
                    : append(product)
                ;
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    return (
        <IonCard style={{ border: '2px solid var(--ion-color-installation)' }}>
            <IonCardHeader style={{ display: 'flex', background: 'var(--ion-color-installation)' }} className={"ion-align-items-center"}>
                <div>
                    <IonCardSubtitle style={{ color: 'white' }}>{title}</IonCardSubtitle>
                    <IonCardTitle style={{ color: 'white' }}>
                        Forfait : pose complète des produits - mise en route - livraison
                    </IonCardTitle>
                </div>

                <Item style={{ marginLeft: 'auto' }}>
                    <IonLabel position={"stacked"}>Delai maximal d’installation</IonLabel>
                    <Controller
                        name={"installation.delaiMaximalInstallation"}
                        render={
                            (({field}) => {
                                return (
                                    <>
                                        <IonDatetimeButton datetime="delaiMaximalInstallation" />
                                        <IonModal keepContentsMounted={true}>
                                            <IonDatetime
                                                id="delaiMaximalInstallation"
                                                value={field.value}
                                                onIonChange={field.onChange}
                                                showDefaultButtons={true}
                                                doneText="Valider"
                                                cancelText="Annuler"
                                            />
                                        </IonModal>
                                    </>
                                )
                            })
                        }
                    />
                </Item>
            </IonCardHeader>

            <IonCardContent style={{ paddingTop: 12 }}>
                <IonGrid>
                    {
                        fields.map(
                            (f, index) => {
                                return (
                                    <InstallationRow key={`installation-${index}`} index={index} />
                                )
                            }
                        )
                    }

                    <ComputeTotalsRow />
                </IonGrid>
            </IonCardContent>
        </IonCard>
    )
}

function ComputeTotalsRow() {
    const [total, setTotal] = useState({
        ht: currency(0),
        montantTva: currency(0),
        ttc: currency(0)
    });

    const installationProducts = useWatch({ name: 'installation.products', defaultValue: [] });

    useEffect(() => {
        const products = installationProducts;

        const ht = reduce(
            products.map(({ totalHT }: any) => currency(totalHT)),
            (sum, n) => sum.add(n),
        ) || currency(0);

        const montantTva = reduce(
            (
                products.map(
                    ({ totalHT, tvaTx }: any) => {
                        return currency(totalHT).multiply(Number(tvaTx) / 100);
                    }
                )
            ),
            (sum, n) => sum.add(n),
        ) || currency(0);

        const ttc = ht.add(montantTva);

        setTotal({ ht, montantTva, ttc });
    }, [installationProducts]);

    return (
        <TotalRow>
            {/*<IonCol>*/}
            {/*    <IonItem lines={"none"}>*/}
            {/*        <IonNote slot={'start'}>HT</IonNote>*/}
            {/*        <IonInput placeholder="TOTAL HT" readonly value={total.ht.toString()} />*/}
            {/*        <IonIcon icon={logoEuro} />*/}
            {/*    </IonItem>*/}
            {/*</IonCol>*/}

            <IonCol size={"6"}>
                <IonItem lines={"none"}>
                    <IonNote slot={'start'}>
                        Montant TVA
                    </IonNote>
                    <IonInput placeholder="TVA" readonly value={total.montantTva.toString()} />
                    <IonIcon icon={logoEuro} />
                </IonItem>
            </IonCol>

            <IonCol>
                <IonItem lines={"none"}>
                    <IonNote slot={'start'}>TOTAL TTC</IonNote>
                    <IonInput placeholder="TOTAL TTC" readonly value={total.ttc.toString()} />
                    <IonIcon icon={logoEuro} />
                </IonItem>
            </IonCol>
        </TotalRow>
    )
}

const TotalRow = styled(IonRow)`
    padding: 0;
    
    ion-col {
        padding: 0;
    }

    ion-col ion-item {
        font-weight: bold;
    }
    
    ion-col:first-of-type ion-item {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }
    
    ion-col:not(last-child) ion-item {
        --background: #d9cccb;
    }

    ion-col:last-child {
        --background: var(--ion-color-installation);
    }

    ion-col:last-child ion-note {
        color: white;
    }
    
    ion-col:last-child ion-item {
        --background: var(--ion-color-installation);
    }

    ion-col:last-child ion-item {
        border-radius: 8px;
    }
    
    ion-col ion-input {
        background: white;
        border-radius: 8px;
        margin-top: 6px;
        margin-bottom: 6px;
        --padding-start: 8px!important;
        font-weight: bold;
        font-size: 1.2em;
    }

    ion-icon {
        color: white;
    }
`;

const Item = styled(IonItem)`
    &.item-interactive.ion-focused, &.item-interactive.item-has-focus {
        --highlight-background: var(--ion-color-installation);
    }

    &.item-has-focus .label-floating.sc-ion-label-md-h:not(.ion-color) {
        color: var(--ion-color-installation);
    }
`;

type InstallationRowProps = {
    index: number,
}

function InstallationRow({ index }: InstallationRowProps) {
    const {getValues, setValue} = useFormContext();

    const productTypeName = `installation.products[${index}].type`;
    const totalHTName = `installation.products[${index}].totalHT`;
    const tvaTxName = `installation.products[${index}].tvaTx`;
    const totalTTCName = `installation.products[${index}].totalTTC`;

    const totalHT = useWatch({ name: totalHTName });
    const tvaTx = useWatch({ name: tvaTxName });
    const totalTTC = useWatch({ name: totalTTCName, defaultValue: Number(totalHT) * (1 + (Number(tvaTx) / 100)) })

    const productType = useWatch({ name: productTypeName });
    const label = productTypeSubTitleMapping[productType];

    const productTvaTx = useWatch({ name: `${productType}.tvaTx`, defaultValue: 0 });

    useEffect(() => {
        if (!productTvaTx) {
            return;
        }

        setValue(tvaTxName, productTvaTx);
    }, [productTvaTx]);

    const ttc = currency(totalTTC);


    useEffect(() => {
        const myHt = currency(totalHT);
        const myCoeffTva = tvaTx / 100;
        const myTtc = myHt.multiply(1 + myCoeffTva);
        setValue(totalTTCName, myTtc.value);
    }, [totalHT, tvaTx]);

    useEffect(() => {
        const myTtc = currency(totalTTC);
        const coeffTva = Number(tvaTx) / 100;
        const myHt = myTtc.divide(1 + coeffTva);
        setValue(totalHTName, myHt.value);
    }, [totalTTC]);

    return (
        <IonRow>
            <IonCol size={"5"}>
                <IonItem>
                    <IonLabel>
                        <h2>Pose, raccordement et mise en service</h2>
                        <h3><b>{label}</b></h3>
                    </IonLabel>
                </IonItem>
            </IonCol>

            <IonCol size={"3"}>
                <Controller
                    name={tvaTxName}
                    render={
                        ({field}) => {
                            return (
                                <IonItem color={'light'} lines={'none'}>
                                    <IonNote slot={'start'}>
                                        TVA
                                    </IonNote>
                                    <IonSelect placeholder="TVA" value={field.value} onIonChange={field.onChange} cancelText={'Fermer'}>
                                        {
                                            vatRates[productType].map(
                                                (r: IVatRate) => (
                                                    <IonSelectOption key={r.label} value={r.value}>{r.label}</IonSelectOption>
                                                )
                                            )
                                        }
                                    </IonSelect>
                                    <IonNote slot={'end'}>
                                        %
                                    </IonNote>
                                </IonItem>
                            )
                        }
                    }
                />
            </IonCol>

            <IonCol>
                <Controller
                    name={totalTTCName}
                    render={
                        ({field}) => {
                            return (
                                <IonItem color={'light'} lines={'none'}>
                                    <IonNote slot={'start'}>
                                        TTC
                                    </IonNote>
                                    <IonInput
                                        value={Number(field.value)}
                                        onIonChange={field.onChange}
                                        type={'number'}
                                        min={1}
                                    />
                                    <IonIcon slot={"end"} icon={logoEuro} />
                                </IonItem>
                            )
                        }
                    }
                    defaultValue={ttc.value}
                />
            </IonCol>
        </IonRow>
    );
}

function installationHasProduct(type: InstallationProductType, value: any) {
    if (type === InstallationProductType.pacAirEau) {
        const v = value as PacAirEauFormModel;

        const { product } = v;

        return !!product;
    }

    if (type === InstallationProductType.pacAirAir) {
        const v = value as PacAirAirFormModel;

        const { products } = v;

        return products.filter(p => !!p).length > 0;
    }

    if (type === InstallationProductType.ballonThermodynamique) {
        const v = value as BallonThermodynamiqueFormModel;

        const { product } = v;

        return !!product;
    }

    if (type === InstallationProductType.panneauxPhotovoltaique) {
        const v = value as PanneauxPhotovoltaiqueFormModel;

        const {product} = v;

        return !!product;
    }

    if (type === InstallationProductType.autreMateriel) {
        const v = value as AutreMaterielFormModel;

        const { product } = v;

        return !!product;
    }
}

function extractPoseTotalHT(type: InstallationProductType, value: any) {
    if (type === InstallationProductType.pacAirEau) {
        const v = value as PacAirEauFormModel;

        const { product } = v;

        return currency(product.array_options?.options_prix_pose || 0);
    }

    if (type === InstallationProductType.pacAirAir) {
        const v = value as PacAirAirFormModel;

        const { products } = v;

        const ht = reduce(
            products.map(({ product, qty }: any) => {
                const prixPose = product.array_options?.options_prix_pose || 0;
                return currency(prixPose).multiply(Number(qty || 1))
            }),
            (sum, n) => sum.add(n),
        );

        return ht;
    }

    if (type === InstallationProductType.ballonThermodynamique) {
        const v = value as BallonThermodynamiqueFormModel;

        const { product } = v;

        return currency(product.array_options?.options_prix_pose || 0);
    }

    if (type === InstallationProductType.panneauxPhotovoltaique) {
        const v = value as PanneauxPhotovoltaiqueFormModel;

        const { product, qty } = v;

        return currency(product.array_options?.options_prix_pose || 0).multiply(Number(qty) || 1);
    }

    if (type === InstallationProductType.autreMateriel) {
        const v = value as AutreMaterielFormModel;

        const { product } = v;

        return currency(product.array_options?.options_prix_pose || 0);
    }
}