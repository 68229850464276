import TechnicianAvatars from "../components/Intervention/TechnicianAvatars";
import {IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar} from "@ionic/react";
import TechnicianAppointmentCard from "../components/Intervention/TechnicianAppointmentCard";
import {FormProvider, useForm} from "react-hook-form";

interface ScheduleFormModel {
    technician: {
        id: string,
        lastname: string,
        firstname: string,
        login: string,
    };
    selectedDate: string;
}

const ScheduleInterventionPage: React.FC = () => {
    const methods = useForm<ScheduleFormModel>();

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Programmer VT</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <FormProvider {...methods}>
                    <TechnicianAvatars />

                    <TechnicianAppointmentCard />
                </FormProvider>
            </IonContent>
        </IonPage>
    );
}

export default ScheduleInterventionPage;