import {Proposal} from "../../models/proposal.model";
import {mailingHttp} from "../../http";

interface SendContractByMailDto {
    recipient: string,
    proposal: Proposal;
    attachments: Array<{
        filename: string;
        path: string; // base64,
    }>;
}

export function sendContractByMail({ recipient, proposal, attachments }: SendContractByMailDto) {
    return mailingHttp.post('/mailing/send', {
        to: recipient,
        cc: "commercial@kamess.fr",
        bcc: "amdp.solutions@gmail.com",
        subject: `Confirmation: e-signature document Devis n° ${proposal.ref}`,
        template: "kamess/common/confirmation-signature",
        context: proposalToContext(proposal),
        attachments,
    })
}

function proposalToContext(p: Proposal) {
    return {
        ref: p.ref,
    }
}