import {ManagedProductKey} from "./index";
import {
    BallonThermodynamiqueWhiteIconSmall,
    PacAirAirWhiteIconSmall,
    PacAirEauWhiteIconSmall,
    PhotovoltaiqueWhiteIconSmall
} from "../../Icons";

export type ProductTypology = 'PAC_AIR_EAU' | 'PAC_AIR_AIR' | 'BALLON_THERMO' | 'PHOTOVOLTAIC' | 'AUTRE_MATERIEL';

export const productTypologyMap: Record<string, ManagedProductKey> = {
    PAC_AIR_EAU: 'pacAirEau',
    PAC_AIR_AIR: 'pacAirAir',
    BALLON_THERMO: 'ballonThermodynamique',
    PHOTOVOLTAIC: 'panneauxPhotovoltaique',
    AUTRE_MATERIEL: 'autreMateriel',
}

interface ProductTypologyUI {
    typologyIcon: Record<ProductTypology, any>
}

export const productTypologyUI: ProductTypologyUI = {
    typologyIcon: {
        PAC_AIR_EAU: PacAirEauWhiteIconSmall,
        PAC_AIR_AIR: PacAirAirWhiteIconSmall,
        BALLON_THERMO: BallonThermodynamiqueWhiteIconSmall,
        PHOTOVOLTAIC: PhotovoltaiqueWhiteIconSmall,
        AUTRE_MATERIEL: undefined,
    }
}