import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonModal,
    IonPage,
    IonTitle,
    IonToolbar,
    useIonViewDidEnter
} from "@ionic/react";
import React from "react";

import {chatboxEllipsesOutline, chatboxEllipsesSharp, navigateOutline, navigateSharp} from "ionicons/icons";
import {useParams} from "react-router";
import InterventionLabel from "../components/Shared/InterventionLabel";
import {useMutation, useQuery} from "@tanstack/react-query";
import {Intervention} from "../models/intervention.model";
import {DateTime} from "luxon";
import styled from "@emotion/styled";
import {getIntervention} from "../calls/Interventions/getIntervention";
import {agentArrivedAtTheInterventionPlace} from "../calls/Interventions/RdvCom/agentArrivedAtTheInterventionPlace";
import {agentIsOnTheWayToIntervention} from "../calls/Interventions/RdvCom/agentIsOnTheWayToIntervention";
import {agentIsLeavingInterventionPlace} from "../calls/Interventions/RdvCom/agentIsLeavingInterventionPlace";
import {closeRdvCom} from "../calls/Interventions/closeRdvCom";

const InterventionDetail: React.FC = () => {
    useIonViewDidEnter(() => {
        window.dispatchEvent(new Event("resize"));
    });
    const { id } = useParams<{ id: string }>();

    const { isError, isFetched, isLoading, data: intervention, refetch } = useInterventionDetail(id);

    if (isLoading) {
        return <></>;
    }

    if (isError) {
        return <></>;
    }

    return (
        <InterventionPage intervention={intervention} />
    )
};

export default InterventionDetail;

function InterventionPage({ intervention }: { intervention: Intervention }) {
    const {
        id,
        customer,
        proposal,
        reference,
        place: {
            address,
            zipCode,
            town,
            coords
        },
        type, typeData,
        status,
        notes,
    } = intervention;

    const addressText = [
        address,
        zipCode,
        town,
    ].filter(i => !!i).join(', ');

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton />
                    </IonButtons>
                    <IonTitle>
                        <InterventionLabel type={type} />
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonItem>
                    <IonLabel className="ion-text-wrap">
                        <h3>{customer.name}</h3>
                        <p>{addressText}</p>
                    </IonLabel>
                    <IonIcon slot={"end"} ios={navigateOutline} md={navigateSharp} />
                </IonItem>

                {
                    notes && (
                        <IonItem>
                            <IonLabel className="ion-text-wrap">
                                <h3>Notes / Commentaires</h3>
                                <p>{notes}</p>
                            </IonLabel>
                            <IonIcon slot={"end"} ios={chatboxEllipsesOutline} md={chatboxEllipsesSharp} />
                        </IonItem>
                    )
                }
            </IonContent>

            <IonFooter>
                <IonToolbar>
                    {({
                        SCHEDULED: <OnTheWayButton id={id} />,
                        ON_THE_WAY: <ArrivedButton id={id} />,
                        ARRIVED: <DepartureButton id={id} />,
                    } as any)[status]}
                </IonToolbar>
            </IonFooter>
        </IonPage>
    )
}

const formatDate = (date: string) => DateTime.fromISO(date, { zone: "Europe/Paris" }).setLocale('fr').toFormat('dd/MM/yyyy HH:mm');
const formatPoseDate = (date: string) => DateTime.fromISO(date, { zone: "Europe/Paris" }).setLocale('fr').toLocaleString(DateTime.DATE_MED);

const LgModal = styled(IonModal)`
    --width: 80%;
    --height: 80%;
`;

function useInterventionDetail(id: string) {
    return useQuery(['intervention', id], ({ queryKey }) => {
        return getIntervention(queryKey[1] as string);
    });
}

function OnTheWayButton({ id  }: { id: string }) {
    const { mutateAsync, isLoading } = useMutation(() => agentIsOnTheWayToIntervention(id));
    const { refetch } = useInterventionDetail(id);

    const handleClick = async () => {
        await mutateAsync();
        await refetch();
    }

    return (
        <IonButton expand={"block"} color={"success"} onClick={handleClick} disabled={isLoading}>
            En route vers le client
        </IonButton>
    )
}

function ArrivedButton({ id  }: { id: string }) {
    const { mutateAsync, isLoading } = useMutation(() => agentArrivedAtTheInterventionPlace(id));
    const { refetch } = useInterventionDetail(id);

    const handleClick = async () => {
        await mutateAsync();
        await refetch();
    }

    return (
        <IonButton expand={"block"} onClick={handleClick} disabled={isLoading}>
            Arrivé chez le client
        </IonButton>
    )
}

function DepartureButton({ id  }: { id: string }) {
    const { mutateAsync: leave, isLoading: leaving } = useMutation(() => agentIsLeavingInterventionPlace(id));
    const { mutateAsync: complete, isLoading: completing } = useMutation(() => closeRdvCom(id));
    const { refetch } = useInterventionDetail(id);

    const handleClick = async () => {
        await leave();
        await complete();
        await refetch();
    }

    return (
        <IonButton expand={"block"} onClick={handleClick} disabled={leaving || completing}>
            Départ
        </IonButton>
    )
}
