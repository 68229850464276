import {searchInterventions, SearchInterventionsPayload} from "../../calls/Interventions/searchInterventions";
import {QueryFunction, useQuery} from "@tanstack/react-query";
import {Intervention} from "../../models/intervention.model";
import {PaginationResultModel} from "../../models/common.model";

export function useInterventionsQuery(payload: SearchInterventionsPayload = {}) {
    const queryKey = ['interventions', {payload}];
    const queryFn: QueryFunction<PaginationResultModel<Intervention>> = () => {
        return searchInterventions(payload);
    }

    return useQuery<PaginationResultModel<Intervention>>(queryKey, queryFn);
}