import {http} from "../http";
import {Endpoint} from "./endpoint.enum";

type DownloadDocumentPayload = {
    modulepart?: string;
    original_file?: string;
    entity: number,
    attachment?: number,
}

type DownloadDocumentReturn  = {
    filename: string,
    content: string,
    'content-type': string,
}

export async function downloadDocument(payload: DownloadDocumentPayload): Promise<DownloadDocumentReturn> {
    const { data } = await http.get(Endpoint.documentDownload, {
        params: payload,
    });

    return data;
}