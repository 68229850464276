import {http} from "../../http";
import {Endpoint} from "../endpoint.enum";

export type ProposalHeaderDto = {
    socid: number;
    fk_project: number;
    model_pdf: string;
    date?: number;
    duree_validite?: number;
    note_private?: string;
    note_public?: string;
    array_options?: {
        origine_affaire: number,
        foire_origine?: string,
        rabatteur_ids: string,
        responsable_ids: string,
        vendeur_ids: string,
        installation_day_delai_max?: number;
        technician_signature?: string;
        customer_signature?: string;
    },
    entity: number;
}

async function createProposalHeader(dto: ProposalHeaderDto) {
    const { data } = await http.post(Endpoint.proposals, dto);

    return data;
}


export type ProposalLineDto = {
    desc?: string;
    subprice?: number;
    qty?: number;
    tva_tx?: number;
    fk_product?: string;
    product_type?: number;
    array_options?: {
        related_product?: string,
    }
}

async function attachLineToProposal(proposalId: string, dto: ProposalLineDto) {
    const url = Endpoint.proposalLines.replace(':id', proposalId);

    const { data } = await http.post(url, dto);

    return data;
}

export async function createProposal(headerDto: ProposalHeaderDto, lines: Array<ProposalLineDto>) {
    try {
        const headerId = await createProposalHeader(headerDto);

        for (const line of lines) {
            await attachLineToProposal(headerId, line);
        }

        return headerId;
    } catch (e) {
        console.error(e);
    }
}