import React from "react";
import {IonDatetime, IonDatetimeButton, IonInput, IonItem, IonLabel, IonModal, IonTextarea} from "@ionic/react";
import {Controller, useWatch} from "react-hook-form";
import {InterventionType} from "../../models/intervention.model";

export interface InterventionFormModel {
    type: InterventionType;
    date?: string;
    technician?: { id: string, name: string };

    // Visite technique
    duration?: number;
    desiredInterventionDate?: string;
    particularConditions?: string;
    observations?: string;

    // Pose
    theoricalStartDate: string;
    theoricalEndDate: string;
}

export default function InterventionForm() {
    const type = useWatch({ name: 'type' });

    return(
        <form>
            {/*<Controller*/}
            {/*    name="type"*/}
            {/*    defaultValue={InterventionType.VISITE_TECHNIQUE}*/}
            {/*    render={*/}
            {/*        ({field: {onChange, value}}) => (*/}
            {/*            <IonRadioGroup value={value} onIonChange={onChange}>*/}
            {/*                <IonListHeader>*/}
            {/*                    <IonLabel>*/}
            {/*                        Type*/}
            {/*                    </IonLabel>*/}
            {/*                </IonListHeader>*/}
            {/*                <IonRow>*/}
            {/*                    <IonCol>*/}
            {/*                        <IonItem  lines={"none"}>*/}
            {/*                            <IonLabel>Visite Technique</IonLabel>*/}
            {/*                            <IonRadio value={InterventionType.VISITE_TECHNIQUE} slot={"start"} />*/}
            {/*                        </IonItem>*/}
            {/*                    </IonCol>*/}
            {/*                    <IonCol>*/}
            {/*                        <IonItem lines={"none"}>*/}
            {/*                            <IonLabel>Pose</IonLabel>*/}
            {/*                            <IonRadio value={InterventionType.POSE} slot={"start"} />*/}
            {/*                        </IonItem>*/}
            {/*                    </IonCol>*/}
            {/*                    <IonCol>*/}
            {/*                        <IonItem lines={"none"}>*/}
            {/*                            <IonLabel>SAV</IonLabel>*/}
            {/*                            <IonRadio value={InterventionType.SAV} slot={"start"} />*/}
            {/*                        </IonItem>*/}
            {/*                    </IonCol>*/}
            {/*                    <IonCol>*/}
            {/*                        <IonItem lines={"none"}>*/}
            {/*                            <IonLabel>Entretien</IonLabel>*/}
            {/*                            <IonRadio value={InterventionType.ENTRETIEN} slot={"start"} />*/}
            {/*                        </IonItem>*/}
            {/*                    </IonCol>*/}
            {/*                </IonRow>*/}
            {/*            </IonRadioGroup>*/}
            {/*        )*/}
            {/*    }*/}
            {/*/>*/}

            {/*<IonItem className={"ion-margin-top"}>*/}
            {/*    <Controller*/}
            {/*        name={"technician"}*/}
            {/*        render={*/}
            {/*            ({field}) => {*/}
            {/*                return(*/}
            {/*                    <></>*/}
            {/*                )*/}
            {/*            }*/}
            {/*        }*/}
            {/*    />*/}
            {/*</IonItem>*/}

            <Controller
                name={"date"}
                render={({ field , fieldState:{error}}) => {
                    return (
                        <>
                            <IonItem>
                                <IonLabel>Date</IonLabel>
                                <IonDatetimeButton datetime="datetime" />
                                <IonModal keepContentsMounted={true}>
                                    <IonDatetime
                                        id="datetime"
                                        value={field.value}
                                        onIonChange={field.onChange}
                                        showDefaultButtons={true}
                                        doneText="Valider"
                                        cancelText="Annuler"
                                    />
                                </IonModal>
                            </IonItem>

                        </>
                    )
                }}
            />

            {
                type === InterventionType.VISITE_TECHNIQUE && (
                    <>

                        <Controller
                            name={"observations"}
                            render={
                                ({field, fieldState: { error} }) => {
                                    return (
                                        <IonItem className={"ion-margin-top"}>
                                            <IonLabel position="floating">Observations</IonLabel>
                                            <IonTextarea value={field.value} onIonChange={field.onChange} rows={3} />
                                        </IonItem>
                                    )
                                }
                            }
                        />

                        <IonItem>
                            <IonLabel position="floating">Durée (h)</IonLabel>
                            <Controller
                                name={"duree"}
                                defaultValue={1}
                                render={({field }) => <IonInput value={field.value} onIonChange={field.onChange} type={"number"}/>}
                            />
                        </IonItem>

                        {/*<Controller*/}
                        {/*    name={"desiredInterventionDate"}*/}
                        {/*    render={({ field , fieldState:{error}}) => {*/}
                        {/*        return (*/}
                        {/*            <>*/}
                        {/*                <IonItem>*/}
                        {/*                    <IonLabel>Date VT souhaitée</IonLabel>*/}
                        {/*                    <IonDatetimeButton datetime="desiredInterventionDate" />*/}
                        {/*                    <IonModal keepContentsMounted={true}>*/}
                        {/*                        <IonDatetime*/}
                        {/*                            id="desiredInterventionDate"*/}
                        {/*                            value={field.value}*/}
                        {/*                            onIonChange={field.onChange}*/}
                        {/*                            showDefaultButtons={true}*/}
                        {/*                            doneText="Valider"*/}
                        {/*                            cancelText="Annuler"*/}
                        {/*                        />*/}
                        {/*                    </IonModal>*/}
                        {/*                </IonItem>*/}

                        {/*            </>*/}
                        {/*        )*/}
                        {/*    }}*/}
                        {/*/>*/}
                    </>
                )
            }

            {/*{*/}
            {/*    type === InterventionType.POSE && (*/}
            {/*        <>*/}
            {/*            <Controller*/}
            {/*                name={"theoricalStartDate"}*/}
            {/*                render={({ field , fieldState:{error}}) => {*/}
            {/*                    return (*/}
            {/*                        <>*/}
            {/*                            <IonItem>*/}
            {/*                                <IonLabel>Date début</IonLabel>*/}
            {/*                                <IonDatetimeButton datetime="theoricalStartDate" />*/}
            {/*                                <IonModal keepContentsMounted={true}>*/}
            {/*                                    <IonDatetime*/}
            {/*                                        id="theoricalStartDate"*/}
            {/*                                        value={field.value}*/}
            {/*                                        onIonChange={field.onChange}*/}
            {/*                                        showDefaultButtons={true}*/}
            {/*                                        doneText="Valider"*/}
            {/*                                        cancelText="Annuler"*/}
            {/*                                    />*/}
            {/*                                </IonModal>*/}
            {/*                            </IonItem>*/}

            {/*                        </>*/}
            {/*                    )*/}
            {/*                }}*/}
            {/*            />*/}

            {/*            <Controller*/}
            {/*                name={"theoricalEndDate"}*/}
            {/*                render={({ field , fieldState:{error}}) => {*/}
            {/*                    return (*/}
            {/*                        <>*/}
            {/*                            <IonItem>*/}
            {/*                                <IonLabel>Date fin</IonLabel>*/}
            {/*                                <IonDatetimeButton datetime="theoricalEndDate" />*/}
            {/*                                <IonModal keepContentsMounted={true}>*/}
            {/*                                    <IonDatetime*/}
            {/*                                        id="theoricalEndDate"*/}
            {/*                                        value={field.value}*/}
            {/*                                        onIonChange={field.onChange}*/}
            {/*                                        showDefaultButtons={true}*/}
            {/*                                        doneText="Valider"*/}
            {/*                                        cancelText="Annuler"*/}
            {/*                                    />*/}
            {/*                                </IonModal>*/}
            {/*                            </IonItem>*/}

            {/*                        </>*/}
            {/*                    )*/}
            {/*                }}*/}
            {/*            />*/}
            {/*        </>*/}
            {/*    )*/}
            {/*}*/}
        </form>
    )
}