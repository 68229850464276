import {http} from "../../http";
import {Endpoint} from "../endpoint.enum";
import {ActionComm} from "../../models/action-comm.model";

export async function getEvent(id: number) {
    const url = Endpoint.agendaEventId.replace(':id', id.toString());

    const {data} = await http.get<ActionComm>(url);

    return data;
}