export class TypeBallon {
    private constructor(
        public label: string,
        public value: string | undefined,
    ) {}

    static THERMODYNAMIQUE = new TypeBallon('Thermodynamique', 'THERMODYNAMIQUE');

    static ELECTRIQUE = new TypeBallon('Électrique', 'ELECTRIQUE');

    public static supportedTypes(): Array<TypeBallon> {
        return [
            this.THERMODYNAMIQUE,
            this.ELECTRIQUE
        ];
    }
}