import {http} from "../../http";
import {Endpoint} from "../endpoint.enum";

export interface CreateContactDto {
    lastname: string;
    firstname?: string;
    socid: number;
    civility_code?: string;
    address?: string;
    zip?: string;
    town?: string;
    country_id?: number;
    note_private?: string;
    note_public?: string;
    phone?: string;
    phone_perso?: string;
    phone_mobile?: string;
    array_options?: {
        lat?: number,
        lng?: number;
    }
}

export async function createContact(dto: CreateContactDto) {
    const {data} = await http.post<number>(Endpoint.contacts, dto);

    return data;
}