import React from "react";
import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonMenuButton,
    IonNote,
    IonPage,
    IonRow,
    IonSkeletonText,
    IonText,
    IonTitle,
    IonToolbar,
    useIonAlert,
    useIonModal
} from "@ionic/react";
import {useParams} from "react-router";
import LoadingDots from "../components/LoadingDots";
import {Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {FormattedNumber} from "react-intl";
import {useProposalDetail} from "../components/ContractDetail/useProposalDetail";
import currency from "currency.js";
import {DateTime, Duration} from "luxon";
import AddNewProposalLineModal from "../components/ContractDetail/AddNewProposalLineModal";
import {ProposalLineFormModel} from "../components/ContractDetail/ProposalLineForm";
import {
    addOutline,
    addSharp,
    checkmarkCircleOutline,
    checkmarkCircleSharp,
    sendOutline,
    sendSharp,
    trashOutline,
    trashSharp
} from "ionicons/icons";
import {useThirdPartyDetail} from "../components/ThirdParty/useThirdPartyDetail";
import {ProposalLine} from "../models/proposal.model";
import EditProposalLineModal from "../components/ContractDetail/EditProposalLineModal";
import {useMutation} from "@tanstack/react-query";
import {deleteProposalLine} from "../calls/Proposals/deleteProposalLine";
import {useInterventionsQuery} from "../components/Intervention/useInterventionsQuery";
import {InterventionType, interventionUI, VisiteTechnique} from "../models/intervention.model";
import {draftProposal} from "../calls/Proposals/draftProposal";
import {validateProposal} from "../calls/Proposals/validateProposal";
import IonLoadingButton from "../components/Common/Lab/IonLoadingButton";
import {useSendProposalByEmail} from "../components/ContractDetail/useSendProposalByEmail";

const ContractDetail: React.FC = () => {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let { id: proposalId } = useParams<{ id: string }>();

    const { isError, isFetched, isLoading, data: proposal } = useProposalDetail(proposalId);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>
                        {
                            isLoading
                                ? (<IonSkeletonText animated={true} style={{ 'width': '80%' }} />)
                                : <>Devis {proposal?.ref}</>
                        }

                        {
                            isLoading
                                ? (<IonSkeletonText animated={true} style={{ 'width': '50%' }} />)
                                : (
                                    <IonLabel className={'ion-no-margin'}>
                                        <p>Date du devis <DateItem value={proposal?.date || 0} />, Fin de validité <DateItem value={proposal?.fin_validite || 0} /></p>
                                    </IonLabel>
                                )
                        }
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            routerLink={`/page/EditContract/${proposalId}`}
                            routerDirection={'forward'}
                        >
                            <IonIcon slot="start" icon={penToSquare}></IonIcon>
                            Editer
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                {
                    isLoading && (
                        <IonItem lines={"none"} color={"light"}>
                            <IonText color={'medium'} style={{ marginRight: 5 }}>
                                <span>Veuillez patienter</span>
                            </IonText>
                            <LoadingDots />
                        </IonItem>
                    )
                }

                {
                    !isLoading && (
                        <IonRow>
                            <IonCol size={'9'}>
                                <IonCard>
                                    <IonCardHeader>
                                        <IonCardSubtitle>Détails du devis</IonCardSubtitle>
                                    </IonCardHeader>

                                    <IonCardContent>
                                        <TableContainer>
                                            <Table sx={{ minWidth: 650 }}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Produit</TableCell>
                                                        <TableCell align="right">Qté</TableCell>
                                                        <TableCell align="right">P.U HT</TableCell>
                                                        <TableCell align="right">Remise</TableCell>
                                                        <TableCell align="right">TVA</TableCell>
                                                        <TableCell align="right">Prix TTC</TableCell>
                                                        <TableCell align="right">Actions</TableCell>
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                    {
                                                        (proposal?.lines || []).map((line) => (
                                                            <TableRow
                                                                key={line.ref}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <ProductCell line={line} />
                                                                <TableCell align="right">{line.qty}</TableCell>
                                                                <TableCell align="right">
                                                                    <FormattedNumber
                                                                        value={Number(line.subprice)}
                                                                        style="currency"
                                                                        currency={"EUR"}
                                                                    />
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    <FormattedNumber
                                                                        style='percent'
                                                                        value={Number(line.remise_percent || 0) / 100}
                                                                        minimumFractionDigits={2}
                                                                    />
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    <FormattedNumber
                                                                        style='percent'
                                                                        value={Number(line.tva_tx || 0) / 100}
                                                                        minimumFractionDigits={2}
                                                                    />
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    <FormattedNumber
                                                                        value={Number(line.total_ttc)}
                                                                        style="currency"
                                                                        currency={"EUR"}
                                                                    />
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    <EditLineButton
                                                                        proposalId={proposal?.id || ''}
                                                                        line={line}
                                                                    />

                                                                    <DeleteLineButton
                                                                        proposalId={proposal?.id || ''}
                                                                        lineId={line.id || ''}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                        <Divider />

                                        <IonRow>
                                            <IonCol size={'6'}>
                                                <AddProposalLineButton id={proposal?.id || ''} />
                                            </IonCol>
                                            <IonCol size={'6'}>
                                                <IonList>
                                                    <IonItem>
                                                        <IonLabel>
                                                            <h2>
                                                                Total HT
                                                            </h2>
                                                        </IonLabel>
                                                        <IonNote slot="end">
                                                            <CurrencyItem value={proposal?.total_ht || 0} />
                                                        </IonNote>
                                                    </IonItem>

                                                    <IonItem>
                                                        <IonLabel>
                                                            <h2>
                                                                Montant TVA
                                                            </h2>
                                                        </IonLabel>
                                                        <IonNote slot="end">
                                                            <CurrencyItem value={proposal?.total_tva || 0} />
                                                        </IonNote>
                                                    </IonItem>

                                                    <IonItem>
                                                        <IonLabel>
                                                            <h2>
                                                                Total TTC
                                                            </h2>
                                                        </IonLabel>
                                                        <IonNote slot="end">
                                                            <CurrencyItem value={proposal?.total_ttc || 0} />
                                                        </IonNote>
                                                    </IonItem>
                                                </IonList>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>

                            <IonCol size={'3'}>
                                <IonCard>
                                    <IonCardHeader>
                                        <IonCardSubtitle>Information client</IonCardSubtitle>
                                    </IonCardHeader>

                                    <IonList>
                                        <CustomerItems id={proposal?.socid as string} />
                                    </IonList>

                                    <SendMailButton proposalId={proposal?.id || ''} />
                                </IonCard>

                                <ScheduleInterventionCard
                                    proposalId={proposal?.id || ''}
                                    thirdPartyId={proposal?.socid as string}
                                />
                            </IonCol>
                        </IonRow>
                    )
                }
            </IonContent>
        </IonPage>
    )
}

export default ContractDetail;

function SendMailButton({ proposalId }: { proposalId: string }) {
    const { data: proposal, isLoading: loadingProposal } = useProposalDetail(proposalId);
    const { data: thirdParty, isLoading: thirdPartyLoading } = useThirdPartyDetail(proposal?.socid || '');

    const { mutate, isLoading, isError, isSuccess } = useSendProposalByEmail();

    const send = () => {
        if (!proposal || !thirdParty) {
            return;
        }

        // Envoi du contract par email si email existant
        const email = thirdParty?.email || '';

        mutate({ to: email, proposal, customer: thirdParty });
    }

    return (
        <>
            <IonLoadingButton
                color={"primary"}
                fill={"outline"}
                expand={"block"}
                loading={isLoading}
                onClick={() => send()}
                disabled={loadingProposal}
            >
                <IonIcon slot="start" md={sendSharp} ios={sendOutline}></IonIcon>
                Envoyer Email
            </IonLoadingButton>

            {
                (!isLoading && isError) && (
                    <div className={'ion-padding ion-text-center'}>
                        <IonText color={"danger"}>
                            Une erreur est survenue lors de l'envoi du mail. Veuillez réessayer.
                        </IonText>
                    </div>
                )
            }

            {
                (isSuccess && !isError) && (
                    <div className={'ion-padding'}>
                        <IonText color={'primary'} style={{ display: 'flex' }} className={'ion-align-items-center ion-justify-content-center'}>
                            <IonIcon md={checkmarkCircleSharp} ios={checkmarkCircleOutline} />
                            <span style={{ marginLeft: 1 }}>Email envoyé</span>
                        </IonText>
                    </div>
                )
            }
        </>
    )
}

function ProductCell({ line }: { line: ProposalLine }) {
    const hasProduct = !!line.fk_product;

    return (
        <TableCell component="th" scope="row">
            {
                hasProduct ? (
                    <IonLabel>
                        <h2>
                            {line.ref}
                        </h2>
                        <h3>{line.libelle}</h3>
                    </IonLabel>
                ) : (
                    <IonLabel>
                        <h2>
                            {line.label}
                        </h2>
                        <h3>{line.description}</h3>
                    </IonLabel>
                )
            }
        </TableCell>
    )
}

function CustomerItems({ id }: { id: string }) {
    const { isError, isFetched, isLoading, data: customer } = useThirdPartyDetail(id);

    if (isLoading) {
        return <LoadingDots />;
    }

    const address = [customer?.address, customer?.zip, customer?.town].filter(part => !!part).join(', ');

    return (
        <>
            <IonItem lines={"none"}>
                <IonLabel className={"ion-text-wrap"}>
                    <h2>{customer?.array_options?.options_civilite || ''} {customer?.firstname} {customer?.name}</h2>
                    <p>{address}</p>
                </IonLabel>
            </IonItem>

            {/*<IonItem>*/}
            {/*    <IonLabel>*/}
            {/*        <h2>{customer?.phone}</h2>*/}
            {/*        <p>Téléphone</p>*/}
            {/*    </IonLabel>*/}
            {/*</IonItem>*/}

            {/*<IonItem>*/}
            {/*    <IonLabel>*/}
            {/*        <h2>{customer?.email}</h2>*/}
            {/*        <p>Email</p>*/}
            {/*    </IonLabel>*/}
            {/*</IonItem>*/}
        </>
    )
}

const upRightFromSquareIcon = '/assets/icon/up-right-from-square.svg';

function ScheduleInterventionCard({ proposalId, thirdPartyId }: { proposalId: string, thirdPartyId: string }) {
    const { data, isLoading, isError } = useInterventionsQuery({
        customerId: String(thirdPartyId),
        type: InterventionType.VISITE_TECHNIQUE,
    });

    const items = data?.docs || [];
    const itemsCount = data?.totalDocs || 0;

    return (
        <IonCard>
            <IonCardHeader>
                <IonCardSubtitle>Interventions programmées ({itemsCount})</IonCardSubtitle>
            </IonCardHeader>

            {
                isLoading ? (
                    <IonCardContent>
                        <LoadingDots />
                    </IonCardContent>
                ) : (
                    isError || !data || items.length === 0 ? (
                        <IonCardContent>
                            Aucune intervention programmée pour cette affaire
                        </IonCardContent>
                    ) : (
                        <IonList>
                            {
                                items.map(
                                    item => {
                                        const description = interventionUI.typeTranslation[item.type as InterventionType];

                                        if (item.type === InterventionType.POSE) {
                                            const startDate = DateTime.fromISO(item.typeData.theoricalStartDate, { zone: "Europe/Paris" }).setLocale('fr');
                                            const endDate = DateTime.fromISO(item.typeData.theoricalEndDate, { zone: "Europe/Paris" }).setLocale('fr');

                                            return (
                                                <IonItem
                                                    key={item.id}
                                                    button
                                                    detail={true}
                                                    detailIcon={upRightFromSquareIcon}
                                                    lines={"none"}
                                                    routerLink={`/page/Interventions/${item.id}`}
                                                    routerDirection={'root'}
                                                >
                                                    <IonLabel>
                                                        <h3>{description}</h3>
                                                        <p>Début: {startDate.toFormat('dd/MM/yyyy HH:mm')}</p>
                                                        <p>Fin: {endDate.toFormat('dd/MM/yyyy HH:mm')}</p>
                                                    </IonLabel>
                                                </IonItem>
                                            )
                                        }

                                        const intervention = item as VisiteTechnique;

                                        const date = intervention.date
                                            ? DateTime.fromISO(intervention!.date, { zone: "Europe/Paris" }).setLocale('fr').toFormat('dd/MM/yyyy HH:mm')
                                            : 'Non planifiée'
                                        ;

                                        const duration = Duration.fromObject({ seconds:  Number(intervention.typeData.duration || 0) }).toFormat('hh:mm');

                                        return (
                                            <IonItem
                                                key={item.id}
                                                button
                                                detail={true}
                                                detailIcon={upRightFromSquareIcon}
                                                lines={"none"}
                                                routerLink={`/page/Interventions/${item.id}`}
                                                routerDirection={'root'}
                                            >
                                                <IonLabel>
                                                    <h3>{description}</h3>
                                                    <p>Date: {date}</p>
                                                    <p>Durée: {duration}</p>
                                                </IonLabel>
                                            </IonItem>
                                        )
                                    }
                                )
                            }
                        </IonList>
                    )
                )
            }

            <ScheduleInterventionButton
                proposalId={proposalId}
                thirdPartyId={thirdPartyId}
            />
        </IonCard>
    )
}

function ScheduleInterventionButton({ proposalId, thirdPartyId }: { proposalId: string, thirdPartyId: string }) {
    const { isLoading: proposalLoading, data: proposal } = useProposalDetail(proposalId);
    const { isLoading: thirdPartyLoading, data: thirdParty } = useThirdPartyDetail(thirdPartyId);

    const projectId = thirdParty?.array_options?.options_related_project || '0';

    const coords = [
        Number(thirdParty?.array_options?.options_lat || '0'),
        Number(thirdParty?.array_options?.options_lng || '0'),
    ];

    const baseProps = {
        proposal: {
            id: +proposalId,
            origin: proposal?.array_options?.options_origine_affaire,
            originId: proposal?.array_options?.options_foire_origine,
        },
        thirdParty: {
            id: +thirdPartyId,
            name: thirdParty?.name || '',
        },
        place: {
            address: thirdParty?.address || '',
            town: thirdParty?.town || '',
            zipCode: thirdParty?.zip || '',
            coords
        },
        projectId: +projectId,
    }

    return (
        <>
            <IonButton
                // id="schedule-intervention"
                fill="clear"
                expand={"block"}
                routerLink={`/page/ScheduleIntervention/${proposalId}`}
                routerDirection={'forward'}
            >
                <IonIcon slot="start" ios={addOutline} md={addSharp}></IonIcon>
                Programmer VT
            </IonButton>

            {/*<IonPopover trigger="schedule-intervention" dismissOnSelect={false}>*/}
            {/*    <IonContent>*/}
            {/*        <IonList>*/}
            {/*            <IonItem button={true} lines={"none"} id="schedule-intervention-vt">*/}
            {/*                Programmer une VT*/}
            {/*            </IonItem>*/}

            {/*            <InterventionFormModal*/}
            {/*                {...baseProps}*/}
            {/*                trigger={"schedule-intervention-vt"}*/}
            {/*                defaultInterventionType={InterventionType.VISITE_TECHNIQUE}*/}
            {/*            />*/}
            {/*        </IonList>*/}
            {/*    </IonContent>*/}
            {/*</IonPopover>*/}
        </>
    )
}

function DateItem({ value }: { value: number }) {
    const d = DateTime.fromSeconds(value, { zone: "Europe/Paris" }).setLocale('fr');
    return <>{d.toLocaleString(DateTime.DATE_SHORT)}</>
}

function CurrencyItem({ value }: { value: number | string }) {
    const c = currency(value);
    return <IonText color={'primary'}>
        <p><b>{c.value} €</b></p>
    </IonText>;
}

function AddProposalLineButton({ id }: { id: string }) {
    const { refetch } = useProposalDetail(id);

    const [present, dismiss] = useIonModal(AddNewProposalLineModal, {
        proposalId: id,
        onDismiss: (data: ProposalLineFormModel, role: string) => {
            (role === 'confirm') && refetch();
            dismiss(data, role);
        },
    });

    return (
        <IonButton fill={"clear"} expand="block" onClick={() => present()}>
            <IonIcon slot="start" md={addSharp} ios={addOutline}></IonIcon>
            Ajouter une ligne
        </IonButton>
    )
}

const penToSquare = '/assets/icon/pen-to-square.svg';

function EditLineButton({ proposalId, line }: { proposalId: string, line: ProposalLine }) {
    const { refetch } = useProposalDetail(proposalId);

    const [present, dismiss] = useIonModal(EditProposalLineModal, {
        proposalId,
        line,
        onDismiss: (data: string, role: string) => {
            (role === 'confirm') && refetch();
            dismiss(data, role);
        },
    });

    return (
        <IonButton fill={"clear"} color={"medium"} onClick={() => present()}>
            <IonIcon slot="icon-only" size="small" icon={penToSquare}></IonIcon>
        </IonButton>
    )
}

function DeleteLineButton({ proposalId, lineId }: { proposalId: string, lineId: string }) {
    const [presentAlert] = useIonAlert();

    const { refetch } = useProposalDetail(proposalId);
    const { mutateAsync, isLoading } = useMutation(async () => {
        // Passage à l'état de brouillon avant
        await draftProposal(proposalId);

        // On supprime
        await deleteProposalLine(proposalId, lineId);

        // On revalide
        await validateProposal(proposalId);
    });

    return (
        <>
            <IonButton fill={"clear"} color={"danger"}
                       onClick={() =>
                           presentAlert({
                               header: 'Suppression ligne',
                               message: 'Êtes-vous sûr de vouloir supprimer cette ligne ?',
                               buttons: [
                                   {
                                       text: 'Retour',
                                       role: 'cancel',
                                   },
                                   {
                                       text: 'OK',
                                       role: 'confirm',
                                       handler: async () => {
                                           await mutateAsync();

                                           refetch();
                                       },
                                   },
                               ],
                           })
                       }
            >
                <IonIcon slot="icon-only" size="small" md={trashSharp} ios={trashOutline}></IonIcon>
            </IonButton>
        </>
    );
}
