import {useQuery} from "@tanstack/react-query";
import {getScheduledInterventionsInPeriod} from "../../calls/Interventions/getScheduledInterventionsInPeriod";
import {keyBy} from "lodash";

interface Payload {
    technicianId: string;
    startDate: string;
    endDate: string;
}

export function useScheduleInterventionsByPeriod({ technicianId, startDate, endDate}: Payload) {
    const queryFn = () => getScheduledInterventionsInPeriod({
        technicianId,
        startDate,
        endDate,
    });

    return useQuery(["scheduleInterventionsByPeriod", technicianId, startDate, endDate], queryFn, {
        enabled: !!technicianId,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        select(data) {
            return {
                interventionByDate: keyBy(data, 'date'),
                allInterventions: (data.map(d => d.docs)).flat()
            }
        }
    });
}
