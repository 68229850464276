import {http} from "../../http";
import {Endpoint} from "../endpoint.enum";

type CreateThirdPartyCompleteDto = {
    name: string;
    name_bis?: string;
    firstname?: string;
    email?: string;
    client?: string;
    code_client?: number;
    commercial_id?: number;
    typent_id?: number;
    civility_id?: string | number;
    civility_code?: string;
    address?: string,
    zip?: string,
    town?: string,
    countryId?: number,
    phone?: string,
    entity: number,
    array_options: {
        civilite?: string
        personal_mobile_phone?: string,
        business_mobile_phone?: string,
        lat?: number,
        lng?: number;
        technician_signature?: string,
        customer_signature?: string,
    }
}

export type CreateThirdPartyDto = Omit<CreateThirdPartyCompleteDto, 'code_client' | 'client' | 'countryId'>;

type CreateThirdPartyReturn = {
    thirdpartyId: number,
    projectId: number,
    bucketId: number; // où ranger les documents
    defaultContactId: number; // contact par défaut créé
}

export async function createThirdParty(dto: CreateThirdPartyDto): Promise<CreateThirdPartyReturn> {
    const completeDto: CreateThirdPartyCompleteDto = {
        ...dto,
        client: '2',
        countryId: 1, // 1=France
    }

    const { data } = await http.post(Endpoint.createThirdParty, completeDto);

    return data;
}

