import {Product} from "../../../models/product.model";
import {computeProductPricingRange, useProductPricingRange} from "./useProductPricingRange";
import {useIonAlert} from "@ionic/react";
import {useEffect} from "react";
import {useFormContext} from "react-hook-form";
import currency from "currency.js";

type UseProductPricingAlertProps = {
    product: Product,
    tvaTx: string;
    totalTTC: string | number;
}

export function useProductPricingAlert({ product, tvaTx, totalTTC }: UseProductPricingAlertProps) {
    const { array_options: { options_prix_pro, options_prix_hors_cible }, price } = product;

    const appliedPricing = useProductPricingRange({
        prixPublic: price,
        prixPro: options_prix_pro,
        prixHorsCible: options_prix_hors_cible,
        tvaTx,
        totalTTC,
    });

    const [presentAlert] = useIonAlert();

    useEffect(() => {
        if (!appliedPricing.alertMessage) {
            return;
        }

        presentAlert({
            header: 'Attention',
            subHeader: 'Changement tarification',
            message: appliedPricing.alertMessage,
            buttons: ['OK'],
        })
    }, [appliedPricing.alertMessage]);
}

export function useProductPricingAlertFunc({ keyToReset }: { keyToReset?: string } = {}) {
    const { setValue } = useFormContext();
    const [presentAlert] = useIonAlert();

    return ({ product, tvaTx, totalTTC, qty = 1 }: UseProductPricingAlertProps & { qty?: number }) => {
        const { array_options: { options_prix_pro, options_prix_hors_cible }, price } = product;

        const appliedPricing = computeProductPricingRange({
            prixPublic: price,
            prixPro: options_prix_pro,
            prixHorsCible: options_prix_hors_cible,
            tvaTx,
            totalTTC,
            qty,
        });

        if (!appliedPricing.alertMessage) {
            return;
        }

        presentAlert({
            header: 'Attention',
            subHeader: 'Changement tarification',
            message: appliedPricing.alertMessage,
            buttons: [
                {
                    text: 'Annuler',
                    role: 'cancel',
                    handler: () => {
                        if (!keyToReset) {
                            return;
                        }

                        // on remet la valeur par défaut si annulation
                        const ht = currency(price).multiply(qty).value;
                        setValue(keyToReset, ht);
                    },
                },
                {
                    text: 'OK',
                    role: 'confirm',
                },
            ],
        })
    }
}