import {IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import {Redirect, Route, useRouteMatch} from 'react-router-dom';
import Menu from './components/Menu';
import DashboardPage from './pages/DashboardPage';
import Contracts from "./pages/Contracts";
import NewContract from "./pages/NewContract";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import LoginPage from "./pages/Login";
import ProtectedRoute from "./components/Auth/ProtectedRoute";
import ContractDetail from './pages/ContractDetail';
import ScheduleInterventionPage from "./pages/ScheduleInterventionPage";
import EditContract from "./pages/EditContract";
import Interventions from "./pages/Interventions";
import InterventionDetail from "./pages/InterventionDetail";

setupIonicReact();

const App: React.FC = () => {
    return (
        <IonApp>
            <IonReactRouter>
                <IonRouterOutlet>
                    <Route path="/" exact={true}>
                        <Redirect to="/page" />
                    </Route>

                    <Route path="/Login" exact={true}>
                        <LoginPage />
                    </Route>

                    <ProtectedRoute path="/page">
                        <PageNestedRoutes />
                    </ProtectedRoute>
                </IonRouterOutlet>
            </IonReactRouter>
        </IonApp>
    );
};

export default App;

function PageNestedRoutes() {
    const match = useRouteMatch();

    const url = (path: string) => `${match.path}/${path}`;

    return (
        // Min width 1400 pour cacher sur la tablette en paysage
        <IonSplitPane when="(min-width: 1400px)"  contentId="main">
            <Menu />

            <IonRouterOutlet id="main">
                <ProtectedRoute path={match.path} exact={true}>
                    <Redirect to={url("Dashboard")} />
                </ProtectedRoute>

                <ProtectedRoute path={`${url("Dashboard")}`}  exact={true}>
                    <DashboardPage />
                </ProtectedRoute>

                <ProtectedRoute path={`${url("Contracts")}`} exact={true}>
                    <Contracts />
                </ProtectedRoute>

                <ProtectedRoute exact path={url("Interventions")}>
                    <Interventions />
                </ProtectedRoute>

                <ProtectedRoute exact path={url("Interventions/:id")}>
                    <InterventionDetail />
                </ProtectedRoute>

                <ProtectedRoute path={`${url("Contracts/:id")}`} exact={true}>
                    <ContractDetail />
                </ProtectedRoute>

                <ProtectedRoute path={`${url("NewContract")}`} exact={true}>
                    <NewContract />
                </ProtectedRoute>

                <ProtectedRoute path={`${url("EditContract/:id")}`} exact={true}>
                    <EditContract />
                </ProtectedRoute>

                <ProtectedRoute path={`${url("ScheduleIntervention/:id")}`} exact={true}>
                    <ScheduleInterventionPage />
                </ProtectedRoute>
            </IonRouterOutlet>
        </IonSplitPane>
    )
}
