import {ThirdParty} from "./third-party.model";

export enum InterventionType {
    VISITE_TECHNIQUE = 'VISITE_TECHNIQUE',
    POSE = 'POSE',
    SAV = 'SAV',
    ENTRETIEN = 'ENTRETIEN',
    RDVCOM = 'RDVCOM',
}

export enum AllInterventionStatus {
    TO_SCHEDULE = 'TO_SCHEDULE',
    SCHEDULED = 'SCHEDULED',
    CANCELLED = 'CANCELLED',
    PARTIALLY_COMPLETED = 'PARTIALLY_COMPLETED',
    COMPLETED = 'COMPLETED',
    ON_THE_WAY = 'ON_THE_WAY',
    ARRIVED = 'ARRIVED',
    DEPARTURE = 'DEPARTURE',
}


export class Intervention<T extends InterventionType = any, S = any> {
    public id: string;
    public reference: string;
    public type: T;
    public proposal: InterventionProposal;
    public customer: InterventionCustomer;
    public place: InterventionPlace;
    public entity: string;
    public status: AllInterventionStatus;
    public events: Events[];
    public date?: string;
    public reportSaved?: boolean;
    public technician?: InterventionTechnician;
    public notes?: string;
    public createdAt?: string;
    public updatedAt?: string;
    public typeData: S;
}

export class InterventionProposal {
    public id?: string;
    public origin?: string;
    public originId?: string;
}

export class InterventionCustomer {
    public id: string;
    public name: string;
}

export class InterventionPlace {
    public address?: string;
    public zipCode?: string;
    public town?: string;
    public coords?: number[];
}

export class InterventionTechnician {
    public id?: string;
    public name?: string;
}

export interface ParcelleCadastrale {
    commune: string;
    section: string;
    parcelle: string;
    prefixe: string;
    contenance: number;
}

export class VisiteTechniqueTypeData {
    public duration?: number;
    public desiredInterventionDate?: string;
    public particularConditions?: string;
    public observations?: string;
    public cadastre?: ParcelleCadastrale;
    public cancellationReason?: string;
}

export class VisiteTechnique extends Intervention<InterventionType.VISITE_TECHNIQUE, VisiteTechniqueTypeData> {}

export type RawVisiteTechnique = Omit<VisiteTechnique, 'place'> & {
    place: Omit<InterventionPlace, 'coords'> & {
        coords: {
            type: 'Point',
            coordinates: [number, number],
        };
    }
}

export class PoseTypeData {
    theoricalStartDate: string;
    theoricalEndDate: string;
}

export class Pose extends Intervention<InterventionType.POSE, PoseTypeData> {}

export class Events {
    public type?: string;
    public date?: string;
}

export class RdvComTypeData {

}

export class RdvCom extends Intervention<InterventionType.RDVCOM, RdvComTypeData> {}

export class OldIntervention {
    public socid?: string;
    public datec?: number;
    public datev?: number;
    public dateo: number;
    public datee?: number;
    public datet?: string;
    public datem?: number;
    public duration?: string;
    public statut?: string;
    public description?: string;
    public fk_contrat?: string;
    public fk_project?: string;
    public ref_client?: any;
    public lines: InterventionLine[];
    public id: string;
    public entity?: string;
    public import_key?: any;
    public array_options?: InterventionExtraFields;
    public array_languages?: any;
    public linkedObjectsIds?: any;
    public canvas?: any;
    public contact_id?: any;
    public thirdparty: ThirdParty;
    public user?: any;
    public origin?: any;
    public origin_id?: any;
    public ref?: string;
    public ref_ext?: any;
    public status?: any;
    public country_id?: any;
    public country_code?: any;
    public state_id?: any;
    public region_id?: any;
    public barcode_type?: any;
    public barcode_type_coder?: any;
    public mode_reglement_id?: any;
    public cond_reglement_id?: any;
    public demand_reason_id?: any;
    public transport_mode_id?: any;
    public shipping_method_id?: any;
    public model_pdf?: string;
    public last_main_doc?: any;
    public fk_bank?: any;
    public fk_account?: any;
    public note_public?: any;
    public note_private?: any;
    public total_ht?: any;
    public total_tva?: any;
    public total_localtax1?: any;
    public total_localtax2?: any;
    public total_ttc?: any;
    public name?: any;
    public lastname?: any;
    public firstname?: any;
    public civility_id?: any;
    public date_creation?: any;
    public date_validation?: any;
    public date_modification?: any;
    public specimen?: number;
    public user_creation?: string;
    public brouillon?: number;
    public thirdparty_label: string;
    public thirdparty_address: string;
    public thirdparty_town: string;
    public thirdparty_zip: string;
}

export class InterventionExtraFields {
    public options_intervention_type?: string;
    public options_assignee?: string;
    public options_habitat_electricite_type_branchement?: string;
    public options_habitat_electricite_puissance_kva?: string;
    public options_habitat_murs_epaisseur?: string;
    public options_habitat_murs_type?: string;
    public options_habitat_informations_complementaires_superficie_a_chauffer?: string;
    public options_habitat_informations_complementaires_hauteur?: string;
    public options_habitat_isolation?: string;
    public options_habitat_date_de_derniere_renovation_murs?: string;
    public options_habitat_date_de_derniere_renovation_combles?: string;
    public options_habitat_date_de_derniere_renovation_fenetre?: string;
    public options_habitat_observations?: string;
    public options_pac_air_eau_surface_volume_total?: any;
    public options_pac_air_eau_surface_hauteur_moyenne?: any;
    public options_pac_air_air_split1_taille?: string;
    public options_pac_air_air_split1_emplacement?: any;
    public options_pac_air_air_split1_longueur_frigo?: string;
    public options_pac_air_air_split1_surface?: string;
    public options_pac_air_air_split1_volume?: string;
    public options_pac_air_air_split2_taille?: string;
    public options_pac_air_air_split2_emplacement?: any;
    public options_pac_air_air_split2_longueur_frigo?: string;
    public options_pac_air_air_split2_surface?: string;
    public options_pac_air_air_split2_volume?: string;
    public options_pac_air_air_split3_taille?: any;
    public options_pac_air_air_split3_emplacement?: any;
    public options_pac_air_air_split3_longueur_frigo?: any;
    public options_pac_air_air_split3_surface?: any;
    public options_pac_air_air_split3_volume?: any;
    public options_pac_air_air_split4_taille?: any;
    public options_pac_air_air_split4_emplacement?: any;
    public options_pac_air_air_split4_longueur_frigo?: any;
    public options_pac_air_air_split4_surface?: any;
    public options_pac_air_air_split4_volume?: any;
    public options_pac_air_air_split5_taille?: any;
    public options_pac_air_air_split5_longueur_frigo?: any;
    public options_pac_air_air_split5_emplacement?: any;
    public options_pac_air_air_split5_surface?: any;
    public options_pac_air_air_split5_volume?: any;
    public options_photovolataique_produit_installe?: string;
    public options_photovolataique_hauteur_bas_de_toit?: string;
    public options_photovolataique_hauteur_haut_de_toit?: string;
    public options_photovolataique_longeur_disponible_pour_pv?: any;
    public options_photovolataique_rampant?: any;
    public options_photovolataique_pente?: string;
    public options_photovolataique_type_de_tuile?: string;
    public options_photovolataique_installation?: string;
    public options_photovolataique_dependance_alimentation?: string;
    public options_photovolataique_dependance_section_cable?: string;
    public options_photovolataique_charpente?: string;
    public options_observations_techniques?: string;
    public options_observations_financieres?: string;
    public options_emargement_lieu?: string;
    public options_emargement_date?: string;
    public options_emargement_signature_client?: string;
    public options_emargement_signature_technicien?: string;
}

export class InterventionLine {
    public fk_fichinter?: string;
    public desc?: string;
    public date?: number;
    public datei?: number;
    public duration?: string;
    public rang?: string;
    public id?: string;
    public rowid?: any;
    public fk_unit?: any;
    public date_debut_prevue?: any;
    public date_debut_reel?: any;
    public date_fin_prevue?: any;
    public date_fin_reel?: any;
    public entity?: any;
    public import_key?: any;
    public array_languages?: any;
    public linkedObjectsIds?: any;
    public canvas?: any;
    public origin?: any;
    public origin_id?: any;
    public ref?: any;
    public ref_ext?: any;
    public statut?: any;
    public status?: any;
    public state_id?: any;
    public region_id?: any;
    public barcode_type?: any;
    public barcode_type_coder?: any;
    public demand_reason_id?: any;
    public transport_mode_id?: any;
    public last_main_doc?: any;
    public fk_bank?: any;
    public fk_account?: any;
    public total_ht?: any;
    public total_tva?: any;
    public total_localtax1?: any;
    public total_localtax2?: any;
    public total_ttc?: any;
    public lines?: any;
    public date_creation?: any;
    public date_validation?: any;
    public date_modification?: any;
    public specimen?: number;
    public qty?: number;
    public product_type?: number;
}

interface InterventionUI {
    typeTranslation: Record<InterventionType, string>;
    typeColorMapping: Record<InterventionType, string>;
    statusTranslation: Record<AllInterventionStatus, string>;
    statusColorMapping: Record<AllInterventionStatus, string>;
}

export const interventionUI: InterventionUI = {
    typeTranslation: {
        [InterventionType.VISITE_TECHNIQUE]: 'Visite technique',
        [InterventionType.POSE]: 'Pose',
        [InterventionType.SAV]: 'SAV',
        [InterventionType.ENTRETIEN]: 'Entretien',
        [InterventionType.RDVCOM]: 'Rdv Comm'
    },
    typeColorMapping: {
        [InterventionType.VISITE_TECHNIQUE]: 'primary',
        [InterventionType.POSE]: 'success',
        [InterventionType.SAV]: 'tertiary',
        [InterventionType.ENTRETIEN]: 'warning',
        [InterventionType.RDVCOM]: 'warning'
    },
    statusTranslation: {
        [AllInterventionStatus.TO_SCHEDULE]: 'À Planifier',
        [AllInterventionStatus.SCHEDULED]: 'Programmée',
        [AllInterventionStatus.CANCELLED]: 'Annulée',
        [AllInterventionStatus.PARTIALLY_COMPLETED]: 'Partiellement complétée',
        [AllInterventionStatus.COMPLETED]: 'Terminée',
        [AllInterventionStatus.ON_THE_WAY]: 'En route',
        [AllInterventionStatus.ARRIVED]: 'Arrivé',
        [AllInterventionStatus.DEPARTURE]: 'Départ',
    },
    statusColorMapping: {
        [AllInterventionStatus.TO_SCHEDULE]: 'medium',
        [AllInterventionStatus.SCHEDULED]: 'primary',
        [AllInterventionStatus.CANCELLED]: 'danger',
        [AllInterventionStatus.PARTIALLY_COMPLETED]: 'warning',
        [AllInterventionStatus.COMPLETED]: 'success',
        [AllInterventionStatus.ON_THE_WAY]: 'primary',
        [AllInterventionStatus.ARRIVED]: 'success',
        [AllInterventionStatus.DEPARTURE]: 'primary',
    }
}