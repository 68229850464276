export class User {
  public id: string;
  public statut?: string;
  public employee?: string;
  public civility_code?: string;
  public gender?: string;
  public birth?: string;
  public email?: string;
  public personal_email?: string;
  public job?: string;
  public signature?: string;
  public address?: string;
  public zip?: string;
  public town?: string;
  public state_id?: any;
  public office_phone?: string;
  public office_fax?: string;
  public user_mobile?: string;
  public personal_mobile?: string;
  public admin?: string;
  public login?: string;
  public entity?: string;
  public datec?: string;
  public datem?: number;
  public socid?: any;
  public contact_id?: any;
  public fk_member?: any;
  public fk_user?: any;
  public fk_user_expense_validator?: any;
  public fk_user_holiday_validator?: any;
  public clicktodial_url?: any;
  public clicktodial_login?: any;
  public clicktodial_poste?: any;
  public datelastlogin?: number;
  public datepreviouslogin?: number;
  public datestartvalidity?: string;
  public dateendvalidity?: string;
  public photo?: any;
  public lang?: any;
  public rights?: any;
  public user_group_list?: any;
  public parentof?: any;
  public accountancy_code?: string;
  public thm?: any;
  public tjm?: any;
  public salary?: any;
  public salaryextra?: any;
  public weeklyhours?: any;
  public color?: string;
  public dateemployment?: string;
  public dateemploymentend?: string;
  public default_c_exp_tax_cat?: any;
  public default_range?: any;
  public fk_warehouse?: any;
  public import_key?: any;
  public array_languages?: any;
  public linkedObjectsIds?: any;
  public canvas?: any;
  public fk_project?: any;
  public user?: any;
  public origin?: any;
  public origin_id?: any;
  public ref?: string;
  public ref_ext?: any;
  public status?: any;
  public country_id?: any;
  public country_code?: string;
  public region_id?: any;
  public barcode_type?: any;
  public barcode_type_coder?: any;
  public mode_reglement_id?: any;
  public cond_reglement_id?: any;
  public demand_reason_id?: any;
  public transport_mode_id?: any;
  public last_main_doc?: any;
  public fk_bank?: any;
  public fk_account?: any;
  public note_public?: string;
  public note_private?: string;
  public name?: any;
  public lastname?: string;
  public firstname?: string;
  public civility_id?: any;
  public date_creation?: any;
  public date_validation?: any;
  public date_modification?: any;
  public specimen?: number;
  public liste_limit?: number;
}

/**
 * Fonction pour retourner un équivament en string d'un User
 * @param firstname
 * @param lastname
 * @param login
 */
export function userToString({ firstname, lastname, login }: User) {
    // get des différentes parties qui identifie un utilisateur - nom et prénom
    const parts = [firstname, lastname].filter(e => !!e);

    // On regarde ce qui reste du filter et s'il y a des élément on join pour obetnir un string, sinon on prend le login en dernier recours
    return parts.length > 0 ? parts.join(' ') : login;
}

/**
 * Fonction pour retourner les initials d'un User
 * @param firstname
 * @param lastname
 * @param login
 */
export function userToInitials({ firstname, lastname, login }: User) {
    // get des différentes parties qui identifie un utilisateur - nom et prénom
    const parts = [firstname, lastname].filter(e => !!e);

    // On regarde ce qui reste du filter et s'il y a des élément on join pour obetnir un string, sinon on prend le login en dernier recours
    if (parts.length > 0) {
        const name = parts.join(' ');
        return `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;
    }

    return (login ? login[0] : '').toUpperCase();
}