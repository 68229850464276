import {Endpoint} from "../../endpoint.enum";
import {interventionHttp} from "../../../http";
import {RdvCom} from "../../../models/intervention.model";

export async function agentIsLeavingInterventionPlace(id: string) {
    const url = Endpoint.agentIsLeavingInterventionPlace.replace(':id', id);

    const {data} = await interventionHttp.post<RdvCom>(url, {});

    return data;
}
