import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem, IonItemOption, IonItemOptions, IonItemSliding,
    IonLabel,
    IonList,
    IonListHeader,
    IonNote,
    IonPage,
    IonRow, IonSelect, IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    useIonModal
} from "@ionic/react";
import {
    addOutline,
    addSharp,
    checkmarkOutline,
    checkmarkSharp,
    logoEuro, removeOutline, removeSharp,
    trashOutline,
    trashSharp
} from "ionicons/icons";
import SearchPacAirAir from "./SearchPacAirAir";
import {Controller, FormProvider, useFieldArray, useForm, useFormContext, useWatch} from "react-hook-form";
import {PacAirAirWhiteIcon} from "../../Icons";
import styled from "@emotion/styled";
import {FormattedPlural} from "react-intl";
import {Product} from "../../../models/product.model";
import currency from "currency.js";
import React, {useEffect, useState} from "react";
import {reduce} from 'lodash';
import {TVA_20, VatRate} from "../../../valueObjects/VatRate";

export default function PacAirAirItem() {
    const title = 'Pompe à chaleur AIR / AIR';

    // fields est filled normalement quand on vient de l'édition
    const { fields } = useFieldArray({
        name: "pacAirAir.products",
    });

    // On se base sur fields pour afficher par défaut ou pas les produits
    const selectedByDefault = fields.length > 0;

    const [productSelected, setProductSelected] = useState(selectedByDefault);

    return (
        <>
            {
                (!productSelected) && (
                    <AddButton
                        title={title}
                        onProductSelected={() => setProductSelected(true)}
                    />
                )
            }

            {
                (productSelected) && (
                    <Card
                        title={title}
                        onProductDeleted={() => setProductSelected(false)}
                    />
                )
            }
        </>
    )
}

type AddButtonProps = {
    title: string,
    onProductSelected?(product: any): void,
}

function AddButton({ title, onProductSelected }: AddButtonProps) {
    const { append } = useFieldArray({
        name: "pacAirAir.products",
    });

    const [present, dismiss] = useIonModal(Modal, {
        onDismiss: (products: Product[], role: string) => {
            if (role === 'confirm') {
                const data = products.map(
                    product => ({ product, qty: 1, tvaTx: TVA_20 })
                );

                append(data);
                onProductSelected && onProductSelected(data);
            }

            dismiss(products, role);
        },
    });

    return (
        <IonRow>
            <IonCol>
                <IonButton color={"pac-air-air"} fill={"solid"} expand={"block"} onClick={() => present()}>
                    <IonIcon md={addSharp} ios={addOutline} slot="start"/>
                    <IonText>
                        <span>{title}</span>
                    </IonText>
                </IonButton>
            </IonCol>
        </IonRow>
    )
}

type CardProps = {
    title: string,
    onProductDeleted(): void,
}

type PacAirAirProduct = {
    product: Product,
    totalTTC: number | string,
    qty: number,
    tvaTx: number | string,
    index: number,
}

function Card({ title, onProductDeleted }: CardProps) {
    const { fields, append, remove } = useFieldArray({
        name: "pacAirAir.products",
    });

    const [present, dismiss] = useIonModal(Modal, {
        onDismiss: (products: Product[], role: string) => {
            if (role === 'confirm') {
                const data = products.map(
                    product => ({ product, qty: 1, tvaTx: TVA_20 })
                );

                append(data);
            }

            dismiss(products, role);
        },
    });

    const units = fields.map((u, index) => ({ ...u, index }))
    const outdoorUnits = units.filter(({ product }: any) => product.array_options?.options_is_indoor_unit == "0" || !product.array_options?.options_is_indoor_unit);
    const splits = units.filter(({ product }: any) => product.array_options?.options_is_indoor_unit == "1");

    return (
        <IonCard style={{ border: '2px solid var(--ion-color-pac-air-air)' }}>
            <IonCardHeader style={{ display: 'flex', background: 'var(--ion-color-pac-air-air)' }} className={"ion-align-items-center"}>
                <PacAirAirWhiteIcon />

                <div style={{ marginLeft: 5 }}>
                    <IonCardSubtitle style={{ color: 'white' }}>{title}</IonCardSubtitle>
                    <IonCardTitle style={{ color: 'white' }}>Unités Extérieures et unités intérieures</IonCardTitle>
                </div>

                <IonButton fill="outline" style={{ marginLeft: 'auto' }} color={'light'} onClick={() => present()}>
                    <IonIcon md={addSharp} ios={addOutline} slot="start"/>
                    Ajouter
                </IonButton>
            </IonCardHeader>

            <IonCardContent>
                <IonList>
                    <IonListHeader>
                        Unités extérieures
                    </IonListHeader>

                    {
                        outdoorUnits.length === 0 && (
                            <IonItem lines={"none"}>
                                <IonLabel>
                                    <p>Pas d'unité extérieure</p>
                                </IonLabel>
                            </IonItem>
                        )
                    }

                    {
                        outdoorUnits.map(
                            (unit: any, i) => {
                                return (
                                    <AirAirItem
                                        key={`outdoor-${i}-${unit.product.ref}`}
                                        unit={unit}
                                        onRemove={index => remove(index)}
                                    />
                                )
                            }
                        )
                    }

                    <IonListHeader>
                        Splits / Unités intérieures
                    </IonListHeader>

                    {
                        splits.length === 0 && (
                            <IonItem lines={"none"}>
                                <IonLabel>
                                    <p>Pas d'unité intérieure</p>
                                </IonLabel>
                            </IonItem>
                        )
                    }

                    {
                        splits.map(
                            (unit: any, i) => {
                                return (
                                    <AirAirItem
                                        key={`indoor-${i}-${unit.product.ref}`}
                                        unit={unit}
                                        onRemove={index => remove(index)}
                                    />
                                )
                            }
                        )
                    }
                </IonList>

                <TotalGrid onProductDeleted={onProductDeleted} />
            </IonCardContent>
        </IonCard>
    )
}

function AirAirItem({ unit, onRemove }: { unit: PacAirAirProduct, onRemove: (index: number) => void }) {
    const { setValue } = useFormContext();

    const { product, tvaTx: defaultTvaTx, index } = unit as PacAirAirProduct;

    const qtyName = `pacAirAir.products[${index}].qty`;
    const totalHTName = `pacAirAir.products[${index}].totalHT`;
    const totalTTCName = `pacAirAir.products[${index}].totalTTC`;
    const tvaTxName = `pacAirAir.products[${index}].tvaTx`;

    const qty = useWatch({ name: qtyName, defaultValue: 1 });
    const tvaTx = useWatch({ name: tvaTxName, defaultValue: defaultTvaTx });
    const totalHT = useWatch({ name: totalHTName, defaultValue: Number(product.price) * qty });
    const totalTTC = useWatch({ name: totalTTCName, defaultValue: (Number(product.price) *  qty) * (1 + (Number(tvaTx) / 100)) });

    const unitPrice = currency(product.price);
    const ht = currency(totalHT);
    const baseTva = Number(tvaTx);
    const coeffTva = baseTva / 100;
    const montantTva = ht.multiply(coeffTva);
    const ttc = ht.add(montantTva);

    useEffect(() => {
        const myQtyHt = currency(product.price as string).multiply(qty);
        const myCoeffTva = tvaTx / 100;
        const myTtc = myQtyHt.multiply(1 + myCoeffTva);
        setValue(totalHTName, myQtyHt.value);
        setValue(totalTTCName, myTtc.value);
    }, [qty]);

    useEffect(() => {
        const myHt = currency(totalHT);
        const myCoeffTva = tvaTx / 100;
        const myTtc = myHt.multiply(1 + myCoeffTva);
        setValue(totalTTCName, myTtc.value);
    }, [totalHT, tvaTx]);

    useEffect(() => {
        const myTtc = currency(totalTTC);
        const myHt = myTtc.divide(1 + coeffTva);
        setValue(totalHTName, myHt.value);
    }, [totalTTC]);

    return (
        <IonItemSliding>
            <IonItem lines={"none"}>
                <IonRow key={`${index}-${product.ref}`}>
                    <IonCol size={"4"}>
                        <IonItem>
                            <IonLabel>
                                <h2><b>{product.label}</b></h2>
                                <h3>{product.ref} - {`${product.array_options?.options_puissance} KW`}</h3>
                            </IonLabel>
                        </IonItem>
                    </IonCol>
                    <IonCol>
                        <IonItem>
                            <IonLabel position="floating">
                                <h2><b>P.U</b></h2>
                            </IonLabel>
                            <IonInput
                                value={unitPrice.toString()}
                                min={1}
                                readonly
                            />
                        </IonItem>
                    </IonCol>
                    <IonCol>
                        <Controller
                            name={`pacAirAir.products[${index}].qty`}
                            render={
                                ({field}) => {
                                    return (
                                        <>
                                            <IonItem>
                                                <IonLabel position="floating">
                                                    <h2><b>Qté</b></h2>
                                                </IonLabel>
                                                <IonInput
                                                    value={field.value}
                                                    onIonChange={field.onChange}
                                                    type={'number'}
                                                    min={1}
                                                />
                                            </IonItem>
                                            <div style={{ display: 'flex' }}>
                                                <IonButton size="small" fill="clear" onClick={() => field.onChange(field.value + 1)}>
                                                    <IonIcon slot={'icon-only'} md={addSharp} ios={addOutline} />
                                                </IonButton>
                                                <IonButton size="small" fill="clear" onClick={
                                                    () => field.onChange(
                                                        field.value > 1 ? (field.value - 1) : 1
                                                    )
                                                }>
                                                    <IonIcon slot={'icon-only'} md={removeSharp} ios={removeOutline} />
                                                </IonButton>
                                            </div>
                                        </>
                                    )
                                }
                            }
                            defaultValue={qty}
                        />
                    </IonCol>
                    <IonCol>
                        <Controller
                            name={`pacAirAir.products[${index}].tvaTx`}
                            render={
                                ({field}) => {
                                    return (
                                        <IonItem>
                                            <IonLabel position="floating">
                                                <h2><b>Tva</b></h2>
                                            </IonLabel>
                                            <IonSelect placeholder="TVA" value={field.value} onIonChange={field.onChange} cancelText={'Fermer'}>
                                                {
                                                    VatRate.PacAirAirRates.map(
                                                        r => (
                                                            <IonSelectOption key={r.label} value={r.value}>{r.label}</IonSelectOption>
                                                        )
                                                    )
                                                }
                                            </IonSelect>
                                        </IonItem>
                                    )
                                }
                            }
                            defaultValue={baseTva}
                        />
                    </IonCol>
                    <IonCol>
                        <Controller
                            name={`pacAirAir.products[${index}].totalHT`}
                            render={
                                ({field}) => {
                                    return (
                                        <IonItem>
                                            <IonLabel position="floating">
                                                <h2><b>HT</b></h2>
                                            </IonLabel>
                                            <IonInput
                                                value={field.value}
                                                onIonChange={field.onChange}
                                                type={'number'}
                                                min={1}
                                            />
                                        </IonItem>
                                    )
                                }
                            }
                            defaultValue={ht.toString()}
                        />
                    </IonCol>

                    <IonCol>
                        <IonItem lines={"none"}>
                            <IonLabel position="floating">
                                <h2><b>Montant TVA</b></h2>
                            </IonLabel>
                            <IonInput
                                placeholder="Montant TVA"
                                readonly
                                value={montantTva.toString()}
                            />
                        </IonItem>
                    </IonCol>

                    <IonCol>
                        <IonItem>
                            <IonLabel position={'floating'}><h2><b>TTC</b></h2></IonLabel>
                            <Controller
                                name={`pacAirAir.products[${index}].totalTTC`}
                                render={
                                    ({ field }) => {
                                        return <IonInput type={"number"} placeholder="TOTAL TTC" value={field.value} onIonChange={field.onChange} />
                                    }
                                }
                                defaultValue={ttc.toString()}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
            </IonItem>
            <IonItemOptions>
                <IonItemOption color="danger" onClick={() => onRemove(index)}>
                    <IonIcon md={trashSharp} ios={trashOutline} slot={"icon-only"} />
                </IonItemOption>
            </IonItemOptions>
        </IonItemSliding>
    )
}

function TotalGrid({ onProductDeleted }: { onProductDeleted(): void, }) {
    const { setValue } = useFormContext();

    // watch to enable re-render when ticket number is changed
    const products = useWatch({ name: 'pacAirAir.products', defaultValue: [] });

    const [total, setTotal] = useState({
        ht: currency(0),
        montantTva: currency(0),
        ttc: currency(0)
    });

    useEffect(() => {
        const ht = reduce(
            products.map(({ product, qty, totalHT }: any) => currency(totalHT)),
            (sum, n) => sum.add(n),
        ) || currency(0);

        const montantTva = reduce(
            (
                products.map(
                    ({ product, qty, tvaTx, totalHT }: any) => {
                        const itemHt = currency(totalHT);
                        return itemHt.multiply(Number(tvaTx) / 100);
                    }
                )
            ),
            (sum, n) => sum.add(n),
        ) || currency(0);

        const ttc = ht.add(montantTva);

        setTotal({ ht, montantTva, ttc });
    }, [products]);

    const resetProduct = () => {
        setValue('pacAirAir.products', []);
        onProductDeleted();
    }

    return (
        <IonGrid>
            <TotalRow>
                <IonCol>
                    <IonItem lines={"none"}>
                        <IonNote slot={'start'}>HT</IonNote>
                        <IonInput placeholder="TOTAL HT" readonly value={total.ht.toString()} />
                        <IonIcon icon={logoEuro} />
                    </IonItem>
                </IonCol>

                <IonCol>
                    <IonItem lines={"none"}>
                        <IonNote slot={'start'}>
                            TVA
                        </IonNote>
                        <IonInput placeholder="TVA" readonly value={total.montantTva.toString()} />
                        <IonIcon icon={logoEuro} />
                    </IonItem>
                </IonCol>

                <IonCol>
                    <IonItem lines={"none"}>
                        <IonNote slot={'start'}>TTC</IonNote>
                        <IonInput placeholder="TOTAL TTC" readonly value={total.ttc.toString()} />
                        <IonIcon icon={logoEuro} />
                    </IonItem>
                </IonCol>
            </TotalRow>

            <IonRow className={'ion-margin-top'}>
                <IonCol>
                    <IonButton type={"button"} expand="block" color={"danger"} onClick={() => resetProduct()}>
                        <IonIcon slot={"start"} md={trashSharp} ios={trashOutline} />

                        <FormattedPlural value={products.length} one="Supprimer produit" other="Supprimer produits" />
                    </IonButton>
                </IonCol>
            </IonRow>
        </IonGrid>
    )
}

const TotalRow = styled(IonRow)`
    padding: 0;

    ion-col {
        padding: 0;
    }

    ion-col ion-item {
        font-weight: bold;
    }

    ion-col:first-of-type ion-item {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }

    ion-col:not(last-child) ion-item {
        --background: #d1e6f7;
    }

    ion-col:last-child {
        --background: var(--ion-color-pac-air-air);
    }

    ion-col:last-child ion-note {
        color: white;
    }

    ion-col:last-child ion-item {
        --background: var(--ion-color-pac-air-air);
    }

    ion-col:last-child ion-item {
        border-radius: 8px;
    }

    ion-col ion-input {
        background: white;
        border-radius: 8px;
        margin-top: 6px;
        margin-bottom: 6px;
        --padding-start: 8px!important;
        font-weight: bold;
        font-size: 1.2em;
    }

    ion-icon {
        color: white;
    }
`;

const Item = styled(IonItem)`
    &.item-interactive.ion-focused, &.item-interactive.item-has-focus {
        --highlight-background: var(--ion-color-pac-air-air);
    }

    &.item-has-focus .label-floating.sc-ion-label-md-h:not(.ion-color) {
        color: var(--ion-color-pac-air-air);
    }
`;

const Modal = ({ onDismiss, }: { onDismiss: (data?: any | null | undefined | number, role?: string) => void; }) => {
    const methods = useForm<{ selectedProducts: Product[] }>();

    const handleDismiss = () => {
        const { selectedProducts } = methods.getValues();

        // On garde les produits non undefined
        const filtered = selectedProducts.filter(p => !!p);

        // Récupération des kits
        const flatten = filtered.map(fi => {
            return [fi, ...fi.kits];
        }).flat();

        onDismiss(flatten, 'confirm');
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton color="medium" onClick={() => onDismiss(null, 'cancel')}>
                            Annuler
                        </IonButton>
                    </IonButtons>
                    <IonTitle>Recherche pompe à chaleur Air/Air</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <FormProvider {...methods}>
                    <SearchPacAirAir />
                </FormProvider>
            </IonContent>

            <IonButton onClick={handleDismiss} color={'pac-air-air'} expand={"block"}>
                <IonIcon md={checkmarkOutline} ios={checkmarkSharp} slot="start" />
                Valider le choix
            </IonButton>
        </IonPage>
    );
};