export function useInterventionUI({ type }: { type: string }) {
    return ({
        VISITE_TECHNIQUE: {
            label: 'Visite Technique',
            chipColor: 'primary',
        },
        POSE: {
            label: 'Pose',
            chipColor: 'success',
        },
        SAV: {
            label: 'SAV',
            chipColor: 'tertiary',
        },
        ENTRETIENT: {
            label: 'Entretien',
            chipColor: 'warning',
        },
        RDVCOM: {
            label: 'Rdv Com',
            chipColor: 'warning',
        }
    }[type] || { label: type, chipColor: 'default' })
}
