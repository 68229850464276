import {useMutation} from "@tanstack/react-query";
import {downloadDocument} from "../../calls/downloadDocument";
import {useActiveCompanyId} from "../Company/company.store";

export function useDownloadFromECM() {
    const companyId = useActiveCompanyId();

    return useMutation((file: string) => downloadDocument({
        modulepart: 'ecm',
        original_file: file,
        attachment: 1,
        entity: +companyId,
    }));
}