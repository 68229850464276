import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonDatetime,
    IonGrid,
    IonIcon,
    IonRow
} from "@ionic/react";
import {useController, useWatch} from "react-hook-form";
import {userToString} from "../../models/user.model";
import {addDays, format} from 'date-fns'
import {fr} from 'date-fns/locale'
import {useScheduleInterventionsByPeriod} from "./useScheduleInterventionsByPeriod";
import {addOutline, addSharp} from "ionicons/icons";
import InterventionFormModal from "./InterventionFormModal";
import {InterventionType, RawVisiteTechnique} from "../../models/intervention.model";
import {useProposalDetail} from "../ContractDetail/useProposalDetail";
import {useThirdPartyDetail} from "../ThirdParty/useThirdPartyDetail";
import {useParams} from "react-router";
import {DateTime} from "luxon";
import TechnicianMapCard from "./TechnicianMapCard";


export default function TechnicianAppointmentCard() {
    const today = new Date();

    const tech = useWatch({ name: 'technician', defaultValue: null });
    const selectedDate = useWatch({ name: 'selectedDate', defaultValue: today.toISOString() });

    const startDate = new Date(selectedDate);
    const endDate = addDays(startDate, 5);
    const dates = getDatesInRange(startDate, endDate);

    const { data, isLoading, refetch } = useScheduleInterventionsByPeriod({
        technicianId: tech?.id,
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
    });

    // Get id proposal depuis url
    const { id: proposalId } = useParams<{ id: string }>();

    if (!tech || isLoading) {
        return (<></>);
    }

    const { interventionByDate, allInterventions } = data!;

    return (
        <>
            <TechnicianMapCard interventions={allInterventions} />

            <IonCard>
                <IonCardHeader>
                    <IonCardTitle>{userToString(tech as any)}</IonCardTitle>
                    <IonCardSubtitle>Sélectionner une date de rendez-vous</IonCardSubtitle>
                </IonCardHeader>

                <IonCardContent>
                    <IonGrid>
                        <IonRow>
                            <IonCol size="3">
                                <DatePicker today={today} />
                            </IonCol>

                            <IonCol>
                                <IonGrid>
                                    <IonRow>
                                        {
                                            dates.map(
                                                d => {
                                                    const detail = (interventionByDate!)[d.id];

                                                    const interventions = detail?.docs || [];

                                                    return (
                                                        <IonCol key={`DateCol-${d.id}`} className={'ion-text-center'}>
                                                            <h4><b>{d.weekDay}</b></h4>
                                                            <p style={{ margin: 0 }}>
                                                                {d.dayLabel}
                                                            </p>

                                                            {
                                                                interventions.map(
                                                                    (intervention, index) => (
                                                                        <InterventionItem
                                                                            key={`intervention-${index}`}
                                                                            intervention={intervention}
                                                                        />
                                                                    )
                                                                )
                                                            }

                                                            <AssignInterventionButton
                                                                key={`AssignButton-${d.id}`}
                                                                proposalId={proposalId}
                                                                technician={tech}
                                                                dateId={d.id}
                                                                defaultDate={d.date}
                                                                onSuccess={() => refetch()}
                                                            />
                                                        </IonCol>
                                                    )
                                                }
                                            )
                                        }
                                    </IonRow>
                                </IonGrid>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonCardContent>
            </IonCard>
        </>
    )
}

function DatePicker({ today }: { today: Date }) {
    const {
        field: { value, onChange },
    } = useController({
        name: 'selectedDate',
        rules: { required: true },
    });

    return (
        <IonDatetime
            presentation="date"
            min={today.toISOString()}
            onIonChange={onChange}
        />
    )
}

function getDatesInRange(startDate: Date, endDate: Date) {
    const date = new Date(startDate.getTime());

    const dates = [];

    while (date <= endDate) {
        dates.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }

    return dates.map(
        d => {
            const id = format(d, 'yyyy-MM-dd');
            const weekDay = format(d, 'iii', { locale: fr }).toUpperCase();
            const dayLabel = `${format(d, 'dd')}/${format(d, 'MM')}`;
            const dayIso = format(d, 'i');

            return {
                id,
                weekDay,
                dayLabel,
                dayIso,
                date: d,
            }
        }
    );
}

interface AssignInterventionButtonProps {
    proposalId: string;
    dateId: string;
    defaultDate: Date;
    technician: {
        firstname: string;
        id: string;
        lastname: string;
        login: string;
    };
    onSuccess(): void
}

function AssignInterventionButton({ proposalId, dateId, defaultDate, technician, onSuccess }: AssignInterventionButtonProps) {
    const { isError, isFetched, isLoading, data: { proposal, customer: thirdParty } } = useProposalAndCustomer(proposalId);

    const projectId = thirdParty?.array_options?.options_related_project || '0';

    const coords = [
        Number(thirdParty?.array_options?.options_lat || '0'),
        Number(thirdParty?.array_options?.options_lng || '0'),
    ];

    const baseProps = {
        proposal: {
            id: +proposalId,
            origin: proposal?.array_options?.options_origine_affaire,
            originId: proposal?.array_options?.options_foire_origine,
        },
        thirdParty: {
            id: +(thirdParty?.id || '0'),
            name: thirdParty?.name || '',
        },
        place: {
            address: thirdParty?.address || '',
            town: thirdParty?.town || '',
            zipCode: thirdParty?.zip || '',
            coords
        },
        date: defaultDate,
        projectId: +projectId,
        technician: {
            id: technician?.id || '',
            name: technician ? userToString(technician) as string : '',
        }
    }

    return (
        <>
            <IonButton id={`schedule-intervention-vt-${dateId}`} fill="outline" disabled={isLoading || isError}>
                <IonIcon slot="icon-only" md={addSharp} ios={addOutline} />
            </IonButton>

            <InterventionFormModal
                {...baseProps}
                trigger={`schedule-intervention-vt-${dateId}`}
                defaultInterventionType={InterventionType.VISITE_TECHNIQUE}
                onWillDismiss={(e) => {
                    if (e.detail.role !== 'confirm') {
                        return;
                    }

                    onSuccess();
                }}
            />
        </>
    )
}


function useProposalAndCustomer(proposalId: string) {
    const { isError: proposalIsError, isFetched: proposalIsFetched, isLoading: proposalIsLoading, data: proposal } = useProposalDetail(proposalId);
    const { isError: customerIsError, isFetched: customerIsFetched, isLoading: customerIsLoading, data: customer } = useThirdPartyDetail(proposal?.socid || '');

    return {
        isError: proposalIsError || customerIsError,
        isFetched: proposalIsFetched || customerIsFetched,
        isLoading: proposalIsLoading || customerIsLoading,
        data: {
            proposal,
            customer,
        }
    }
}

interface InterventionItemProps {
    intervention: RawVisiteTechnique;
}

function InterventionItem({ intervention }: InterventionItemProps) {
    const { id, date, customer, reference, place: { address, zipCode, town, }, type, typeData } = intervention;

    const addressText = [
        address,
        zipCode,
        town,
    ].filter(i => !!i).join(', ');

    return (
        <IonCard>
            <IonCardHeader>
                <h3><b>{customer.name}</b></h3>
                <p>{addressText}</p>
            </IonCardHeader>

            <IonCardContent>
                <p><b>{date ? formatDate(date) : '--:--'}</b></p>
            </IonCardContent>
        </IonCard>
    )
}

const formatDate = (date: string) => DateTime.fromISO(date, { zone: "Europe/Paris" }).setLocale('fr').toFormat('HH:mm');