import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonChip,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonMenuButton,
    IonPage,
    IonSkeletonText,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import {useParams} from 'react-router';
import './DashboardPage.css';
import styled from "@emotion/styled";
import {useQuery} from "@tanstack/react-query";
import {getProposalStats} from '../calls/Stats/getProposalStats';
import {ProposalStatus, proposalUI} from "../models/proposal.model";
import {getLastModifiedProposals} from "../calls/Stats/getLastModifiedProposals";
import {getLastModifiedInterventions} from "../calls/Stats/getLastModifiedInterventions";
import {DateTime} from "luxon";
import {interventionUI} from "../models/intervention.model";
import CompanySwitchButton from "../components/Company/CompanySwitchButton";
import {ProductTypology, productTypologyUI} from "../components/Contracts/utils/product-typology";
import {uniq} from 'lodash';
import {useState} from "react";

const DashboardPage: React.FC = () => {

    const { name } = useParams<{ name: string; }>();

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Dashboard</IonTitle>
                </IonToolbar>

                <CompanySwitchButton />
            </IonHeader>

            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">{name}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <LastModifiedProposalsCard />
            </IonContent>
        </IonPage>
    );
};

export default DashboardPage;

const WhiteCard = styled(IonCard)`
    // Commenter car le thème Kamess n'est pas en place ici encore
    //--background: white;
    //
    //& ion-list {
    //    background: white;
    //}
    //
    //& ion-item {
    //    --background: white;
    //}
    //
    //& ion-skeleton-text {
    //    --border-radius: 9999px;
    //}
`;

const NoteServiceCardContent = styled(IonCardContent)`
    --background: white;
`;

function ProposalStatsCard() {
    const { data: stats, isLoading } = useQuery(['proposalStats'], getProposalStats);

    const statuses = [
        ProposalStatus.DRAFT,
        ProposalStatus.VALIDATED,
        ProposalStatus.SIGNED,
        ProposalStatus.NOT_SIGNED,
        ProposalStatus.BILLED,
    ];

    return (
        <WhiteCard>
            <IonCardHeader>
                <IonCardSubtitle>Statistiques</IonCardSubtitle>
                <IonCardTitle>Statistiques</IonCardTitle>
            </IonCardHeader>

            <IonCardContent>
                <IonList>
                    {
                        isLoading ? (
                            [1, 2, 3, 4, 5].map(
                                (v, index) => (
                                    <IonItem key={'stat-skeleton' + index}>
                                        <IonLabel>
                                            <h2>
                                                <IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>
                                            </h2>
                                        </IonLabel>
                                    </IonItem>
                                )
                            )
                        ) : (
                            statuses.map(
                                (status, index) => {
                                    const label = proposalUI.statusTranslation[status] || 'default';
                                    const statusColor = proposalUI.statusColorMapping[status] || 'default';

                                    return (
                                        <IonItem key={`stats-${index}`} lines={"none"}>
                                            <IonChip slot={"start"} color={statusColor}>{stats![status] || 0}</IonChip>
                                            <IonLabel>{label}</IonLabel>
                                        </IonItem>
                                    )
                                }
                            )
                        )
                    }
                </IonList>
            </IonCardContent>
        </WhiteCard>
    )
}

function LastModifiedProposalsCard() {
    const { data: lastProposalItems, isLoading } = useQuery(['lastModifiedProposals'], getLastModifiedProposals);

    const [max, setMax] = useState(15);
    const items = (lastProposalItems || []).slice(0, max);

    return (
        <WhiteCard>
            <IonCardHeader>
                <IonCardSubtitle>Avancements</IonCardSubtitle>
                <IonCardTitle>Affaires</IonCardTitle>
            </IonCardHeader>

            <IonCardContent>
                <IonList>
                    {
                        isLoading ? (
                            [1, 2, 3, 4].map(
                                (v, index) => (
                                    <IonItem key={'lastproposals-skeleton' + index}>
                                        <IonLabel>
                                            <h3>
                                                <IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>
                                            </h3>
                                            <p>
                                                <IonSkeletonText animated={true} style={{ 'width': '60%' }}></IonSkeletonText>
                                            </p>
                                        </IonLabel>
                                    </IonItem>
                                )
                            )
                        ) : (
                            (items).map(
                                (a, index) => {
                                    const status = a.fk_statut as ProposalStatus;
                                    const label = proposalUI.statusTranslation[status] || 'default';
                                    const statusColor = proposalUI.statusColorMapping[status] || 'default';

                                    const fromFormat= 'yyyy-MM-dd HH:mm:ss';
                                    const toFormat= 'dd/MM/yyyy HH:mm';
                                    const date = a.createdAt ? DateTime.fromFormat(a.createdAt, fromFormat).toFormat(toFormat) : '-- -- ----';

                                    const productTypologies = uniq((a.productTypologies || '').split(','))
                                        .map(typology => ({
                                            typology,
                                            Icon: productTypologyUI.typologyIcon[typology as ProductTypology]
                                        }))
                                        .filter(t => !!t.Icon)
                                    ;

                                    return (
                                        <IonItem button detail={true} key={`avancements-${index}`} lines={"none"} routerLink={`/page/Contracts/${a.proposalId}`} routerDirection={"forward"}>
                                            <IonLabel>
                                                <h2>{a.customerName}</h2>
                                                <p>Créé le {date}</p>
                                            </IonLabel>
                                            <IonChip slot={"end"} color="primary">
                                                {a.ref}
                                            </IonChip>

                                            {
                                                productTypologies.length > 0 && (
                                                    <IonChip slot={"end"} color="primary">
                                                        {
                                                            productTypologies.map(
                                                                ({ typology, Icon }, i) => (
                                                                    <Icon key={`${typology}-icon-${i}`}/>
                                                                )
                                                            )
                                                        }
                                                    </IonChip>
                                                )
                                            }
                                            <IonChip slot={"end"} color={statusColor}>{label}</IonChip>
                                        </IonItem>
                                    )
                                }
                            )
                        )
                    }
                </IonList>

                <IonButton expand="block" fill="clear" disabled={max > 15} onClick={() => setMax(50)}>
                    Voir tout
                </IonButton>
            </IonCardContent>
        </WhiteCard>
    )
}

function LastModifiedInterventionsCard() {
    const { data: lastInterventionsItems, isLoading } = useQuery(['lastModifiedInterventions'], getLastModifiedInterventions);

    return (
        <WhiteCard>
            <IonCardHeader>
                <IonCardSubtitle>Avancements</IonCardSubtitle>
                <IonCardTitle>Interventions</IonCardTitle>
            </IonCardHeader>

            <IonCardContent>
                <IonList>
                    {
                        isLoading ? (
                            [1, 2, 3, 4].map(
                                (v, index) => (
                                    <IonItem key={'lastinterventions-skeleton' + index}>
                                        <IonLabel>
                                            <h3>
                                                <IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>
                                            </h3>
                                            <p>
                                                <IonSkeletonText animated={true} style={{ 'width': '60%' }}></IonSkeletonText>
                                            </p>
                                        </IonLabel>
                                    </IonItem>
                                )
                            )
                        ) : (
                            (lastInterventionsItems || []).map(
                                (i, index) => {
                                    const type = i.type;
                                    const label = interventionUI.typeTranslation[type] || 'default';
                                    const typeColor = interventionUI.typeColorMapping[type] || 'default';

                                    const fromFormat= 'yyyy-MM-dd';
                                    const toFormat= 'dd/MM/yyyy';
                                    const date = i.startDate ? DateTime.fromFormat(i.startDate, fromFormat).toFormat(toFormat) : '-- -- ----';

                                    return (
                                        <IonItem key={`interventions-${index}`} lines={"none"}>
                                            <IonLabel>
                                                <h2>{i.name}</h2>
                                                <p>{date}</p>
                                            </IonLabel>
                                            <IonChip slot={"end"} color={typeColor}>{label}</IonChip>
                                        </IonItem>
                                    )
                                }
                            )
                        )
                    }
                </IonList>
            </IonCardContent>
        </WhiteCard>
    )
}