export class TypeBloc {
    private constructor(
        public label: string,
        public value: string | undefined,
    ) {}

    static MONOBLOC = new TypeBloc('Monobloc', 'MONOBLOC');

    static BIBLOC = new TypeBloc('Bibloc', 'BIBLOC');

    public static supportedBlocs(): Array<TypeBloc> {
        return [
            this.MONOBLOC,
            this.BIBLOC
        ];
    }
}