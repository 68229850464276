import {ContractFormModel} from "../models";
import currency from "currency.js";
import {keyBy, reduce} from "lodash";
import {Product} from "../../../models/product.model";
import {productTypeSubTitleMapping} from "../Installation/InstallationItem";

type NormalizedLine = {
    product?: Product;
    pose?: string,
    poseId?: number,
    totalHT: number,
    tvaTx: number,
    totalTTC: number,
    qty?: number,
    extraFields?: any,
}

export type ManagedProductKey = 'pacAirEau' | 'pacAirAir' | 'ballonThermodynamique' | 'panneauxPhotovoltaique' | 'autreMateriel';

export function normalizeFormValues(value: ContractFormModel) {
    const result: Partial<Record<ManagedProductKey, Array<NormalizedLine>>> = {};

    const {
        pacAirEau,
        pacAirAir,
        ballonThermodynamique,
        panneauxPhotovoltaique,
        autreMateriel,
        installation,
    } = value;

    if (pacAirEau.product) {
        result.pacAirEau = [
            {
                product: pacAirEau.product,
                totalHT: Number(pacAirEau.totalHT),
                tvaTx: Number(pacAirEau.tvaTx),
                totalTTC: Number(pacAirEau.totalTTC),
                extraFields: {
                    product_typology: 'PAC_AIR_EAU',
                    pac_air_eau_actions: pacAirEau.actions,
                    pac_air_eau_type_logement: pacAirEau.typeLogement,
                    pac_air_eau_surface_logement: pacAirEau.surfaceLogement,
                    pac_air_eau_marque_ancienne_chaudiere: pacAirEau.marqueAncienneChaudiere,
                    pac_air_eau_type_ancienne_chaudiere: pacAirEau.typeAncienneChaudiere,
                }
            }
        ];
    }

    if (pacAirAir.products.filter(p => !!p).length > 0) {
        result.pacAirAir = pacAirAir.products.map(
            p => {
                const totalHT = currency(p.totalHT || 0);
                const unitPrice = totalHT.divide(p.qty || 1);
                const totalTTC = unitPrice.multiply(1 + (Number(p.tvaTx) / 100));

                return {
                    product: p.product,
                    totalHT: unitPrice.value, // On hack ici en réalité totalHT est le prix unitaire
                    tvaTx: Number(p.tvaTx),
                    totalTTC: totalTTC.value,
                    qty: p.qty as number,
                    extraFields: {
                        product_typology: 'PAC_AIR_AIR',
                    }
                }
            }
        );
    }

    if (ballonThermodynamique.product) {
        result.ballonThermodynamique = [
            {
                product: ballonThermodynamique.product,
                totalHT: Number(ballonThermodynamique.totalHT),
                tvaTx: Number(ballonThermodynamique.tvaTx),
                totalTTC: Number(ballonThermodynamique.totalTTC),
                extraFields: {
                    product_typology: 'BALLON_THERMO',
                }
            }
        ];
    }

    if (panneauxPhotovoltaique.product) {
        const p = panneauxPhotovoltaique;
        const totalHT = currency(p.totalHT || 0);
        const unitPrice = totalHT.divide(p.qty || 1);
        const totalTTC = unitPrice.multiply(1 + (Number(p.tvaTx) / 100));

        result.panneauxPhotovoltaique = [
            {
                product: panneauxPhotovoltaique.product,
                totalHT: unitPrice.value, // On hack ici en réalité totalHT est le prix unitaire
                tvaTx: Number(p.tvaTx),
                totalTTC: totalTTC.value,
                qty: p.qty as number,
                extraFields: {
                    product_typology: 'PHOTOVOLTAIC',
                    pv_source_puissance_kit: panneauxPhotovoltaique.sourcePuissanceKit,
                }
            }
        ];
    }

    if (autreMateriel.product) {
        result.autreMateriel = [
            {
                product: autreMateriel.product,
                totalHT: Number(autreMateriel.totalHT),
                tvaTx: Number(autreMateriel.tvaTx),
                totalTTC: Number(autreMateriel.totalTTC),
                extraFields: {
                    product_typology: 'AUTRE_MATERIEL',
                }
            }
        ];
    }

    if (installation.products.filter(p => !!p).length > 0) {
        installation.products.forEach(p => {
            const totalHT = currency(p.totalHT);
            const totalTTC = totalHT.multiply(1 + (Number(p.tvaTx) / 100));

            result[p.type]?.push({
                poseId: p.poseId,
                pose: (
                    p.poseId
                        ? POSE_DB[p.poseId].description
                        : `Pose, raccordement et mise en service ${productTypeSubTitleMapping[p.type]}`
                ),
                totalHT: Number(p.totalHT),
                tvaTx: Number(p.tvaTx),
                totalTTC: Number(totalTTC),
            })
        });
    }

    return result;
}

export function sumNormalizedLines(lines: Array<NormalizedLine>) {
    const ht = reduce(
        lines.map(({totalHT, qty}) => currency(totalHT).multiply(Number(qty || 1))),
        (sum, n) => sum.add(n),
    ) || currency(0);

    const montantTva = reduce(
        (
            lines.map(
                ({totalHT, tvaTx, qty}) => {
                    return currency(totalHT).multiply(Number(qty || 1)).multiply(Number(tvaTx) / 100);
                }
            )
        ),
        (sum, n) => sum.add(n),
    ) || currency(0);

    const ttc = ht.add(montantTva as any);

    return {
        ht,
        montantTva,
        ttc
    }
}

const poseList = [
    {
        "label": "Pose raccordement et mise en service d'un ballon d'eau chaude",
        "description": "Cette prestation comprend :\n-Depose du chauffe eau existant\n-Fourniture des accesoires\n-Superficie habitable: \n-Main d'oeuvre et frais de déplacement\n-Mise en service\n\nPose confiée à Agéo Azur Géothermie Gerant: MONGE GERALD \nSiret: 50016880200030\n\nQualiPAC module chauffage et ECS\nPeriode couverte par le certificat: 26 février 2022 au 26 février 2023\nQualiPAC n°QPAC/32729\n\nQualiPV module Bat et module Elec \nPeriode couverte par le certificat: 22 septembre 2021 au 22 septembre 2022\nQualiPV n°QPV/32729",
        "other": null,
        "type": "1",
        "price": null,
        "price_ttc": null,
        "price_min": null,
        "price_min_ttc": null,
        "price_base_type": null,
        "multiprices": [],
        "multiprices_ttc": [],
        "multiprices_base_type": [],
        "multiprices_min": [],
        "multiprices_min_ttc": [],
        "multiprices_tva_tx": [],
        "prices_by_qty": [],
        "prices_by_qty_list": [],
        "multilangs": [],
        "default_vat_code": null,
        "tva_tx": null,
        "localtax1_tx": "0.0000",
        "localtax2_tx": "0.0000",
        "localtax1_type": "0",
        "localtax2_type": "0",
        "lifetime": null,
        "qc_frequency": null,
        "cost_price": null,
        "pmp": "0.00000000",
        "seuil_stock_alerte": null,
        "desiredstock": "0",
        "duration_value": false,
        "duration_unit": "",
        "status": "1",
        "status_buy": "1",
        "finished": null,
        "fk_default_bom": null,
        "status_batch": "0",
        "batch_mask": null,
        "customcode": null,
        "url": null,
        "weight": null,
        "weight_units": "3",
        "length": null,
        "length_units": "0",
        "width": null,
        "width_units": "0",
        "height": null,
        "height_units": "0",
        "surface": null,
        "surface_units": "0",
        "volume": null,
        "volume_units": "0",
        "net_measure": null,
        "net_measure_units": null,
        "accountancy_code_sell": null,
        "accountancy_code_sell_intra": null,
        "accountancy_code_sell_export": null,
        "accountancy_code_buy": null,
        "accountancy_code_buy_intra": null,
        "accountancy_code_buy_export": null,
        "barcode": null,
        "barcode_type": null,
        "date_creation": "2022-08-14 00:00:00",
        "date_modification": "2022-08-14 10:03:54",
        "fk_default_warehouse": null,
        "fk_price_expression": null,
        "fk_unit": null,
        "price_autogen": "0",
        "is_object_used": null,
        "mandatory_period": "0",
        "id": "575",
        "entity": "1",
        "validateFieldsErrors": [],
        "import_key": "20220814120354",
        "array_options": {
            "options_prix_achat": null,
            "options_prix_pro": null,
            "options_prix_hors_cible": null,
            "options_prix_pose": null,
            "options_type_branchement": null,
            "options_type_bloc": null,
            "options_is_indoor_unit": null,
            "options_type_ballon": null,
            "options_volume_maximum_bassin": null,
            "options_capacity": null,
            "options_cop": null,
            "options_etas": null,
            "options_puissance": null
        },
        "array_languages": null,
        "linkedObjectsIds": null,
        "canvas": null,
        "ref": "Pose Ballon Thermodynamique",
        "ref_ext": null,
        "country_id": "1",
        "country_code": "FR",
        "state_id": null,
        "region_id": null,
        "barcode_type_coder": null,
        "last_main_doc": null,
        "note_public": null,
        "note_private": "Dans le bon de commande on indique la surface du logement. Il faut renvoyer cette info dans superficie (descriptif 1)",
        "total_ht": null,
        "total_tva": null,
        "total_localtax1": null,
        "total_localtax2": null,
        "total_ttc": null,
        "date_validation": null,
        "specimen": 0,
        "duration": null
    },
    {
        "label": "Pose raccordement et mise en service d'une pompe à chaleur air/air",
        "description": "Type d'habitation : Maison individuelle\nCette prestation comprend :\n-Fourniture des accessoires\n-Main d'oeuvre\n-Mise en service",
        "other": null,
        "type": "1",
        "price": null,
        "price_ttc": null,
        "price_min": null,
        "price_min_ttc": null,
        "price_base_type": null,
        "multiprices": [],
        "multiprices_ttc": [],
        "multiprices_base_type": [],
        "multiprices_min": [],
        "multiprices_min_ttc": [],
        "multiprices_tva_tx": [],
        "prices_by_qty": [],
        "prices_by_qty_list": [],
        "multilangs": [],
        "default_vat_code": null,
        "tva_tx": null,
        "localtax1_tx": "0.0000",
        "localtax2_tx": "0.0000",
        "localtax1_type": "0",
        "localtax2_type": "0",
        "lifetime": null,
        "qc_frequency": null,
        "cost_price": null,
        "pmp": "0.00000000",
        "seuil_stock_alerte": null,
        "desiredstock": "0",
        "duration_value": false,
        "duration_unit": "",
        "status": "1",
        "status_buy": "1",
        "finished": null,
        "fk_default_bom": null,
        "status_batch": "0",
        "batch_mask": null,
        "customcode": null,
        "url": null,
        "weight": null,
        "weight_units": "3",
        "length": null,
        "length_units": "0",
        "width": null,
        "width_units": "0",
        "height": null,
        "height_units": "0",
        "surface": null,
        "surface_units": "0",
        "volume": null,
        "volume_units": "0",
        "net_measure": null,
        "net_measure_units": null,
        "accountancy_code_sell": null,
        "accountancy_code_sell_intra": null,
        "accountancy_code_sell_export": null,
        "accountancy_code_buy": null,
        "accountancy_code_buy_intra": null,
        "accountancy_code_buy_export": null,
        "barcode": null,
        "barcode_type": null,
        "date_creation": "2022-08-14 00:00:00",
        "date_modification": "2022-08-14 10:03:54",
        "fk_default_warehouse": null,
        "fk_price_expression": null,
        "fk_unit": null,
        "price_autogen": "0",
        "is_object_used": null,
        "mandatory_period": "0",
        "id": "573",
        "entity": "1",
        "validateFieldsErrors": [],
        "import_key": "20220814120354",
        "array_options": {
            "options_prix_achat": null,
            "options_prix_pro": null,
            "options_prix_hors_cible": null,
            "options_prix_pose": null,
            "options_type_branchement": null,
            "options_type_bloc": null,
            "options_is_indoor_unit": null,
            "options_type_ballon": null,
            "options_volume_maximum_bassin": null,
            "options_capacity": null,
            "options_cop": null,
            "options_etas": null,
            "options_puissance": null
        },
        "array_languages": null,
        "linkedObjectsIds": null,
        "canvas": null,
        "ref": "Pose PAC AIR/AIR",
        "ref_ext": null,
        "country_id": "1",
        "country_code": "FR",
        "state_id": null,
        "region_id": null,
        "barcode_type_coder": null,
        "last_main_doc": null,
        "note_public": null,
        "note_private": null,
        "total_ht": null,
        "total_tva": null,
        "total_localtax1": null,
        "total_localtax2": null,
        "total_ttc": null,
        "date_validation": null,
        "specimen": 0,
        "duration": null
    },
    {
        "label": "Pose raccordement et mise en service d'une pompe à chaleur air/eau",
        "description": "Type d'habitation : Maison individuelle\nCette prestation comprend :\n-Depose de l'ancienne chaudière\n-Superficie: \n-Fourniture des accessoires\n-Main d'oeuvre\n-Mise en service\n-non compris( enlevement chaudière et cuve fioul)\n\nPose confiée à Agéo Azur Géothermie Gerant: MONGE GERALD \nSiret: 50016880200030\n\nQualiPAC module chauffage et ECS\nPeriode couverte par le certificat: 26 février 2022 au 26 février 2023\nQualiPAC n°QPAC/32729\n\nQualiPV module Bat et module Elec \nPeriode couverte par le certificat: 22 septembre 2021 au 22 septembre 2022\nQualiPV n°QPV/32729",
        "other": null,
        "type": "1",
        "price": null,
        "price_ttc": null,
        "price_min": null,
        "price_min_ttc": null,
        "price_base_type": null,
        "multiprices": [],
        "multiprices_ttc": [],
        "multiprices_base_type": [],
        "multiprices_min": [],
        "multiprices_min_ttc": [],
        "multiprices_tva_tx": [],
        "prices_by_qty": [],
        "prices_by_qty_list": [],
        "multilangs": [],
        "default_vat_code": null,
        "tva_tx": null,
        "localtax1_tx": "0.0000",
        "localtax2_tx": "0.0000",
        "localtax1_type": "0",
        "localtax2_type": "0",
        "lifetime": null,
        "qc_frequency": null,
        "cost_price": null,
        "pmp": "0.00000000",
        "seuil_stock_alerte": null,
        "desiredstock": "0",
        "duration_value": false,
        "duration_unit": "",
        "status": "1",
        "status_buy": "1",
        "finished": null,
        "fk_default_bom": null,
        "status_batch": "0",
        "batch_mask": null,
        "customcode": null,
        "url": null,
        "weight": null,
        "weight_units": "3",
        "length": null,
        "length_units": "0",
        "width": null,
        "width_units": "0",
        "height": null,
        "height_units": "0",
        "surface": null,
        "surface_units": "0",
        "volume": null,
        "volume_units": "0",
        "net_measure": null,
        "net_measure_units": null,
        "accountancy_code_sell": null,
        "accountancy_code_sell_intra": null,
        "accountancy_code_sell_export": null,
        "accountancy_code_buy": null,
        "accountancy_code_buy_intra": null,
        "accountancy_code_buy_export": null,
        "barcode": null,
        "barcode_type": null,
        "date_creation": "2022-08-14 00:00:00",
        "date_modification": "2022-08-14 10:03:54",
        "fk_default_warehouse": null,
        "fk_price_expression": null,
        "fk_unit": null,
        "price_autogen": "0",
        "is_object_used": null,
        "mandatory_period": "0",
        "id": "572",
        "entity": "1",
        "validateFieldsErrors": [],
        "import_key": "20220814120354",
        "array_options": {
            "options_prix_achat": null,
            "options_prix_pro": null,
            "options_prix_hors_cible": null,
            "options_prix_pose": null,
            "options_type_branchement": null,
            "options_type_bloc": null,
            "options_is_indoor_unit": null,
            "options_type_ballon": null,
            "options_volume_maximum_bassin": null,
            "options_capacity": null,
            "options_cop": null,
            "options_etas": null,
            "options_puissance": null
        },
        "array_languages": null,
        "linkedObjectsIds": null,
        "canvas": null,
        "ref": "Pose PAC AIR/EAU",
        "ref_ext": null,
        "country_id": "1",
        "country_code": "FR",
        "state_id": null,
        "region_id": null,
        "barcode_type_coder": null,
        "last_main_doc": null,
        "note_public": null,
        "note_private": "Dans le bon de commande on indique la surface du logement. Il faut renvoyer cette info dans superficie (descriptif 1)",
        "total_ht": null,
        "total_tva": null,
        "total_localtax1": null,
        "total_localtax2": null,
        "total_ttc": null,
        "date_validation": null,
        "specimen": 0,
        "duration": null
    },
    {
        "label": "Pose raccordement et mise en service d'une installation photovoltaïque",
        "description": null,
        "other": null,
        "type": "1",
        "price": null,
        "price_ttc": null,
        "price_min": null,
        "price_min_ttc": null,
        "price_base_type": null,
        "multiprices": [],
        "multiprices_ttc": [],
        "multiprices_base_type": [],
        "multiprices_min": [],
        "multiprices_min_ttc": [],
        "multiprices_tva_tx": [],
        "prices_by_qty": [],
        "prices_by_qty_list": [],
        "multilangs": [],
        "default_vat_code": null,
        "tva_tx": null,
        "localtax1_tx": "0.0000",
        "localtax2_tx": "0.0000",
        "localtax1_type": "0",
        "localtax2_type": "0",
        "lifetime": null,
        "qc_frequency": null,
        "cost_price": null,
        "pmp": "0.00000000",
        "seuil_stock_alerte": null,
        "desiredstock": "0",
        "duration_value": false,
        "duration_unit": "",
        "status": "1",
        "status_buy": "1",
        "finished": null,
        "fk_default_bom": null,
        "status_batch": "0",
        "batch_mask": null,
        "customcode": null,
        "url": null,
        "weight": null,
        "weight_units": "3",
        "length": null,
        "length_units": "0",
        "width": null,
        "width_units": "0",
        "height": null,
        "height_units": "0",
        "surface": null,
        "surface_units": "0",
        "volume": null,
        "volume_units": "0",
        "net_measure": null,
        "net_measure_units": null,
        "accountancy_code_sell": null,
        "accountancy_code_sell_intra": null,
        "accountancy_code_sell_export": null,
        "accountancy_code_buy": null,
        "accountancy_code_buy_intra": null,
        "accountancy_code_buy_export": null,
        "barcode": null,
        "barcode_type": null,
        "date_creation": "2022-08-14 00:00:00",
        "date_modification": "2022-08-14 10:03:54",
        "fk_default_warehouse": null,
        "fk_price_expression": null,
        "fk_unit": null,
        "price_autogen": "0",
        "is_object_used": null,
        "mandatory_period": "0",
        "id": "574",
        "entity": "1",
        "validateFieldsErrors": [],
        "import_key": "20220814120354",
        "array_options": {
            "options_prix_achat": null,
            "options_prix_pro": null,
            "options_prix_hors_cible": null,
            "options_prix_pose": null,
            "options_type_branchement": null,
            "options_type_bloc": null,
            "options_is_indoor_unit": null,
            "options_type_ballon": null,
            "options_volume_maximum_bassin": null,
            "options_capacity": null,
            "options_cop": null,
            "options_etas": null,
            "options_puissance": null
        },
        "array_languages": null,
        "linkedObjectsIds": null,
        "canvas": null,
        "ref": "Pose Panneau Voltaïque",
        "ref_ext": null,
        "country_id": "1",
        "country_code": "FR",
        "state_id": null,
        "region_id": null,
        "barcode_type_coder": null,
        "last_main_doc": null,
        "note_public": null,
        "note_private": null,
        "total_ht": null,
        "total_tva": null,
        "total_localtax1": null,
        "total_localtax2": null,
        "total_ttc": null,
        "date_validation": null,
        "specimen": 0,
        "duration": null
    }
]

export const POSE_DB: any = keyBy(poseList, 'id');