export class TypeBranchementAirAir {
    private constructor(
            public label: string,
            public value: string | undefined,
    ) {}

    static MONO = new TypeBranchementAirAir('Mono', 'MONO');

    static BI = new TypeBranchementAirAir('BI', 'BI');

    static TRI = new TypeBranchementAirAir('Tri', 'TRI');

    static QUARDI = new TypeBranchementAirAir('Quardi', 'QUARDI');

    public static supportedBranchements(): Array<TypeBranchementAirAir> {
        return [
            this.MONO,
            this.BI,
            this.TRI,
            this.QUARDI,
        ];
    }
}