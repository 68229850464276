export enum Endpoint {
    searchProducts = '/amdpeasysaleskms/products',
    createThirdParty = '/amdpeasysaleskms/thirdparty',
    proposals = '/proposals',
    proposal = '/proposals/:id',
    proposalAvailableDocuments = '/amdpsales/proposal/available-documents',
    proposalLines = '/proposals/:id/lines',
    proposalLine = '/proposals/:id/lines/:lineId',
    proposalValidate = '/proposals/:id/validate',
    proposalAddContact = '/proposals/:id/contact/:contactid/:type',
    proposalSetToDraft = '/proposals/:id/settodraft',
    thirdParty = '/thirdparties/:id',
    documentBuildDoc = '/documents/builddoc',
    documentDownload = '/documents/download',
    documentUpload = '/documents/upload',
    foires = '/amdpeasysaleskms/foire',
    users = '/amdpsales/users/search',
    me = '/users/login/:login',
    userLogin = '/login',
    company = '/multicompany',
    viewCompanyLogo = '/viewimage.php?modulepart=mycompany&file=logos/thumbs/:filename&entity=:entity',
    statsLastModifiedInterventions = '/amdpsales/lastModifiedInterventions',
    statsLastModifiedProposals = '/amdpsales/lastModifiedProposals',
    statsProposals = '/amdpsales/proposalStats',
    products = '/amdpeasysaleskms/products',
    product = '/products/:id',
    proposalsSearch = '/amdpsales/proposals/search',
    proposalAcceptOrReject = '/proposals/:id/close',
    proposalInvoiced = '/proposals/:id/setinvoiced',
    thirdParties = '/amdpsales/thirdparties/search',
    documents = '/documents',
    interventions = '/interventions',
    categories = '/categories',
    payments = '/amdpsales/payments/search',
    filesSearch = '/amdpsales/files/search',
    agendaEvents = '/amdpsales/agenda-events/search',
    agendaEventId = '/agendaevents/:id',
    interventionsHeader = "/interventions",
    interventionsLines = "/amdpsales/interventions/:id/lines",
    interventionsValidate = "/interventions/:id/validate",
    addPayment = '/invoices/paymentsdistributed',
    order = '/orders/:id',
    orderValidate = '/orders/:id/validate',
    orderClosed = '/orders/:id/close',
    invoice = '/invoices/:id',
    invoicePayments = '/invoices/:id/payments',
    invoiceValidate = '/invoices/:id/validate',
    contacts = '/contacts',
    createVisiteTechnique = '/interventions-of-visite-technique/create',
    intervention = "/interventions/:id",
    aggregationScheduledInterventionsInPeriod = '/aggregation/ScheduledInterventionsInPeriod',
    agentIsOnTheWayToIntervention = '/interventions-of-rdv-com/:id/on-the-way',
    agentArriveAtTheInterventionPlace = '/interventions-of-rdv-com/:id/arrived',
    agentIsLeavingInterventionPlace = '/interventions-of-rdv-com/:id/departure',
    closeRdvCom = '/interventions-of-rdv-com/:id/close',
}

