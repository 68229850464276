import styled from "@emotion/styled";
import {useIonViewDidEnter} from "@ionic/react";
import 'leaflet/dist/leaflet.css'
import {MapContainer, Marker, TileLayer} from "react-leaflet";
import {RawVisiteTechnique} from "../../models/intervention.model";
import L from 'leaflet';

export default function TechnicianMapCard({ interventions }: { interventions: Array<RawVisiteTechnique> }) {
    useIonViewDidEnter(() => {
        window.dispatchEvent(new Event("resize"));
    });

    const positions = interventions.map(
        i => {
            const coords = i.place.coords.coordinates;

            return [...coords || []].reverse();
        }
    );

    const bounds = new L.LatLngBounds(positions as any);

    const props: any = positions.length === 0 ? {
        center: [44.837789, -0.57918]
    } : {
        bounds,
    }

    return (
        <div style={{ height: '300px' }}>
            <StyledMapContainer {...props} zoom={7} scrollWheelZoom={false}>
                <TileLayer
                    attribution='&copy; AMDP'
                    url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
                />

                {
                    positions.map(
                        (p, index) => <Marker key={`Marker-${index}`} position={p as any} icon={icon} />
                    )
                }
            </StyledMapContainer>
        </div>

    )
}

const icon = new L.Icon({
    iconUrl: '/assets/img/marker-icon-gold.png',
    shadowUrl: '/assets/img/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
});

const StyledMapContainer = styled(MapContainer)`
    height: 100%;
    width: 100%;
`;
