import {IonCol, IonGrid, IonItem, IonRow, IonSkeletonText, IonText, IonThumbnail} from "@ionic/react";
import {useTechniciansQuery} from "./useTechniciansQuery";
import {Avatar, IconButton} from "@mui/material";
import LoadingDots from "../LoadingDots";
import React from "react";
import {User, userToInitials, userToString} from "../../models/user.model";
import {useController} from "react-hook-form";
import styled from "@emotion/styled";

export default function TechnicianAvatars() {
    const { data, isLoading, isError } = useTechniciansQuery();

    const {
        field: { value, onChange },
    } = useController({
        name: 'technician',
        rules: { required: true },
    });

    if (isLoading) {
        return (
            <IonItem lines={"none"}>
                <IonText color={'medium'} style={{ marginRight: 5 }}>
                    <span>Veuillez patienter</span>
                </IonText>
                <LoadingDots />
            </IonItem>
        )
    }

    const users = data?.items || [];

    const handleClick = (t: User) => {
        onChange({
            id: t.id,
            lastname: t.lastname,
            firstname: t.firstname,
            login: t.login,
        });
    }

    return (
        <IonGrid>
            <IonRow>
                {
                    users.map(
                        u => (
                            <IonCol key={u.id} className={`ion-text-center`}>
                                <StyledTechnicianButton
                                    className={`ion-text-center ${value?.id === u.id ? 'selected' : ''}`}
                                    onClick={() => handleClick(u)}
                                >
                                    <Avatar sx={{ bgcolor: stringToColor(u.lastname || u.firstname || u.login || '') }}>
                                        {userToInitials(u)}
                                    </Avatar>
                                </StyledTechnicianButton>
                                <p style={{ margin: 0 }}>
                                    {userToString(u)}
                                </p>
                            </IonCol>
                        )
                    )
                }
            </IonRow>
        </IonGrid>
    )
}

const StyledTechnicianButton = styled(IconButton)`
    &.selected {
        border: 3px solid var(--ion-color-primary);
    }
`;

function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}